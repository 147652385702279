import styles from '../styles/components/OrderDetails.module.scss'
import FlowerIcon from '../assets/icons/flower.svg'
import MapPinIcon from '../assets/icons/map-pin.svg'

interface OrderDetailsProps {
  title: string;
  duration: string;
  frequency: string;
  location: string;
  isHighlighted?: boolean;
}

export const OrderDetails: React.FC<OrderDetailsProps> = ({
  title,
  duration,
  frequency,
  location,
  isHighlighted
}) => {
  return (
    <div className={`${styles.orderDetails} ${isHighlighted ? styles.highlighted : ''}`}>
      <div className={styles.item}>
        <img src={FlowerIcon} alt="Flower Icon" />
        <div className={styles.itemInfo}>
          <p>{title}</p>
          <p>{duration}</p>
          <p>{frequency}</p>
        </div>
      </div>
      <div className={styles.item}>
        <img src={MapPinIcon} alt="Map Pin Icon" />
        <p>{location}</p>
      </div>
    </div>
  )
}

export default OrderDetails