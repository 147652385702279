import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import styles from '../styles/components/Preferences.module.scss';
import BodyFrontImg from '../assets/images/body-front.svg';
import BodyBackImg from '../assets/images/body-back.svg';
import { Dropdown } from './core/Dropdown';
import { useUpdateUserNotes } from '../hooks/useUserProfile';
import { useToast } from './core/ToastManager';
import Spinner from './core/Spinner';

type PreferenceOption = 'ok' | 'avoid' | 'focus';

interface PreferencesFormData {
  preferences: Record<string, PreferenceOption>;
  notes: string;
}

interface Props {
  onClose: () => void;
}

const options = [
  { value: 'ok', label: 'Ok' },
  { value: 'avoid', label: 'Avoid' },
  { value: 'focus', label: 'Focus' },
];

const frontAreas = [
  { name: 'Head', key: 'head' },
  { name: 'Face', key: 'face' },
  { name: 'Pecs', key: 'pecs' },
  { name: 'Arms & Hands', key: 'armsHands' },
  { name: 'Quads', key: 'quads' },
  { name: 'Feet', key: 'feet' },
];

const backAreas = [
  { name: 'Neck', key: 'neck' },
  { name: 'Shoulders', key: 'shoulders' },
  { name: 'Upper Back', key: 'upperBack' },
  { name: 'Mid Back', key: 'midBack' },
  { name: 'Lower Back', key: 'lowerBack' },
  { name: 'Hips & Glutes', key: 'hipsGlutes' },
  { name: 'Hamstrings', key: 'hamstrings' },
  { name: 'Calves', key: 'calves' },
];

export const PreferencesModal: React.FC<Props> = ({ onClose }) => {
  const { control, handleSubmit, watch } = useForm<PreferencesFormData>({
    defaultValues: {
      preferences: {
        head: 'ok',
        face: 'ok',
        pecs: 'ok',
        armsHands: 'ok',
        quads: 'ok',
        feet: 'ok',
        neck: 'ok',
        shoulders: 'ok',
        upperBack: 'ok',
        midBack: 'ok',
        lowerBack: 'ok',
        hipsGlutes: 'ok',
        hamstrings: 'ok',
        calves: 'ok',
      },
      notes: '',
    },
  });

  const { addToast } = useToast();

  const { mutate: updateUserNotes, isPending: isUpdateUserNotesPending } = useUpdateUserNotes(
    (data) => {
      addToast('Preferences updated successfully', 'success');
      onClose()
    },
    (error) => {
      addToast(`Failed to update preferences: ${error.errorMessages[0]}`, 'error');
    }
  );

  const preferences = watch('preferences');

  const onSubmit = (data: PreferencesFormData) => {
    const preferencesList = Object.entries(data.preferences)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');
  
    const formattedString = `Preferences: ${preferencesList}. Notes: ${data.notes}`;
    
    updateUserNotes(formattedString);
  };

  const getCircleColor = (value: PreferenceOption) => {
    switch (value) {
      case 'avoid':
        return '#acacac'; 
      case 'focus':
        return '#404d6a';
      default:
        return '#fff';
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.preferences}>
      <h2>Are there any areas that you would like your therapist to focus on or avoid?</h2>
      <p>Select all that areas that apply.</p>

      <div className={styles.heading}>
        <h2>Front</h2>
      </div>
      <div className={styles.preference}>
        <div className={styles.preferenceForm}>
          {frontAreas.map((area, index) => {
            return (
              <div key={area.key + index} className={styles.preferenceRow}>
                <label>{area.name}</label>
                <Controller
                  name={`preferences.${area.key}` as const}
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={area.key + index}
                      label={''}
                      value={field.value as string}
                      options={options}
                      onChange={field.onChange}
                      className={styles.dropdown}
                    />
                  )}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.preferenceLayout}>
          <img src={BodyFrontImg} alt="Body front" />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.head), top: '0', left: '50%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.face), top: '3%', left: '30%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.pecs), top: '22%', left: '30%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.pecs), top: '22%', right: '35%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.armsHands), top: '33%', right: '23%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.armsHands), top: '33%', left: '15%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.armsHands), top: '53%', right: '23%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.armsHands), top: '53%', left: '15%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.quads), top: '60%', right: '38%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.quads), top: '60%', left: '30%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.feet), top: '94%', right: '33%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.feet), top: '94%', left: '25%' }}
          />
        </div>
      </div>

      <div className={styles.heading}>
        <h2>Back</h2>
      </div>
      <div className={styles.preference}>
        <div className={styles.preferenceForm}>
          {backAreas.map((area, index) => {
            return (
              <div key={area.key + index} className={styles.preferenceRow}>
                <label>{area.name}</label>
                <Controller
                  name={`preferences.${area.key}` as const}
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={area.key + index}
                      label={''}
                      value={field.value as string}
                      options={options}
                      onChange={field.onChange}
                      className={styles.dropdown}
                    />
                  )}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.preferenceLayout}>
          <img src={BodyBackImg} alt="Body back" />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.neck), top: '10%', left: '39%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.shoulders), top: '15%', left: '20%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.shoulders), top: '15%', right: '30%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.upperBack), top: '20%', left: '40%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.midBack), top: '28%', left: '40%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.lowerBack), top: '37%', left: '40%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.hipsGlutes), top: '45%', left: '23%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.hipsGlutes), top: '45%', right: '30%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.hamstrings), top: '63%', left: '32%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.hamstrings), top: '63%', right: '38%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.calves), top: '74%', left: '28%' }}
          />
          <div
            className={styles.circle}
            style={{ backgroundColor: getCircleColor(preferences.calves), top: '74%', right: '33%' }}
          />
        </div>
      </div>

      <div className={styles.messageForm}>
        <h2>Is there anything else your therapist should know?</h2>
        <p>Are there areas that you would like to focus on? Please tell us.</p>
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <textarea {...field} placeholder="Type your message here" maxLength={500} />
          )}
        />
      </div>

      <div className={styles.actions}>
        <button type="submit" className="button">
          {isUpdateUserNotesPending ? <Spinner size={20} /> : "Save Preferences"}
        </button>
        <button type="button" className="button__underline" onClick={() => onClose()}>
          CANCEL
        </button>
      </div>
    </form>
  );
};

export default PreferencesModal;