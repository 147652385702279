import styles from '../styles/pages/Memberships.module.scss'
import StarsIcon from '../assets/icons/stars-icon.svg';
import PiggyBankIcon from '../assets/icons/piggy-bank-icon.svg';
import ShoppingBagIcon from '../assets/icons/shopping-bag-icon.svg';
import ArrowsCircleIcon from '../assets/icons/arrows-circle-icon.svg';
import { numberToText } from '../utils/caseConverter';
import { Link } from 'react-router-dom';
import { useUserProfile } from '../hooks/useUserProfile';
import { useMemberships } from '../hooks/useMemberships';
import Spinner from '../components/core/Spinner';

const MembershipBenefits = [
  { title: 'Friends & family member rate for anyone new to The NOW', icon: StarsIcon },
  { title: '10% off in-store purchases on nourish, scent & room by the NOW Products', icon: ShoppingBagIcon },
  { title: 'Savings on monthly massages', icon: PiggyBankIcon },
  { title: 'Roll-over unused credits', icon: ArrowsCircleIcon },
]


export const Memberships = () => {
  const { data: userProfile, isLoading: isLoadingUserProfile, error: userProfileError } = useUserProfile()
  const { data: boutiqueMemberships, isLoading: isLoadingMemberships, error: membershipsError } = useMemberships(userProfile?.data.boutiqueId);

  if (isLoadingUserProfile || isLoadingMemberships) {
    return <div><Spinner /></div>;
  }
  
  if (userProfileError || membershipsError) {
    return <div className={styles.error}>Error loading memberships. Please try again later.</div>;
  }
    
  return (
    <div className={styles.noMemberships}>
    <div className={styles.header}>
      <h3 className={styles.benefitTitle}>Save up to $100 on monthly massages</h3>
      <p>Make massage a part of your monthly ritual. No long-term commitments, cancel any time with 30 days notice</p>
    </div>
    <div className={styles.membershipBenefits}>
      {MembershipBenefits.map((benefit, index) => (
      <div key={index} className={styles.membershipBenefit}>
        <img src={benefit.icon} alt="benefit icon" />
        <p>{benefit.title}</p>
      </div>
      ))}
    </div>
    <div className={styles.selectMembership}>
    {boutiqueMemberships && boutiqueMemberships.membershipGroups.map((membershipGroup) => (
      <div key={membershipGroup.id} className={styles.membershipColumn}>
        <h3>{membershipGroup.name}</h3>
        {membershipGroup.memberships.map((membership) => (
          <div
            key={membership.zenotiId}
            className={styles.membershipCard}
          >
            <div className={styles.membershipContentLeft}>
              <p className={styles.membershipFrequency}>
                {numberToText(membership.frequencyPerMonth)} {membership.durationInMinutes} minute {membership.frequencyPerMonth > 1 ? 'massages' : 'massage'}
              </p>
              <p>per month</p>
              <Link to={`/checkout/${membership.boutique.id}/membership/${membership.zenotiId}`} className={`button`}>Choose</Link>
            </div>
            <div className={styles.membershipContentRight}>
              <div className={styles.price}>
                <div className={styles.membershipPrice}>${membership.price}</div>
                <div className={styles.nonMemberPrice}>per month</div>
              </div>
              {membership.memberSaving && <p className={styles.discount}>${membership.memberSaving} SAVINGS</p>}
            </div>
          </div>
        ))}
      </div>
    ))}
    </div>
  </div>
  )
}

export default Memberships