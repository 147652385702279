import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { createInvoice, closeInvoice, closeGiftCardInvoice } from '../api/purchase'; 
import { InvoiceRequest, InvoiceResponse, GiftCardCloseInvoiceRequest } from '../types/purchase'; 
import { ApiErrorResponse } from '../types/api'; 


export const useCreateInvoice = (
  onSuccess?: (data: InvoiceResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<InvoiceResponse, ApiErrorResponse, InvoiceRequest> => {
  return useMutation<InvoiceResponse, ApiErrorResponse, InvoiceRequest>({
    mutationFn: (data: InvoiceRequest) => createInvoice(data),
    onSuccess, 
    onError, 
  });
};

export const useCloseInvoice = (
  onSuccess?: (data: InvoiceResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<InvoiceResponse, ApiErrorResponse, { invoiceId: string; giftCardPurchase?: boolean }> => {
  return useMutation<InvoiceResponse, ApiErrorResponse, { invoiceId: string; giftCardPurchase?: boolean }>({
    mutationFn: ({ invoiceId, giftCardPurchase }) => closeInvoice(invoiceId, giftCardPurchase),
    onSuccess,
    onError,
  });
};

export const useCloseGiftCardInvoice = (
  onSuccess?: (data: InvoiceResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<InvoiceResponse, ApiErrorResponse, { invoiceId: string; data: GiftCardCloseInvoiceRequest }> => {
  return useMutation<InvoiceResponse, ApiErrorResponse, { invoiceId: string; data: GiftCardCloseInvoiceRequest }>({
    mutationFn: ({ invoiceId, data }) => closeGiftCardInvoice(invoiceId, data),
    onSuccess,
    onError,
  });
};
