import styles from '../styles/pages/PageNotFound.module.scss';
import CandleImage from '../assets/images/candle-on-the-sea.jpg';
import { Link } from 'react-router-dom';

export const PageNotFound = () => {
  return (
    <div className={styles.pageNotFound}>
      <div>
        <h1>Something’s not right here...</h1>
        <p>This is a 404 error, which means you’ve clicked on a bad link or entered an invalid URL.</p>
        <div className={styles.actions}>
          <Link className="button" to="/book">Book a massage</Link>
          <Link className="button inverted" to="/">Home</Link>
        </div>
      </div>
      <img src={CandleImage} alt="candle on the sea" />
      
    </div>
  )
}

export default PageNotFound;
