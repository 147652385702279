import MeIcon from '../assets/icons/user-account.svg';
import GuestIcon from '../assets/icons/people.svg';
import styles from '../styles/components/BookingFlowGuests.module.scss';
import RadioBox from '../components/core/RadioBox';
import { BookingOptions, Guest } from '../types/bookings';
import { Input } from './core/Input';
import { Dispatch, SetStateAction } from 'react';
import ArrowDownIcon from '../assets/icons/arrow-down.svg'
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useToast } from './core/ToastManager';
import { formatPhoneNumber } from '../utils/formatPhone';

interface Props {
  guestsInfo: Guest[];
  setGuestsInfo: (data: Guest[]) => void;
  removeGuest: (index: number) => void;
  activeGuestIndex: number;
  setActiveGuestIndex: Dispatch<SetStateAction<number>>;
  bookingOptions: BookingOptions
  handlePregnancySelection: (index?: number) => void;
  control: Control
  errors: FieldErrors;
  clearErrorsForGuest: () => void;
}


const getMinorityOptions = (isGuest?: boolean) => {
const pronoun = isGuest ? 'Guest is a' : 'I am a'
  return [
    {
      label: `${pronoun} male`,
      value: "male"
    },
    {
      label: `${pronoun} female`,
      value: "female"
    }
  ]
}

const getPregnancyOptions = (isGuest?: boolean) => {
  const pronoun = isGuest ? 'Guest is' : 'I am'
  return [
    {
      label: `${pronoun} less than 12 weeks pregnant`,
      value: "under12weeks"
    },
    {
      label: `${pronoun} more than 12 weeks pregnant`,
      value: "over12weeks"
    }
  ]
}

export const BookingFlowGuests: React.FC<Props> = ({
  guestsInfo,
  setGuestsInfo,
  removeGuest,
  activeGuestIndex,
  setActiveGuestIndex,
  bookingOptions,
  handlePregnancySelection,
  control,
  errors,
  clearErrorsForGuest
}) => {

  const { addToast } = useToast()
  const userForm = () => (
    <div>
      <p className={styles.radioGroupTitle}>Select any of the following that apply to you:</p>
      <div className={styles.radioBoxOptions}>
        <Controller
          name="userSelection"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <>
              <RadioBox
                id="userMinority"
                label={bookingOptions.minorOptions.me.title}
                value="minor"
                selectedValue={field.value}
                onChange={(value) => {
                  field.onChange(value)
                  if (value === 'minor:male' || value === 'minor:female') {
                    addToast('If you are a minor your massage therapist’s gender will match yours.', 'info', 4000)
                  }
                }}
                // TODO: uncomment if we need to fetch options from the API
                // options={bookingOptions.minorOptions.me.options.map(option => ({
                //   label: option,
                //   value: option,
                // }))}
                options={getMinorityOptions()}
              />
              <RadioBox
                id="userPregnancy"
                label={bookingOptions.pregnancyOptions.me.title}
                value="pregnant"
                selectedValue={field.value}
                // onChange={(value) => field.onChange(value)}
                options={getPregnancyOptions()}
                onChange={(value) => {
                  field.onChange(value)
                  if (value === 'pregnant:over12weeks') {
                    addToast('Prenatal Glow $15. This enhancement will automatically be added to your booking if “I am pregnant is selected.”', 'info', 4000)
                  }
                  if (value === 'pregnant:under12weeks') {
                    handlePregnancySelection()
                  }
                }}
                error={errors.userSelection?.message as string}
                // TODO: uncomment if we need to fetch options from the API
                // options={bookingOptions.pregnancyOptions.me.options.map(option => ({
                //   label: option,
                //   value: option,
                // }))}
              />
            </>
          )}
        />
      </div>
    </div>
  );

  const guestForm = (index: number) => (
    <div key={index} className={`${styles.guest} ${activeGuestIndex === index ? styles.activeGuest : ""}`}>
      <div onClick={() => setActiveGuestIndex(index)} className={styles.guestHeader}>
        <h2>Guest {index + 1}</h2>
        <div className={styles.headerRight}>
          {guestsInfo[index].firstName && guestsInfo[index].lastName && <p>{guestsInfo[index].firstName} {guestsInfo[index].lastName}</p>}
          <img className={styles.arrowIcon} src={ArrowDownIcon} alt="arrow down icon" />
        </div>
      </div>
      <div className={styles.guestForm}>
        <div className={styles.inputsRow}>
          <Controller
            name={`guests.${index}.firstName`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                id={`firstNameGuest${index}`}
                label="First Name"
                inputProps={field}
                // error={errors.guests?.[index]?.firstName?.message}
                error={
                  Array.isArray(errors.guests) 
                    ? errors.guests[index]?.firstName?.message 
                    : undefined
                }
              />
              )}
            />
          <Controller
            name={`guests.${index}.lastName`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                id={`lastNameGuest${index}`}
                label="Last Name"
                inputProps={field}
                // error={errors.guests?.[index]?.lastName?.message}
                error={
                  Array.isArray(errors.guests) 
                    ? errors.guests[index]?.lastName?.message 
                    : undefined
                }
              />
              )}
            />
        </div>
        <div className={styles.inputsRow}>
          <Controller
            name={`guests.${index}.email`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                id={`emailGuest${index}`}
                label="Email Address"
                inputProps={{ ...field, autoComplete: 'username' }}
                // error={errors.guests?.[index]?.email?.message}
                error={
                  Array.isArray(errors.guests) 
                    ? errors.guests[index]?.email?.message 
                    : undefined
                }
              />
              )}
          />
          <Controller
            name={`guests.${index}.phone`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                id={`phoneGuest${index}`}
                label="Mobile Number"
                inputProps={{
                  ...field,
                  onChange: (e) => {
                    const formattedValue = formatPhoneNumber(e.target.value);
                    field.onChange(formattedValue);
                  },
                  type: 'tel',
                }}
                // error={errors.guests?.[index]?.mobileNumber?.message}
                error={
                  Array.isArray(errors.guests) 
                    ? errors.guests[index]?.phone?.message 
                    : undefined
                }
              />
            )}
          />
        </div>
        <p className={styles.radioGroupTitle}>Select any of the following that apply:</p>
        <div className={styles.radioBoxOptions}>
          <Controller
            name={`guests.${index}.selection`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <RadioBox
                  id={`guestMinority${index}`}
                  label={bookingOptions.minorOptions.guest.title}
                  value="minor"
                  selectedValue={field.value}
                  onChange={(value) => {
                    field.onChange(value)
                    const updatedGuests: Guest[] = [...guestsInfo];
                    updatedGuests[index].gender = value;
                    setGuestsInfo(updatedGuests);
                    if (value === 'minor:male' || value === 'minor:female') {
                      addToast('If you are a minor your massage therapist’s gender will match yours.', 'info', 4000)
                    }
                  }}
                  options={getMinorityOptions(true)}
                  // TODO: uncomment if we need to fetch options from the API
                  // options={bookingOptions.minorOptions.guest.options.map(option => ({
                  //   label: option,
                  //   value: option,
                  // }))}
                />
                <RadioBox
                  id={`guestPregnancy${index}`}
                  label={bookingOptions.pregnancyOptions.guest.title}
                  value="pregnant"
                  selectedValue={field.value}
                  onChange={(value) => {
                    field.onChange(value)
                    const updatedGuests: Guest[] = [...guestsInfo];
                    updatedGuests[index].pregnantOver12Weeks = !!value;
                    setGuestsInfo(updatedGuests);
                    if (value === 'pregnant:over12weeks') {
                      addToast('Prenatal Glow $15. This enhancement will automatically be added to your booking if “I am pregnant is selected.”', 'info', 4000)
                    }
                    if (value === 'pregnant:under12weeks') {
                      handlePregnancySelection()
                    }
                  }}
                  options={getPregnancyOptions(true)}
                  // TODO: uncomment if we need to fetch options from the API
                  // options={bookingOptions.pregnancyOptions.guest.options.map(option => ({
                  //   label: option,
                  //   value: option,
                  // }))}
                />
              </>
            )}
          />
        </div>

        <button
          type="button"
          className="button__underline"
          onClick={() => removeGuest(index)}
        >
          REMOVE GUEST
        </button>
      </div>
    </div>
  );


  return (
    <div className={styles.guestsSection}>
      <div className={styles.guests}>
        <div
          className={`${styles.guestOption} ${guestsInfo.length === 0 ? styles.selected : ''}`}
          onClick={() => {
            setGuestsInfo([])
            clearErrorsForGuest()
          }}
        >
          <img src={MeIcon} alt="user icon" />
          <p>Just Me</p>
        </div>
        <div
          className={`${styles.guestOption} ${guestsInfo.length > 0 ? styles.selected : ''}`}
          onClick={() => setGuestsInfo([{
            firstName: "",
            lastName: "",
            phone: "",
            email: ""
          }])}
        >
          <img src={GuestIcon} alt="users icon" />
          <p>Me & a Guest</p>
        </div>
      </div>

      {guestsInfo.length === 0 && userForm()}
      {guestsInfo.length > 0 && (
        <div>
        {userForm()}
        {guestsInfo.map((_, index) => (
          <div key={index}>
            {guestForm(index)}
          </div>
        ))}
        </div>
      )}
    </div>
  );
};
