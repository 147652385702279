import styles from '../styles/components/DashboardCard.module.scss'

interface DashboardCardProps {
  iconUrl?: string
  title: string
  children: React.ReactNode
  className?: string
}

export const DashboardCard:React.FC<DashboardCardProps> = ({
  iconUrl,
  title,
  children,
  className = ''
}) => {
  return (
    <div className={`${styles.dashboardCard} ${className ? className : ''}`}>
      <div>
        {iconUrl && <img src={iconUrl} alt={title} />}
        <div className={styles.headerContainer}>
          <h2>{title}</h2>
        </div>
      </div>
        {children}
    </div>
  )
}
