import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styles from '../styles/pages/ResetPassword.module.scss';
import { Input } from '../components/core/Input';
import { useToast } from '../components/core/ToastManager';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiErrorResponse } from '../types/api';
import { useResetUserPassword } from '../hooks/useUserProfile';
import Spinner from '../components/core/Spinner';

interface FormData {
  authorizationCode: string;
  newPassword: string;
  repeatNewPassword: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object().shape({
  authorizationCode: yup.string().required('Authorization code is required'),
  newPassword: yup.string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain a lowercase letter')
    .matches(/[A-Z]/, 'Password must contain an uppercase letter')
    .matches(/[0-9]/, 'Password must contain a numeral')
    .matches(/[^a-zA-Z0-9]/, 'Password must contain a special character'),
  repeatNewPassword: yup.string()
    .oneOf([yup.ref('newPassword')], 'Passwords do not match.')
    .required('Please repeat your new password'),
});

const ResetPassword: React.FC = () => {
  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const newPassword = useWatch({ control, name: 'newPassword' });
  const repeatNewPassword = useWatch({ control, name: 'repeatNewPassword' });
  const passwordsMatch = newPassword && repeatNewPassword && newPassword === repeatNewPassword;

  const { addToast } = useToast();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('user_id') || '';
  const verificationId = searchParams.get('verification_id') || '';
  const navigate = useNavigate()

  const mutation = useResetUserPassword(
    () => {
      addToast('Password successfully reset!', 'success', 5000);
      navigate('/login');
    },
    (error: ApiErrorResponse) => {
      addToast(error.errorMessages[0] || 'Failed to reset password. Please try again.', 'error', 5000);
    }
  );

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    mutation.mutate({
      user_id: userId,
      verification_id: verificationId,
      verification_code: data.authorizationCode,
      new_password: data.newPassword,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <h2>Reset Your Password</h2>
      <Input
        id="authorizationCode"
        label="Authorization Code"
        inputProps={{ ...register('authorizationCode'), type: 'text', autoComplete: 'off' }}
        error={errors.authorizationCode?.message}
      />
      <Input
        id="newPassword"
        label="Create a Password"
        inputProps={{ ...register('newPassword'), type: 'password', autoComplete: 'new-password' }}
        error={errors.newPassword?.message}
      />
      <div className={styles.passwordTip}>
        <p>Please make sure your password:</p>
        <ul>
          <li>Is at least 8 characters long</li>
          <li>Does not contain your full name</li>
          <li>Contains a lowercase letter (a through z)</li>
          <li>Contains an uppercase letter (A through Z)</li>
          <li>Contains a numeral (0-9)</li>
          <li>Contains a special character (such as @, !, $, %)</li>
        </ul>
      </div>
      <Input
        id="repeatNewPassword"
        label="Repeat Your Password"
        inputProps={{ ...register('repeatNewPassword'), type: 'password', autoComplete: 'new-password' }}
        error={errors.repeatNewPassword?.message}
        success={passwordsMatch ? 'Passwords Match' : undefined}
      />
      {mutation.isPending ? <Spinner /> : (
        <button
          className={`button ${styles.button} ${!isValid ? "disabled" : ""}`}
          disabled={!isValid}
          type="submit"
          >
        Reset Password
      </button>
      ) }

      <p className={styles.newAccountLink}>New to The NOW? <Link to="/register">Create an Account</Link></p>
    </form>
  );
};

export default ResetPassword;
