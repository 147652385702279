import { useEffect, useState } from "react";
import styles from "../styles/pages/PurchaseConfirmation.module.scss"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useUserProfile } from "../hooks/useUserProfile";
import { useBoutique } from "../hooks/useBoutiques";
import ErrorScreen from "../components/core/ErrorMessages";
import Spinner from "../components/core/Spinner";
import BookingDetails from "../components/BookingDetails";
import { useServices, useTherapists } from "../hooks/useBookings";
import { DateTime } from "luxon";
import Modal from "../components/core/Modal";
import PreferencesModal from "../components/Preferences";


export const BookingConfirmation = () => {
  const { boutiqueId, serviceId } = useParams();
  const [searchParams] = useSearchParams()
  const { data: userProfile, isLoading: userProfileLoading } = useUserProfile();
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(boutiqueId || '')

  const time = searchParams.get('time')
  const therapistId = searchParams.get('therapistId')
  const encodedAddOns = searchParams.get('addOns')
  const formattedDate = time ? DateTime.fromISO(time).toFormat("MMM dd") : null
  const formattedTime = time ? DateTime.fromISO(time).toFormat("hh:mm a") : null
  const { data: services, isLoading: isLoadingServices, error: servicesError } = useServices(boutiqueId || '')
  const { data: therapists, isLoading: isLoadingTherapists, error: therapistsError } = useTherapists(boutiqueId || '');
  const selectedService = services?.services.find(service => service.zenotiId === serviceId)
  const selectedTherapist = therapists?.therapists.find(therapist => therapist.id === therapistId)
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!boutiqueId || !serviceId) {
      navigate('/book');
    }
  }, [])

  if (isLoadingServices || userProfileLoading || isLoadingBoutique || isLoadingTherapists) {
    return <Spinner />
  }

  if (servicesError || boutiqueError || therapistsError) {
    return <ErrorScreen errors={[therapistsError ?? undefined, boutiqueError ?? undefined, servicesError ?? undefined]} />
  }

  let addOns = []

  if (encodedAddOns) {
    try {
      addOns = JSON.parse(decodeURIComponent(encodedAddOns));
    } catch (error) {
      console.error("Failed to parse guests data:", error);
    }
  }

  const selectedAddOns = addOns.map((addOn: string) => {
    const service = services?.services.find(service => service.zenotiId === addOn)
    return service?.name
  })
  
  return (
    <div className={styles.purchaseConfirmation}>
       <div className={styles.orderDetails}>
        <h3>Appointment Details</h3>
        <BookingDetails
          title={selectedService?.name || ''}
          date={formattedDate || ''}
          time={formattedTime || ''}
          location={boutique?.data.name || ''}
          locationId={boutiqueId}
          therapistName={selectedTherapist?.nickName || ''}
          addOns={selectedAddOns}
        />
      </div>
      <div className={styles.separator}></div>
      <div className={styles.message}>
        <h3>Thank you {userProfile?.data.firstName ? `, ${userProfile.data.firstName}!` : "!"}</h3>
        <div>
          <p>Your booking is confirmed at {boutique?.data.name}.</p>
          <p>Please arrive 15 minutes early to check-in with one of our Experience Guides to ensure your service starts on time.</p>
          <p>We’ve got your back!</p>
          <p>xoxo, The NOW</p>
        </div>
        <div className={styles.separator}></div>

        <p>You can view and manage your appointment in your account.</p>
        <Link to="/dashboard" className={`button`}>Complete Questionnaire</Link>
        <button onClick={() => setShowPreferencesModal(true)} className={`button inverted`}>Complete personal preferences</button>
      </div>
      {showPreferencesModal && (
          <Modal onClose={() => setShowPreferencesModal(false)} isOpen={showPreferencesModal} className={styles.preferencesModal}>
            <PreferencesModal onClose={() => setShowPreferencesModal(false)} />
          </Modal>
        )}
    </div>
  )
}

export default BookingConfirmation