import styles from "../styles/pages/PurchaseConfirmation.module.scss"
import OrderDetails from "../components/OrderDetails"
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMembershipById } from "../hooks/useMemberships";
import { numberToNumeralsText } from "../utils/caseConverter";
import Spinner from "../components/core/Spinner";
import { useEffect } from "react";
import { useUserProfile } from "../hooks/useUserProfile";


export const PurchaseConfirmation = () => {
  const { boutiqueId, membershipId } = useParams();
  const { data: membership, isLoading: isMembershipLoading, error: membershipError } = useMembershipById(membershipId!, boutiqueId!)
  const { data: userProfile, isLoading: userProfileLoading } = useUserProfile();
  const { name, durationInMinutes, frequencyPerMonth, boutique } = membership?.membershipGroup.memberships || {};
  const navigate = useNavigate();
  const isBookingRedirect = !!sessionStorage.getItem('restoreBooking')
  const bookingLink = isBookingRedirect ? '/book?restoreSessionFromDuration=true' : '/book'

  useEffect(() => {
    if (!boutiqueId || !membershipId) {
      navigate('/settings/memberships');
    }
  }, [])

  if (isMembershipLoading || userProfileLoading) {
    return <Spinner />
  }

  if (membershipError) {
    return <div>Error loading membership details: {membershipError.errorMessages[0]}</div>
  }

  return (
    <div className={styles.purchaseConfirmation}>
       <div className={styles.orderDetails}>
        <h3>Order Details</h3>
        <OrderDetails 
          title={name || ''}
          duration={`${durationInMinutes} minutes`}
          frequency={`${numberToNumeralsText(frequencyPerMonth || 0)} per month`}
          location={boutique?.name || ''}
        />
      </div>
      <div className={styles.separator}></div>
      <div className={styles.message}>
        <h3>Thank you {userProfile?.data.firstName ? `, ${userProfile.data.firstName}!` : "!"}</h3>
        <p>Your membership purchase is complete and you will receive a confirmation email shortly.</p>
        <Link to={bookingLink} className={`button`}>{isBookingRedirect ? 'Continue Booking' : 'Book Now'}</Link>
        <button className={`button inverted`}>View Membership Perks</button>
      </div>
    </div>
  )
}

export default PurchaseConfirmation