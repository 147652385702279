import { get } from './apiClient';
import { BoutiquesResponse, Boutique, BoutiquesRequestParams, PromotionResponse } from '../types/boutiques';

export const fetchBoutiques = async (options: BoutiquesRequestParams = {}): Promise<BoutiquesResponse> => {
  const params: Record<string, any> = {};

  // Only add properties to params if they are defined
  if (options.lat !== undefined) params.lat = options.lat;
  if (options.lng !== undefined) params.lng = options.lng;
  if (options.ip !== undefined) params.ip = options.ip;
  if (options.perPage !== undefined) params.per_page = options.perPage;
  if (options.page !== undefined) params.page = options.page;

  const response = await get<BoutiquesResponse>('/api/v1/boutiques', { params });

  return response.data;
};

export const fetchBoutiqueById = async (id: string): Promise<{ data: Boutique }> => {
  const response = await get<{ data: Boutique }>(`/api/v1/boutiques/${id}`);
  
  return response.data;
};

export const fetchBoutiquePromotions = async (boutiqueId: string): Promise<PromotionResponse> => {
  const response = await get<PromotionResponse>(`/api/v1/boutiques/${boutiqueId}/promotions`);

  return response.data;
}
