import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styles from '../styles/pages/CreateAccount.module.scss';
import { Input } from '../components/core/Input';
import { AutoCompleteInput } from '../components/core/AutoCompleteInput';
import { Checkbox } from '../components/core/Checkbox';
import Modal from '../components/core/Modal';
import MemberIcon from '../assets/icons/become-a-member.svg'
import AppointmentsIcon from '../assets/icons/appointments.svg'
import GiftCardsIcon from '../assets/icons/gift-cards.svg'
import { useMutation } from '@tanstack/react-query';
import { signUp } from '../api/auth';
import { useAuth } from '../components/auth/AuthProvider';
import { useToast } from '../components/core/ToastManager';
import { useBoutiques } from '../hooks/useBoutiques';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiErrorResponse } from '../types/api';
import { formatPhoneNumber, normalizePhoneNumber } from '../utils/formatPhone';
import { useUserProfile } from '../hooks/useUserProfile';
import Spinner from '../components/core/Spinner';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  password: string;
  repeatPassword: string;
  boutique: string;
  terms: boolean;
}

const CreateAccount: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showBookingLocationModal, setShowBookingLocationModal] = useState(false);
  const [boutiqueValue, setBoutiqueValue] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location');
  const isBookingRedirect = searchParams.get('booking') === 'true';
  const { login } = useAuth();
  const { addToast } = useToast();

  const { data } = useBoutiques({ perPage: 100, page: 1 });

  const boutiques = data?.data.boutiques
  .filter(b => b.useOldSite === false)
  .map(boutique => ({
    value: boutique.id,
    label: boutique.name,
  }));

  const preselectedLocation = boutiques?.find(loc => loc.value === locationId);

  const schema: yup.ObjectSchema<FormData> = yup.object().shape({
    firstName: yup.string()
      .matches(/^[A-Za-z]+$/, 'First name should only contain letters')
      .max(32, 'First name cannot be longer than 32 characters')
      .required('First name is required'),
    lastName: yup.string()
      .matches(/^[A-Za-z]+$/, 'Last name should only contain letters')
      .max(32, 'Last name cannot be longer than 32 characters')
      .required('Last name is required'),
    email: yup.string().email('Invalid email address').required('Email is required'),
    mobileNumber: yup.string()
      .required('Mobile number is required')
      .matches(/^\(\d{3}\) \d{3}\.\d{4}$/, 'Mobile number is not valid'),
    password: yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]/, 'Password must contain a lowercase letter')
      .matches(/[A-Z]/, 'Password must contain an uppercase letter')
      .matches(/[0-9]/, 'Password must contain a numeral')
      .matches(/[^a-zA-Z0-9]/, 'Password must contain a special character')
      .test('no-full-name', 'Password should not contain your full name', function (value) {
        const { firstName, lastName } = this.parent;
        const fullName = `${firstName}${lastName}`.toLowerCase();
        return !value?.toLowerCase().includes(fullName);
      }),
    repeatPassword: yup.string()
      .oneOf([yup.ref('password')], 'Passwords Do Not Match')
      .required('Please repeat your password'),
    boutique: yup.string().required('Please select a boutique').test(
      'is-valid-option',
      'Please select a valid option',
      function (value) {
        return boutiques?.some(option => option.value === value);
      }
    ),
    terms: yup.boolean().oneOf([true], 'You must agree to the terms').required('You must agree to the terms'),
  });

  const { register, handleSubmit, setValue, control, formState: { errors, isValid }, setError } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      password: '',
      repeatPassword: '',
      boutique: '',
    }
  });

  const { data: userProfile, isLoading: isUserProfileLoading, error: userProfileError } = useUserProfile()

  useEffect(() => {
    if (!isUserProfileLoading && !userProfileError && userProfile?.data.email) {
      navigate('/dashboard', { replace: true });
    }
  }, [userProfile])

  useEffect(() => {
    if (preselectedLocation?.value) {
      setValue('boutique', preselectedLocation.value);
    }
  }, [preselectedLocation, setValue]);

  const mutation = useMutation({
    mutationFn: (data: FormData) => signUp(data.email, data.firstName, data.lastName, data.boutique, data.password, data.mobileNumber),
    onSuccess: (data) => {
      login(data.headers);
      addToast('Successfully signed up', 'success');
      setIsModalOpen(true);
    },
    onError: (error: ApiErrorResponse) => {
      error.errors.forEach(err => {
        switch (err.key) {
          case 'email':
            setError('email', { type: 'manual', message: 'Email already in use' });
            addToast('Email already in use', 'error');
            break;
          case 'mobile_phone':
            setError('mobileNumber', { type: 'manual', message: 'Phone number already in use' });
            addToast('Phone number already in use', 'error');
            break;
          default:
            addToast('An error occurred during sign up', 'error');
        }
      });
    },
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const normalizedData = {
      ...data,
      mobileNumber: normalizePhoneNumber(data.mobileNumber),
    };

    mutation.mutate(normalizedData);
  };

  const onSuccessModalClose = () => {
    setIsModalOpen(false);
    navigate(isBookingRedirect ? '/book?restoreSession=true' : '/memberships');
  };

  if (isUserProfileLoading) return <Spinner />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h2>Create Your Account</h2>

        <Input id="firstName" label="First Name" inputProps={register('firstName')} error={errors.firstName?.message} />
        <Input id="lastName" label="Last Name" inputProps={register('lastName')} error={errors.lastName?.message} />
        <Input id="email" label="Email Address" inputProps={{ ...register('email'), autoComplete: 'username' }} error={errors.email?.message} apiError={errors.email?.type === 'manual' ? true : false} />
        <Controller
        name="mobileNumber"
        control={control}
        render={({ field }) => (
          <Input
            id="mobileNumber"
            label="Mobile Number"
            inputProps={{
              ...field,
              onChange: (e) => {
                const formattedValue = formatPhoneNumber(e.target.value);
                field.onChange(formattedValue);
              },
              type: 'tel',
            }}
            error={errors.mobileNumber?.message}
            apiError={errors.mobileNumber?.type === 'manual' ? true : false}
          />
        )}
      />
        <Input id="password" label="Create Password" inputProps={{ ...register('password'), type: 'password', autoComplete: 'new-password' }} error={errors.password?.message} />
        <Input id="repeatPassword" label="Repeat Your Password" inputProps={{ ...register('repeatPassword'), type: 'password', autoComplete: 'new-password' }} error={errors.repeatPassword?.message} />

        <Controller
          name="boutique"
          control={control}
          render={({ field }) => (
            <AutoCompleteInput
              id="boutique"
              label="Your Boutique"
              inputProps={field}
              error={errors.boutique?.message}
              options={boutiques || []}
              value={field.value}
              // onChange={(value) => {
              //   if (isBookingRedirect && locationId) {
              //     setBoutiqueValue(value);
              //     setShowBookingLocationModal(true);
              //   } else {
              //     field.onChange(value);
              //     setValue('boutique', value);
              //   }
              // }}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
          )}
        />

        <Controller
          name="terms"
          control={control}
          render={({ field }) => (
            <Checkbox
              id="terms"
              checked={field.value}
              onChange={field.onChange}
              label={
                <span>
                  I agree to the <Link to="/terms">Terms of Use Agreement</Link> & <Link to="/privacy-policy">Privacy Policy</Link>
                </span>
              }
              error={errors.terms?.message}
            />
          )}
        />

        <button className={`button ${styles.button} ${!isValid ? "disabled" : ""}`} disabled={!isValid} type="submit">
          {mutation.isPending ? 'Creating Account...' : 'Create Account'}
        </button>
        {mutation.isError && (
          <p className={styles.errorMessage}>
            {mutation.error.error_messages.length ? mutation.error.error_messages[0] : 'An error occurred'}
          </p>
        )}
        <Link className={styles.haveAccountLink} to="/login">Have an account? Login</Link>
      </form>
      <Modal isOpen={isModalOpen} onClose={onSuccessModalClose}>
        <div className={styles.successModal}>
          <h2>You have successfully created your account!</h2>
          <p>Welcome to The NOW Massage app. Here you can:</p>
          <ul>
            <li><img src={AppointmentsIcon} alt="booking icon" /> Book and manage appointments</li>
            <li><img src={GiftCardsIcon} alt="eye icon" /> View gift card balance</li>
            <li><img src={MemberIcon} alt="gear icon" /> Manage your membership</li>
          </ul>
          <button className="button" onClick={onSuccessModalClose}>Continue</button>
        </div>
      </Modal>
      <Modal isOpen={showBookingLocationModal} onClose={() => setShowBookingLocationModal(false)} hideCloseButton>
        <div className={styles.locationChangeModal}>
          <h2>You’ve selected a different boutique!</h2>
          <p>Do you want to change your booking to this new boutique?</p>
          <button
            onClick={() => {
              setValue('boutique', boutiqueValue);
              setShowBookingLocationModal(false);
            }}
            className={`button`}
          >
            YES, PLEASE CHANGE MY BOUTIQUE
          </button>
          <button onClick={() => setShowBookingLocationModal(false)} className={`button__underline ${styles.cancelButton}`}>
            NO, CHANGE MY BOUTIQUE BACK TO THE ORIGINAL
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CreateAccount;
