import React from 'react';
import { ApiErrorResponse } from '../../types/api';
import styles from '../../styles/components/ErrorScreen.module.scss';


interface ErrorScreenProps {
  errors?: (ApiErrorResponse | undefined)[];
  fallbackMessage?: string;
}

const ErrorScreen: React.FC<ErrorScreenProps> = ({ errors, fallbackMessage = 'Please try again later.' }) => {
  const error = errors?.find(err => err && err.errorMessages && err.errorMessages.length > 0)

  if (!error) return null;

  return (
    <div className={styles.errors}>
      {errors?.map((error, index) => {
        if (error) return (
          <div className={styles.error} key={error?.errorMessages[0] + index}>
            <span>Error:</span> {error?.errorMessages[0]}. {fallbackMessage}
          </div>
        )
      })}
    </div>
  )
};

export default ErrorScreen;
