import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import styles from '../styles/pages/Booking.module.scss'
import { generateStepGroups } from '../constants/bookingSteps';
import { useBoutique, useBoutiquePromotions } from '../hooks/useBoutiques'
import { useUserMemberships, useUserProfile } from '../hooks/useUserProfile';
import { useServices } from '../hooks/useBookings';
import { useToast } from '../components/core/ToastManager';
import BookingStepper from "../components/BookingStepper"
import Modal from '../components/core/Modal';
import { BookingFlowGuests } from '../components/BookingFlowGuests';
import { BookingFlowDuration } from '../components/BookingFlowDuration';
import { BookingFlowPressure } from '../components/BookingFlowPressure';
import { BookingFlowEnhancements } from '../components/BookingFlowEnhancements';
import { BookingFlowAppointment } from '../components/BookingFlowAppointment';
import { BookingFlowMassage } from '../components/BookingFlowMassage';
import MapPinIcon from '../assets/icons/map-pin.svg'
import PhoneIcon from '../assets/icons/phone.svg'
import { Boutique } from '../types/boutiques';
import { Service } from '../types/bookings';
import { useQueryClient } from '@tanstack/react-query';
import LocationSearch from '../components/LocationSearch';
import { formatPhoneNumber } from '../utils/formatPhone';
import Spinner from '../components/core/Spinner';


export interface Guest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender?: string;
  minor?: boolean;
  pregnant?: string;
  selection?: string;
}

interface GuestFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  selection: string;
}

export interface UserOptions {
  minor?: boolean 
  gender?: string
  pregnant?: string
}

interface GuestsForm {
  userSelection?: string;
  guests?: Guest[];
}

export interface Selection {
  duration?: number;
  pressure?: string;
  createMembership?: boolean;
  massage?: Service
  enhancements?: Service[]
}

const guestSchema = yup.object().shape({
  firstName: yup.string()
    .matches(/^[A-Za-z]+$/, 'First name should only contain letters')
    .max(32, 'First name cannot be longer than 32 characters')
    .required('First name is required'),
  lastName: yup.string()
    .matches(/^[A-Za-z]+$/, 'Last name should only contain letters')
    .max(32, 'Last name cannot be longer than 32 characters')
    .required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string()
    .matches(/^\(\d{3}\) \d{3}\.\d{4}$/, 'Phone number is not valid')
    .required('Phone number is required'),
  selection: yup.string(),
});

const schema = yup.object().shape({
  userSelection: yup.string(),
  guests: yup.array().of(guestSchema).when([], {
    is: (guests: Guest[]) => guests?.length > 0,
    then: (schema) => schema.required('Guest information is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
});


export const Booking:React.FC = () => {
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location')
  const promotionId = searchParams.get('promotionId')
  const restoreSession = searchParams.get('restoreSession') === 'true'
  const restoreSessionFromDuration = searchParams.get('restoreSessionFromDuration') === 'true'
  const { addToast } = useToast()
  const navigate = useNavigate();
  const queryClient = useQueryClient()


  const { data: userProfile, isLoading: isLoadingUserProfile, error: userProfileError } = useUserProfile()
  const boutiqueId = locationId || userProfile?.data.boutiqueId || ''
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(boutiqueId)
  const [selectedBoutique, setSelectedBoutique] = useState<Boutique | undefined>(boutique?.data || undefined);
  const { data: services, isLoading: isLoadingServices, error: servicesError } = useServices(selectedBoutique?.id || boutiqueId);
  const { data: userMemberships, isLoading: isLoadingMemberships } = useUserMemberships(true);
  const { data: boutiquePromotions, isLoading: isLoadingPromotions } = useBoutiquePromotions(selectedBoutique?.id || boutiqueId);
  const activePromotion = boutiquePromotions?.data.promotions.find(promotion => promotion.id.toString() === promotionId)
  const defaultPromotion = boutique?.data.offerBanner
  const promo = activePromotion?.bookingPromoDisplayText || defaultPromotion

  const [isPregnancyModalOpen, setIsPregnancyModalOpen] = useState(false)
  const [activeGuestIndex, setActiveGuestIndex] = useState(0)
  const [userOptions, setUserOptions] = useState<UserOptions | null>(null)
  const [userSelections, setUserSelections] = useState<Selection | null>(null)
  const [guestsSelections, setGuestsSelections] = useState<Selection[] | null>(null)
  const [guestsInfo, setGuestsInfo] = useState<Guest[]>([]);
  const [checkoutLink, setCheckoutLink] = useState('')
  const [showLocationSearch, setShowLocationSearch] = useState(false)

  const [currentStepId, setCurrentStepId] = useState<string>('guests');
  const [completedSteps, setCompletedSteps] = useState<string[]>([]);
  // Dynamically generate steps and labels based on current step and guests
  const stepGroups = generateStepGroups(guestsInfo);
  const currentGroup = stepGroups.find(group =>
    group.steps.some(step => step.id === currentStepId)
  );
  const currentStep = currentGroup?.steps.find(step => step.id === currentStepId);

  const formatSelectionForDefault = (options: UserOptions): string => {
    if (options.minor) {
      return `minor:${options.gender}`;
    }
    if (options.pregnant) {
      return `pregnant:${options.pregnant}`;
    }
    return '';
  };
  
  const { control, handleSubmit, unregister, setError, watch, resetField, clearErrors, formState: { errors, isValid } } = useForm<GuestsForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      userSelection: userOptions ? formatSelectionForDefault(userOptions) : '',
      guests: guestsInfo.length > 0 ? guestsInfo.map(guest => ({
        firstName: guest.firstName,
        lastName: guest.lastName,
        email: guest.email,
        phone: guest.phone,
        selection: formatSelectionForDefault(guest),
      })) : [],
    },
  });

  const clearErrorsForGuest = () => {
    resetField('guests')
    clearErrors('guests')
  }

  const userExtraOptions = watch('userSelection');
  const guestsExtraOptions = watch('guests') || [];
  const hasPregnancyIssue = 
  userExtraOptions === 'pregnant:under12weeks' || 
  guestsExtraOptions.some((guest: Guest) => guest.selection === 'pregnant:under12weeks');

  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showRestoreDataModal, setShowRestoreDataModal] = useState(false)

  useEffect(() => {
    if (!selectedBoutique && boutiqueId === 'null') {
      setShowLocationSearch(true)
    }
    if (selectedBoutique?.useOldSite && selectedBoutique?.oldBookingUrl) {
      window.location.href = selectedBoutique.oldBookingUrl
    }
  }, [selectedBoutique, boutiqueId])

  // Effect to clear errors if pregnant option is unselected
  useEffect(() => {
    if (!hasPregnancyIssue) {
      clearErrors('userSelection');
      guestsExtraOptions.forEach((_, index) => {
        clearErrors(`guests.${index}.selection`);
      });
    }
  }, [hasPregnancyIssue, clearErrors]);

  useEffect(()=> {
    if (!isLoadingBoutique && !boutiqueError) {
      setSelectedBoutique(boutique?.data)
    }
  }, [boutique])

  // Saving entered data to a session storage

  useEffect(() => {
    if (!userSelections && !guestsSelections && !userOptions && guestsInfo.length === 0 && !restoreSession && userProfile) {
      const savedData = sessionStorage.getItem('bookingData');
      if (savedData) setShowRestoreDataModal(true)
    }
    if (!userProfile && !isLoadingUserProfile) {
      queryClient.invalidateQueries({ queryKey: ['userProfile'] });
    }
    if (userProfile && (restoreSession || restoreSessionFromDuration)) {
      loadDataFromSessionStorage()
      if (restoreSession) {
        setCurrentStepId("appointment")
      } else {
        setCurrentStepId("duration-host")
      }
    }
  }, [userProfile]);

  const saveDataToSessionStorage = () => {
    const dataToSave = {
      userSelections,
      guestsSelections,
      userOptions,
      guestsInfo,
      completedSteps,
      currentStepId
    };
  
    sessionStorage.setItem('bookingData', JSON.stringify(dataToSave));
  };
  
  const loadDataFromSessionStorage = () => {
    const savedData = sessionStorage.getItem('bookingData');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setUserSelections(parsedData.userSelections || {});
      setGuestsSelections(parsedData.guestsSelections || []);
      setUserOptions(parsedData.userOptions || {});
      setGuestsInfo(parsedData.guestsInfo || []);
      setCompletedSteps(parsedData.completedSteps || [])
      setCurrentStepId(parsedData.currentStepId)
    }
  };

  const clearBookingDataFromSessionStorage = () => {
    sessionStorage.removeItem('bookingData');
  };

  const addGuest = () => {
    if (services?.bookingOptions.maxGuests && guestsInfo.length < services?.bookingOptions.maxGuests) {
      setGuestsInfo([...guestsInfo, {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
      }]);
      setActiveGuestIndex(guestsInfo.length)
    } else {
      addToast('You have reached the limit of guests', 'warning', 4500)
    }
  };

  const removeGuest = (index: number) => {
    unregister(`guests.${index}`);
    setGuestsInfo((prevGuests) => prevGuests.filter((_, i) => i !== index));
    if (guestsInfo.length > 1) {
      setActiveGuestIndex(guestsInfo.length - 2)
    }
  };

  const formatSelection = (selection: string) => {
    if (selection.startsWith('minor')) {
      return { minor: true, gender: selection.substring(6) };
    }
    if (selection.startsWith('pregnant')) {
      return { minor: false, pregnant: selection.substring(9) };
    }
    return { minor: false };
  };
  
  const formatGuest = (guest: GuestFormData) => {
    const { firstName, lastName, email, phone, selection } = guest;
    return {
      firstName,
      lastName,
      email,
      phone,
      ...formatSelection(selection || ''),
    };
  };


const isLastEnhancementStep = (): boolean => {
  // Determine if the current step is the last enhancement step for the host or guests
  if (currentStepId === 'enhancements-host' && guestsInfo.length === 0) {
    // Only the host has enhancements, and we've finished the host's step
    return true;
  }

  if (currentStepId.startsWith('enhancements-guest')) {
    const guestIndex = parseInt(currentStepId.split('-').pop()!) - 1;
    // If it's the last guest's enhancement step
    return guestIndex === guestsInfo.length - 1;
  }

  return false;
};
  
const onSubmit = (data: FieldValues) => {
  if (currentStepId === 'guests') {
    const formattedGuests = (data.guests || []).map(formatGuest);
    setGuestsInfo(formattedGuests);
    const userOptions = formatSelection(data.userSelection);
    setUserOptions(userOptions);

    if (formattedGuests.length > 0) {
      const emailCounts: Record<string, number> = {};
      formattedGuests.forEach((guest: Guest) => {
        if (guest.email) {
          emailCounts[guest.email] = (emailCounts[guest.email] || 0) + 1;
        }
      });

      const duplicateEmails = Object.keys(emailCounts).filter((email) => emailCounts[email] > 1);
      if (duplicateEmails.length > 0) {
        // Set errors for each duplicate email field
        duplicateEmails.forEach((duplicateEmail) => {
          formattedGuests.forEach((guest: Guest, index: number) => {
            if (guest.email === duplicateEmail) {
              setError(`guests.${index}.email`, {
                type: 'manual',
                message: 'This email is already used by another guest',
              });
            }
          });
        });
        return; // Prevent submission if there are duplicate emails
      }
    }
  }

  setCompletedSteps(prev => [...prev, currentStepId]);

  if (isLastEnhancementStep()) {
    saveDataToSessionStorage();
    if (userProfile) {
      setCurrentStepId('appointment');
    } else {
      navigate(`/login?location=${selectedBoutique?.id}&booking=true`)
    }
  } else if (currentStepId === 'appointment' && checkoutLink) {
    navigate(checkoutLink)
  } else {
    goToNextStep();
  }
};
  
  const triggerSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const handlePregnancySelection = (index?: number) => {
    setIsPregnancyModalOpen(true)
    const fieldId: `guests.${number}.pregnant` | "userSelection" = index !== undefined ? `guests.${index}.pregnant` : "userSelection";
    setError(fieldId, {
      type: 'manual',
      message: 'Please call the studio to book your appointment'
    });
  }

  const goToNextStep = () => {
    // If the current step is "guests", move to the first step in the first group
    if (currentStepId === "guests") {
      const firstGroup = stepGroups[0];
      if (firstGroup && firstGroup.steps.length > 0) {
        setCurrentStepId(firstGroup.steps[0].id); // Move to the first step in the first group
        setCompletedSteps(prev => [...prev, "guests"]); // Mark "guests" step as completed
      }
      return; // Exit early since we handled the "guests" step
    }
  
    // Find the index of the current step
    const stepIndex = stepGroups.findIndex(group =>
      group.steps.some(step => step.id === currentStepId)
    );
  
    // If we couldn't find the current step, return early
    if (stepIndex === -1) {
      console.error("Current step not found in step groups");
      return;
    }
  
    const currentGroup = stepGroups[stepIndex];
    const currentStepInGroupIndex = currentGroup.steps.findIndex(step => step.id === currentStepId);
  
    let nextStep = null;
  
    // If there's another step within the current group, go to that
    if (currentStepInGroupIndex < currentGroup.steps.length - 1) {
      nextStep = currentGroup.steps[currentStepInGroupIndex + 1];
    } else {
      // Otherwise, move to the first step in the next group, if it exists
      const nextGroup = stepGroups[stepIndex + 1];
      if (nextGroup && nextGroup.steps.length > 0) {
        nextStep = nextGroup.steps[0];
      }
    }
  
    // If there's a valid next step, navigate to it
    if (nextStep) {
      setCurrentStepId(nextStep.id); // Move to the next step
      setCompletedSteps(prev => [...prev, currentStepId]); // Mark current step as completed
    } else {
      console.warn("No more steps to navigate to");
    }
  };
  
  // Handle step selection (only if the step is completed)
  const handleStepSelect = (stepId: string) => {
    if (completedSteps.includes(stepId)) {
      setCurrentStepId(stepId); // Allow the user to go back to completed steps
    }
  };

  // Function to store the selected duration and optional membership flag for the user
const handleUserDurationSelect = (duration: number, createMembership = false) => {
  setUserSelections((prev) => ({ ...prev, duration, createMembership }));
};

// Function to store the selected duration for a guest
const handleGuestDurationSelect = (duration: number, index: number) => {
  const updatedSelections = guestsSelections ? [...guestsSelections] : [];
  updatedSelections[index] = { ...updatedSelections[index], duration };
  setGuestsSelections(updatedSelections);
};

const getNextStepLabel = (): string => {
    if (currentStepId === 'appointment') {
      return "Checkout";
    }
  
    if (isLastEnhancementStep()) {
      return "Appointment";
    }
  // Find the index of the current step group
  const stepGroupIndex = stepGroups.findIndex(group =>
    group.steps.some(step => step.id === currentStepId)
  );

  // If current step is not found in any group, assume it's the start
  if (stepGroupIndex === -1) {
    const firstGroup = stepGroups[0];
    if (firstGroup && firstGroup.steps.length > 0) {
      return firstGroup.steps[0].label;
    }
    return "Step not found";
  }

  const currentGroup = stepGroups[stepGroupIndex];
  const currentStepIndex = currentGroup.steps.findIndex(step => step.id === currentStepId);

  // If current step is not found within the current group, assume it's the start
  if (currentStepIndex === -1) {
    return stepGroups[0].steps[0].label;
  }

  // Check if there's a next step within the same group
  if (currentStepIndex < currentGroup.steps.length - 1) {
    return currentGroup.steps[currentStepIndex + 1].label;
  }

  // If this is the last step in the current group, check the next group
  const nextGroup = stepGroups[stepGroupIndex + 1];
  if (nextGroup && nextGroup.steps.length > 0) {
    return nextGroup.steps[0].label;
  }

  return "Checkout";
};

// Conditionally call the correct selection handler based on whether it's the user or a guest
const onDurationSelect = (duration: number, createMembership?: boolean) => {
  if (currentStepId === 'duration-host') {
    handleUserDurationSelect(duration, createMembership);
  } else {
    const guestIndex = parseInt(currentStepId.split('-').pop()!) - 1; // Extract guest index from step id
    handleGuestDurationSelect(duration, guestIndex);
  }
};

const hideContinueButton = () => {
  let hasMembership = false
  if (userMemberships?.membershipGroups) {
    for (const group of userMemberships.membershipGroups) {
      const activeMembership = group.userMemberships.find(
        (userMembership) => userMembership.status === 'active'
      );

      if (activeMembership) {
        hasMembership = true
      }
    }
  }
  if (currentStepId === 'duration-host' && !hasMembership && userProfile) return true
  return false
}

const isContinueDisabledForStep = (): boolean => {
  if (!isValid || hasPregnancyIssue) {
    return true;
  }

  if (currentStepId === 'duration-host' && !userSelections?.duration) {
    return true;
  }
  if (currentStepId.startsWith('duration-guest')) {
    const guestIndex = parseInt(currentStepId.split('-').pop()!) - 1;
    if (!guestsSelections || !guestsSelections[guestIndex]?.duration) {
      return true;
    }
  }

  if (currentStepId === 'pressure-host' && !userSelections?.pressure) {
    return true;
  }
  if (currentStepId.startsWith('pressure-guest')) {
    const guestIndex = parseInt(currentStepId.split('-').pop()!) - 1;
    if (!guestsSelections || !guestsSelections[guestIndex]?.pressure) {
      return true;
    }
  }

  if (currentStepId === 'massage-host' && !userSelections?.massage) {
    return true;
  }
  if (currentStepId.startsWith('massage-guest')) {
    const guestIndex = parseInt(currentStepId.split('-').pop()!) - 1;
    if (!guestsSelections || !guestsSelections[guestIndex]?.massage) {
      return true;
    }
  }

  return false;
};


const isSubmissionDisabled = isContinueDisabledForStep()

if (isLoadingUserProfile || isLoadingBoutique || isLoadingServices || isLoadingMemberships || isLoadingPromotions) return <Spinner />

  return (
    <div className={styles.booking}>
      <div className={styles.stepper}>
        <BookingStepper
          currentStepId={currentStepId}
          guestsInfo={guestsInfo}
          onStepSelect={handleStepSelect}
          boutique={selectedBoutique}
          setBoutique={setSelectedBoutique}
          completedSteps={completedSteps}
          userSelections={userSelections || {}}
          guestsSelections={guestsSelections || []}
        />
      </div>
      <div className={styles.formSteps}>
      {guestsInfo.length > 0 && currentStep ? <h2>{currentStep.label}</h2> : currentGroup && <h2>{currentGroup.groupLabel}</h2>}
      {currentStepId === 'guests' && <h2>Who’s Coming</h2>}
      {currentStepId === 'appointment' && <h2>Select Appointment</h2>}
      {currentStepId === 'guests' && services?.bookingOptions &&
        <BookingFlowGuests 
          guestsInfo={guestsInfo}
          setGuestsInfo={setGuestsInfo} 
          removeGuest={removeGuest}
          activeGuestIndex={activeGuestIndex}
          setActiveGuestIndex={setActiveGuestIndex}
          bookingOptions={services?.bookingOptions}
          handlePregnancySelection={handlePregnancySelection}
          control={control}
          errors={errors}
          clearErrorsForGuest={clearErrorsForGuest}
          />
        }
        {currentStepId === 'duration-host' && services && 
          <BookingFlowDuration 
            durations={services.durations}
            services={services.services}
            onSelectDuration={onDurationSelect}
            nextStep={goToNextStep}
            groupAppointment={guestsInfo && guestsInfo?.length > 0 || undefined}
            userMemberships={userMemberships}
            isLoadingMemberships={isLoadingMemberships}
            prevSelectedDuration={userSelections?.duration}
            isPregnant={!!userOptions?.pregnant}
            isMinor={userOptions?.minor}
            saveDataToSessionStorage={saveDataToSessionStorage}
            promotion={promo}
            />
        }
        {guestsInfo.map((guest, index) => (
            currentStepId === `duration-guest-${index + 1}` && services && 
              <BookingFlowDuration
                key={index}
                durations={services.durations}
                services={services.services}
                onSelectDuration={onDurationSelect}
                nextStep={goToNextStep}
                groupAppointment
                userMemberships={userMemberships}
                isLoadingMemberships={isLoadingMemberships}
                prevSelectedDuration={(guestsSelections && guestsSelections[index]?.duration) || undefined}
                isPregnant={(guestsInfo && !!guestsInfo[index]?.pregnant) || undefined}
                isMinor={(guestsInfo && guestsInfo[index]?.minor) || undefined}
                isGuest={true}
                promotion={promo}
              />
          ))}
        {currentStepId === 'pressure-host' && 
          <BookingFlowPressure
            onSelectPressure={(pressure) => setUserSelections((prev) => ({ ...prev, pressure }))}
            prevSelectedPressure={userSelections?.pressure}
            isPregnant={!!userOptions?.pregnant}
          />
        }
        {guestsInfo.map((guest, index) => (
          currentStepId === `pressure-guest-${index + 1}` &&
            <BookingFlowPressure
              key={index}
              onSelectPressure={(pressure) => {
                const updatedSelections = guestsSelections ? [...guestsSelections] : [];
                updatedSelections[index] = { ...updatedSelections[index], pressure };
                setGuestsSelections(updatedSelections);
              }}
              prevSelectedPressure={(guestsSelections && guestsSelections[index]?.pressure) || undefined}
              isPregnant={(guestsInfo && !!guestsInfo[index]?.pregnant) || undefined}
            />
        ))}
        {currentStepId === 'massage-host' && 
          <BookingFlowMassage
            onSelectMassage={(massage) => setUserSelections((prev) => ({ ...prev, massage }))}
            services={services?.services || []}
            duration={userSelections?.duration || 0}
            prevSelectedMassage={userSelections?.massage}
            isPregnant={!!userOptions?.pregnant}
          />
        }
        {guestsInfo.map((guest, index) => (
          currentStepId === `massage-guest-${index + 1}` &&
            <BookingFlowMassage
              key={index}
              onSelectMassage={(massage) => {
                const updatedSelections = guestsSelections ? [...guestsSelections] : [];
                updatedSelections[index] = { ...updatedSelections[index], massage };
                setGuestsSelections(updatedSelections);
              }}
              services={services?.services || []}
              duration={(guestsSelections && guestsSelections[index].duration) || 0}
              prevSelectedMassage={(guestsSelections && guestsSelections[index]?.massage) || undefined}
              isPregnant={(guestsInfo && !!guestsInfo[index]?.pregnant) || undefined}
            />
        ))}
        {currentStepId === 'enhancements-host' && 
          <BookingFlowEnhancements
            onSelectEnhancements={(enhancements) => {
              setUserSelections((prev) => ({ ...prev, enhancements }))
            }}
            services={services?.services || []}
            selectedMassage={userSelections?.massage}
            prevSelectedEnhancements={userSelections?.enhancements}
            isPregnant={!!userOptions?.pregnant}
            isDeepPressure={userSelections?.pressure === 'deep'}
            isGroupBooking={guestsInfo && guestsInfo.length > 0}
            
          />
        }
        {guestsInfo.map((guest, index) => (
          currentStepId === `enhancements-guest-${index + 1}` &&
            <BookingFlowEnhancements
              key={index}
              onSelectEnhancements={(enhancements) => {
                const updatedSelections = guestsSelections ? [...guestsSelections] : [];
                updatedSelections[index] = { ...updatedSelections[index], enhancements };
                setGuestsSelections(updatedSelections);
              }}
              services={services?.services || []}
              selectedMassage={guestsSelections && guestsSelections[index].massage || undefined}
              prevSelectedEnhancements={(guestsSelections && guestsSelections[index]?.enhancements) || undefined}
              isPregnant={(guestsInfo && !!guestsInfo[index]?.pregnant) || undefined}
              isDeepPressure={(guestsSelections && guestsSelections[index]?.pressure === 'deep') || undefined}
              isGroupBooking={guestsInfo && guestsInfo.length > 0}
            />
        ))}
        {currentStepId === 'appointment' && (
          <BookingFlowAppointment 
            boutiqueId={selectedBoutique?.id || boutiqueId}
            boutiquePhone={selectedBoutique?.phoneNumber || ''}
            userSelections={userSelections || {}}
            userOptions={userOptions || {}}
            guestsSelections={guestsSelections || []}
            guestsInfo={guestsInfo || []}
            hostZenotiId={userProfile?.data.zenotiId || ''}
            setCheckoutLink={setCheckoutLink}
          />
        )}

      </div>
      {!hideContinueButton() &&
        <div className={styles.controls}>
          <div className={`${styles.controlsContainer} container`}>
            <div className={styles.nextStep}>Next: {getNextStepLabel()}</div>
            <div className={styles.controlButtons}>
              {guestsInfo.length > 0 && currentStepId === 'guests' && <button onClick={addGuest} className={`button inverted ${styles.controlButton}`}>Add Another Guest</button>}
              <button onClick={triggerSubmit} disabled={isSubmissionDisabled} className={`button ${styles.controlButton} ${isSubmissionDisabled? "disabled" : ""}`}>{currentStepId === 'appointment' ? 'Proceed to checkout' : 'Continue'}</button>
            </div>
          </div>
        </div>
      }
      <Modal 
        isOpen={isPregnancyModalOpen}
        onClose={() => setIsPregnancyModalOpen(false)}
        hideCloseButton
      >
        <div className={styles.pregnancyModalContent}>
          <h2 className={styles.pregnancyModalTitle}>
            We’re sorry, but you cannot book your appointment online.
          </h2>
          <p>This service is not recommended for guests in their first trimester of pregnancy.</p>
          <div className={styles.pregnancyModalContacts}>
            <div className={styles.contactsRow}>
              <img src={MapPinIcon} alt="map pin icon" />
              <p className={styles.boutiqueName}>{selectedBoutique?.name}</p>
            </div>
            <div className={styles.contactsRow}>
              <img src={PhoneIcon} alt="phone icon" />
              <p>{formatPhoneNumber(selectedBoutique?.phoneNumber || '')}</p>
            </div>
          </div>
          <button className={`button inverted w-full`} onClick={() => setIsPregnancyModalOpen(false)}>
            Close
          </button>
        </div>
      </Modal>
      <Modal
       isOpen={showLeaveModal}
       onClose={() => setShowLeaveModal(false)}
       hideCloseButton
      >
        <div className={styles.leaveBookingModal}>
          <h2>Are you sure you want to leave this booking?</h2>
          <p>Are you sure you want to leave this booking?</p>
          <div>
            <button className="button">Save and leave</button>
            <button>Leave without saving</button>
          </div>
          <button className="button__underline">Cancel</button>
        </div>
      </Modal>
      <Modal
       isOpen={showRestoreDataModal}
       onClose={() => setShowRestoreDataModal(false)}
       hideCloseButton
      >
        <div className={styles.restoreDataBookingModal}>
          <h2>Would you like to pick up where you left off?</h2>
          <p>We found saved data from your last booking attempt. Would you like to restore it and continue where you left off?</p>
          <button onClick={() => {
            loadDataFromSessionStorage()
            setShowRestoreDataModal(false)
          }} className="button">Continue</button>
          <button onClick={() => {
            clearBookingDataFromSessionStorage()
            setShowRestoreDataModal(false)
          }} className="button__underline">START OVER</button>
        </div>
      </Modal>
      {showLocationSearch && (<LocationSearch currentBoutique={selectedBoutique || null} setBoutique={setSelectedBoutique} onClose={() => setShowLocationSearch(false)} />)}
    </div>
  )
}

export default Booking