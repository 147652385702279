import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from '../styles/pages/CancelMembership.module.scss'
import ArrowDownIcon from '../assets/icons/arrow-down.svg';
import Modal from '../components/core/Modal';
import { useToast } from '../components/core/ToastManager';
import { useCancelUserMembership, useUserMemberships } from '../hooks/useUserProfile';
import { DateTime } from 'luxon';
import Spinner from '../components/core/Spinner';

export const CancelMembership = () => {
  const { boutiqueId, membershipId, userMembershipId } = useParams();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { data: userMemberships, isLoading: isLoadingUserMemberships, error: userMembershipsError } = useUserMemberships(true);
  const flattenedMemberships = userMemberships && userMemberships.membershipGroups.map((group) => group.userMemberships).flat();
  const userMembership = flattenedMemberships?.find((membership) => membership.membership.zenotiId === membershipId)
  const membership = userMembership?.membership;

  useEffect(() => {
    if (!boutiqueId || !membershipId) {
      navigate('/settings/memberships');
    }
  }, [])

  const { mutate: cancelUserMembership, isPending: isCancelMembershipPending, error: cancelMembershipError  } = useCancelUserMembership(
    (data) => {
      if (!data.data.success ) {
        addToast(`Error canceling membership`, 'error');
      }
      addToast('We’re sorry to see you go. Your membership has been cancelled.', 'success', 3500);
      setShowCancelModal(false);
      navigate('/settings/memberships');
    },
    (error) => {
      addToast(`Error canceling membership: ${error.errorMessages[0]}`, 'error', 3500);
    }
  );

  if (isLoadingUserMemberships) return <Spinner />
  if (userMembershipsError) return <div className={styles.error}>Error loading memberships: {userMembershipsError.errorMessages[0]}</div>

  return (
    <div className={styles.cancelMembership}>
      <Link to="/settings/memberships" className={styles.backButton}>
        <img src={ArrowDownIcon} alt="Arrow Down Icon" />
        Back
      </Link>
      <h1>Cancel Membership</h1>
      <div className={styles.cards}>

        <div className={styles.card}>
          <div className={styles.textContent}>
            <h2>Pause your membership for up to 3 months in a calendar year.</h2>
            <p>You can use any previously accrued credits during the freeze, however, you will not accumulate additional credits.</p>
          </div>
          <Link to={`/memberships/pause/${userMembership?.zenotiId}`} className={`button inverted ${styles.button}`}>Pause membership</Link>
        </div>

        <div className={styles.card}>
          <div className={styles.textContent}>
            <h2>Modify My Membership</h2>
            <p>Change membership to a 50 or 80 minute massage once per month</p>
          </div>
          <Link to={`/settings/memberships/update/${boutiqueId}/${membershipId}`} className={`button ${styles.button}`}>Change membership</Link>
        </div>

        <div className={styles.card}>
          <div className={styles.textContent}>
            <h2>Cancel My Membership</h2>
            <p>You will be charged one more billing cycle at the rate of ${membership?.nextCollectionAmount}.  You will have access to your membership until {DateTime.fromISO(membership?.nextCollectionDate || '').toFormat("MMMM dd, yyyy")}.</p>
          </div>
          <button onClick={() => setShowCancelModal(true)} className={`button inverted ${styles.button} ${styles.cancelButton}`}>Cancel membership</button>
        </div>

        <div className={styles.card}>
          <div className={styles.textContent}>
            <h2>Keep Your Commitment to Self-Care</h2>
            <p>Extend the benefits of your Ritual Membership with savings from lifestyle and wellness brands we love.</p>
          </div>
          <Link to='/ritual-collective' className={`button ${styles.button}`}>View the ritual collective</Link>
        </div>

      </div>
      <Modal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        className={styles.cancelModal}
        >
          <h2>Are you sure you want to cancel your membership?</h2>
          <ul>
            <li>Your last billing cycle will be charged on {DateTime.fromISO(membership?.nextCollectionDate || '').toFormat("MM/dd/yyyy")} for ${membership?.nextCollectionAmount}</li>
            <li>You will have access to your membership until the end of your billing period on {DateTime.fromISO(membership?.nextCollectionDate || '').toFormat("MM/dd/yyyy")}</li>
            {/* TODO: confirm what credits to show and where to get months valid data */}
            <li>{membership?.creditBalance || 0} credits will be valid for 3 months after your membership is cancelled</li>
          </ul>
          <button className={`button inverted ${styles.cancelButton}`} onClick={() => cancelUserMembership(userMembershipId!)}>{isCancelMembershipPending ? <Spinner size={20} /> : "Yes, Cancel Membership"}</button>
          <button className={`button__underline ${styles.closeButton}`} onClick={() => setShowCancelModal(false)}>No, stay in the now</button>
          {cancelMembershipError && <p className={styles.error}>Error canceling membership: {cancelMembershipError.errorMessages[0]}</p>}
        </Modal>
    </div>
  )
}

export default CancelMembership;