import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styles from '../styles/components/AddressForm.module.scss';
import { Input } from '../components/core/Input';
import { AutoCompleteInput } from '../components/core/AutoCompleteInput';
import { USStates, countries } from '../constants/dropdownObjects';
import { useToast } from '../components/core/ToastManager';
import Spinner from '../components/core/Spinner';
import { useCreditCardById, useUpdateBillingAddress } from '../hooks/useCreditCards';
import { getCountryCodeByShortName } from '../constants/zenotiCountriesList';

interface AddressFormData {
  addressLine1: string;
  addressLine2?: string;
  state: string;
  city: string;
  zipCode: string;
  countryCode: string;
  processorId: string;
}

interface Props {
  id: string;
  processorId: string;
  onClose: () => void;
}

const schema: yup.ObjectSchema<AddressFormData> = yup.object().shape({
  addressLine1: yup.string().required('Address Line 1 is required'),
  addressLine2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required').matches(/^[A-Z]{2}$/, 'Invalid state code'),
  zipCode: yup.string().required('Zip code is required').matches(/^[0-9]{5}$/, 'Invalid zip code'),
  countryCode: yup.string().required('Country is required'),
  processorId: yup.string().required('Processor ID is required'),
});

const EditBillingAddressModal: React.FC<Props> = ({ id, processorId, onClose }) => {
  const { addToast } = useToast();
  const { data: creditCard, isLoading, error } = useCreditCardById(id)
  const defaultValues = {
    addressLine1: creditCard?.data?.billingAddressInfo.addressLine1 || '',
    addressLine2: creditCard?.data?.billingAddressInfo.addressLine2 || '',
    city: creditCard?.data?.billingAddressInfo.city || '',
    state: creditCard?.data?.billingAddressInfo.state || '',
    zipCode: creditCard?.data?.billingAddressInfo.zipCode || '',
    processorId,
    countryCode: getCountryCodeByShortName(creditCard?.data?.billingAddressInfo.countryCode || '') || '',
  }
  const { mutate: updateBillingAddressMutation, isPending, error: updateAddressError } = useUpdateBillingAddress(
    () => {
      addToast('Billing details updated successfully!', 'success', 3500)
      onClose()
    },
    (error) => {
      console.log(error, 'error')
      addToast(error.errorMessages[0] || 'Failed to update billing details. Please try again.', 'error', 3500)
      onClose()
    }
  )
  const { register, handleSubmit, formState: { errors, isDirty } } = useForm<AddressFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
    values: defaultValues
  });

  const onSubmit: SubmitHandler<AddressFormData> = async (data) => {
    updateBillingAddressMutation({ id, data })
  };

  if (isLoading) return <Spinner />;
  if (error) return <div>Failed to load credit card data</div>;

  return (
    <div className={styles.addressForm}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Input id="zip" label="Zip Code" inputProps={register('zipCode')} error={errors.zipCode?.message} />
        <button 
          className={`button w-full ${!isDirty || isPending ? "disabled" : ""}`} 
          disabled={!isDirty || isPending} 
          type="submit"
        >
          {isPending ? <Spinner size={20}/> : 'Update Billing Zip Code'}
        </button>
        {updateAddressError && <p>{updateAddressError.errorMessages[0]}</p>}
      </form>
    </div>
  );
};

export default EditBillingAddressModal;
