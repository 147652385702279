import { get, put, post, del } from './apiClient';
import { 
  UserProfileResponse,
  UserProfileUpdateRequest,
  UserProfileUpdateResponse,
  UpdatePasswordRequest,
  UserProfile,
  ResetPasswordRequest,
  ResetPasswordResponse,
  TriggerVerificationEmailRequest,
  TriggerVerificationEmailResponse,
  CreateUserMembershipRequest 
} from '../types/user';
import { UserMembershipResponse } from '../types/memberships';
import { PaymentProfileResponse } from '../types/payment';
import { GenericSuccessResponse } from '../types/api';

export const fetchUserProfile = async (): Promise<UserProfileResponse> => {
  const response = await get<UserProfileResponse>('/api/v1/user');
  return response.data;
};

export const updateUserProfile = async (
  user: UserProfileUpdateRequest
): Promise<UserProfileUpdateResponse> => {
  const response = await put<UserProfileUpdateResponse, { user: UserProfileUpdateRequest }>('/api/v1/user', { user });
  return response.data;
};

export const updateUserPassword = async (passwordData: UpdatePasswordRequest): Promise<void> => {
  await put<void, { user: UpdatePasswordRequest }>('/api/v1/user_passwords', { user: passwordData });
};

export const updateUserBoutique = async (boutiqueId: string): Promise<UserProfile> => {
  const response = await put<UserProfile, { boutique_id: string }>(`/api/v1/user/boutiques/${boutiqueId}`, { boutique_id: boutiqueId });
  return response.data;
};

export const triggerVerificationEmail = async (data: TriggerVerificationEmailRequest): Promise<TriggerVerificationEmailResponse> => {
  const response = await post<TriggerVerificationEmailResponse, TriggerVerificationEmailRequest>('/api/v1/password_resets', data);
  return response.data;
};

export const resetUserPassword = async (data: ResetPasswordRequest): Promise<ResetPasswordResponse> => {
  const response = await put<ResetPasswordResponse, ResetPasswordRequest>('/api/v1/users/password', data);
  return response.data;
};

export const fetchUserMemberships = async (isActive?: boolean): Promise<UserMembershipResponse> => {
  const params: Record<string, any> = {};
  if (isActive !== undefined) params.is_active = isActive;
  const response = await get<UserMembershipResponse>('/api/v1/user/purchases', params);
  return response.data;
};

export const createUserMembership = async (data: CreateUserMembershipRequest): Promise<PaymentProfileResponse> => {
  const response = await post<PaymentProfileResponse, CreateUserMembershipRequest>('/api/v1/user/purchases', data);
  return response.data;
};

export const cancelUserMembership = async (id: string): Promise<GenericSuccessResponse> => {
  const response = await del<GenericSuccessResponse, null>(`/api/v1/user/purchases/${id}`);
  return response.data;
};

export const updateUserNotes = async (note: string): Promise<GenericSuccessResponse> => {
  const response = await post<GenericSuccessResponse, {note: string}>('/api/v1/user/notes', { note });
  return response.data;
};

