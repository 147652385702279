import { useQuery } from "@tanstack/react-query";
import { fetchRitualCollectivePerks } from "../api/ritualCollective";
import { ApiErrorResponse, GenericPaginationParams } from "../types/api";
import { RitualCollectivePerksResponse } from "../types/ritualCollective";

export const useRitualCollectivePerks = (options: GenericPaginationParams = {}) => {
  return useQuery<RitualCollectivePerksResponse, ApiErrorResponse>({
    queryKey: ['ritualCollectivePerks', options],
    queryFn: () => fetchRitualCollectivePerks(options)
  });
};