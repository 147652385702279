import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import { cancelAppointment, fetchUserAppointments } from '../api/appointments';
import { AppointmentsData } from '../types/appointments';
import { ApiErrorResponse, GenericSuccessResponse } from '../types/api';

export const useUserAppointments = () => {
  return useQuery<AppointmentsData, ApiErrorResponse>({
    queryKey: ['userAppointments'],
    queryFn: fetchUserAppointments,
  });
};

export const useCancelAppointment = (
  onSuccess?: (data: GenericSuccessResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<GenericSuccessResponse, ApiErrorResponse, {boutiqueId: string, invoiceId: string}> => {
  return useMutation<GenericSuccessResponse, ApiErrorResponse, {boutiqueId: string, invoiceId: string}>({
    mutationFn: ({boutiqueId, invoiceId}) => cancelAppointment(boutiqueId, invoiceId),
    onSuccess,
    onError,
  });
};
