import { post, del } from './apiClient';
import { InvoiceRequest, InvoiceResponse, GiftCardCloseInvoiceRequest } from '../types/purchase';

export const createInvoice = async (
  membershipData: InvoiceRequest
): Promise<InvoiceResponse> => {
  const response = await post<InvoiceResponse, InvoiceRequest>('/api/v1/user/invoices', membershipData);
  return response.data;
};

export const closeInvoice = async (
  invoiceId: string,
  giftCardPurchase?: boolean
): Promise<InvoiceResponse> => {
  const response = await del<InvoiceResponse, { giftCardPurchase?: boolean }>(
    `/api/v1/user/invoices/${invoiceId}`,
    giftCardPurchase ? { giftCardPurchase } : {}
  );
  return response.data;
};

export const closeGiftCardInvoice = async (
  invoiceId: string,
  data: GiftCardCloseInvoiceRequest
): Promise<InvoiceResponse> => {
  const response = await del<InvoiceResponse, GiftCardCloseInvoiceRequest>(
    `/api/v1/user/invoices/${invoiceId}`,
    data
  );
  return response.data;
};