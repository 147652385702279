import styles from '../styles/components/BookingDetails.module.scss'
import AppointmentIcon from '../assets/icons/appointments.svg'
import MapPinIcon from '../assets/icons/map-pin-new.svg'
import CalendarIcon from '../assets/icons/calendar.svg'
import TherapistIcon from '../assets/icons/massage-therapist.svg'
import ClockIcon from '../assets/icons/clock.svg'
import Arrow from '../assets/icons/arrow-down.svg'
import { Link } from 'react-router-dom'

interface OrderDetailsProps {
  title: string;
  date: string;
  time: string;
  location: string;
  locationId?: string;
  therapistName: string;
  addOns?: string[];
}

export const BookingDetails: React.FC<OrderDetailsProps> = ({
  title,
  date,
  time,
  location,
  locationId,
  therapistName,
  addOns
}) => {
  return (
    <div className={`${styles.bookingDetails}`}>
      <div className={styles.navigation}>
        <img src={Arrow} alt="Back Arrow" />
        <Link className="button__link" to={`/book?restoreSession=true&location=${locationId}`}>Back to Massage Options</Link>
      </div>
      <div className={styles.bookingInfo}>
        <div className={styles.item}>
          <img src={CalendarIcon} alt="Calendar" />
          <p>{date}</p>
        </div>
        <div className={styles.item}>
          <img src={ClockIcon} alt="Clock Icon" />
          <p>{time}</p>
        </div>
        <div className={styles.item}>
          <img src={MapPinIcon} alt="Map Pin Icon" />
          <p>{location}</p>
        </div>
        <div className={styles.item}>
          <img src={AppointmentIcon} alt="Massage Icon" />
          <div className={styles.itemInfo}>
            <p>{title}</p>
            {addOns && 
              <div className={styles.addOns}>
                <ul>
                  {addOns.map((addOn, index) => <li key={index}>+ {addOn}</li>)}
                </ul>
              </div>
            }
          </div>
        </div>
        {therapistName && <div className={styles.item}>
          <img src={TherapistIcon} alt="Therapist" />
          <p>Therapist, {therapistName}</p>
        </div>}
      </div>
    </div>
  )
}

export default BookingDetails