import { useEffect, useState } from 'react';
import styles from '../styles/components/BookingFlowAppointment.module.scss';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import PreferencesIcon from '../assets/icons/preferences.svg';
import CustomSelectDropdown from './core/CustomSelectDropdown';
import { useCreateGuests, useCreateServiceBooking, useTherapists } from '../hooks/useBookings';
import { Guest, Selection, UserOptions } from '../pages/Booking';
import { BookingSlot, Therapist } from '../types/bookings';
import Spinner from './core/Spinner';
import { useToast } from './core/ToastManager';
import { DateTime } from 'luxon';
import { formatPhoneNumber, normalizePhoneNumber } from '../utils/formatPhone';
import ArrowDownIcon from '../assets/icons/arrow-down.svg'

interface Props {
  boutiqueId: string;
  boutiquePhone: string;
  userSelections: Selection;
  userOptions: UserOptions;
  guestsSelections?: Selection[];
  guestsInfo?: Guest[];
  hostZenotiId: string;
  setCheckoutLink: (link: string) => void
}

interface GuestsTherapists {
  [guestId: string]: {
    id?: string;
    therapist?: Therapist | null;
    gender?: string
  }
}

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export const genders = [
  { value: 'any', label: 'Any' },
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' }
];

export const BookingFlowAppointment: React.FC<Props> = ({
  boutiqueId,
  boutiquePhone,
  userSelections,
  guestsSelections,
  userOptions,
  guestsInfo,
  hostZenotiId,
  setCheckoutLink
}) => {
  const [value, onChange] = useState<Value>(new Date());
  const { data: therapists, isLoading: isLoadingTherapists } = useTherapists(boutiqueId);
  const [expandedTherapistPreference, setExpandedTherapistPreference] = useState<string[]>([]);

  const initialGuestsTherapists: GuestsTherapists = {
    host: {
      id: hostZenotiId,
      therapist: null,
      gender: userOptions.gender || 'any',
    },
  };

  guestsInfo?.forEach((guest, index) => {
    initialGuestsTherapists[`guest-${index}`] = {
      id: `guest-${index}`,
      therapist: null,
      gender: guest.gender || 'any',
    };
  });

  const [guestsTherapists, setGuestsTherapist] = useState<GuestsTherapists>(initialGuestsTherapists);
  const [slots, setSlots] = useState<BookingSlot[]>([])
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('')
  const userAddOnsIds = userSelections?.enhancements?.map(addOn => {
    return addOn.zenotiId
  })
  const encodedUserAddOnIds = encodeURIComponent(JSON.stringify(userAddOnsIds));

  const { mutate: createGuests } = useCreateGuests(
    (data) => {
      handleCreateServiceBooking(data.guestIds)
    },
    (error) => {
      addToast(`Failed to add guests: ${error.error_messages[0]}`, 'error');
    }
  );

  const { mutate: createServiceBooking, data: serviceBookingData, isPending: isCreateServiceBookingPending } = useCreateServiceBooking(
    (data) => {
      setSlots(data.slots)
    },
    (error) => {
      addToast(`Failed to create service booking: ${error.error_messages[0]}`, 'error');
    }
  );

  const toggleTherapistDetails = (id: string) => {
    setExpandedTherapistPreference((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  const handleCreateServiceBooking = (guestIds: string[]) => {
    const guests = guestsInfo?.map((guest, index) => ({
      zenotiId: guestIds[index],
      serviceId: guestsSelections?.[index]?.massage?.zenotiId || '',
      therapistId: guestsTherapists[`guest-${index}`].therapist?.id || '',
      therapistGender: guestsTherapists[`guest-${index}`].gender ? 1 : guestsTherapists[`guest-${index}`].gender === 'female' ? 0 : -1,
      addOnIds: guestsSelections?.[index]?.enhancements?.map(addOn => addOn.zenotiId) || []
    })) || [];

    // Add the host to the beginning of the array
    guests.unshift({
      zenotiId: hostZenotiId,
      serviceId: userSelections?.massage?.zenotiId || '',
      therapistId: guestsTherapists['host'].therapist?.id || '',
      therapistGender: guestsTherapists['host'].gender === 'male' ? 1 : guestsTherapists['host'].gender === 'female' ? 0 : -1,
      addOnIds: userAddOnsIds || []
    });

    createServiceBooking({
      boutiqueId,
      data: {
        date: value instanceof Date ? value.toISOString() : '',
        guests
      }
    });
  };

  const { addToast } = useToast()
  const today = new Date();
  const threeMonthsFromNow = new Date();
  threeMonthsFromNow.setMonth(today.getMonth() + 3);

  const isTherapistLocked = (therapistId: string, guestId: string): boolean => {
    return Object.entries(guestsTherapists).some(([key, value]) => key !== guestId && value.therapist?.id === therapistId);
  };

  // Handle therapist selection
  const handleTherapistChange = (guestId: string, therapistId: string | null) => {
    const therapist = therapists?.therapists.find(t => t.id === therapistId) || null;
  
    setGuestsTherapist(prevState => ({
      ...prevState,
      [guestId]: {
        ...prevState[guestId],
        therapist,
      },
    }));
  };

  const getFilteredTherapists = (guestId: string) => {
    const selectedGender = guestsTherapists[guestId]?.gender || 'any';
  
    return therapists?.therapists.filter((therapist) => {
      // If gender is 'any', include all therapists
      if (selectedGender === 'any') return true;
  
      // Include only therapists matching the selected gender
      return therapist.gender.toLowerCase() === selectedGender.toLowerCase();
    }).map((therapist) => ({
      value: therapist.id,
      label: therapist.nickName,
      disabled: isTherapistLocked(therapist.id, guestId), // Disable therapist if already selected by another guest
    })) || [];
  };

  useEffect(() => {
    if (guestsInfo && guestsInfo.length > 0) {
      const updatedGuests = guestsInfo.map(guest => {
        return {
          firstName: guest.firstName,
          lastName: guest.lastName,
          email: guest.email,
          phone: normalizePhoneNumber(guest.phone),
          gender: guest.gender === 'male' ? '1' : guest.gender === 'female' ? '0' : '-1',
          minor: guest.minor
        }
      })
      createGuests({
        boutiqueId,
        data: { guests: updatedGuests }
      });
    } else {
      createServiceBooking({
        boutiqueId,
        data: {
          date: value instanceof Date ? value.toISOString() : '',
          guests: [
           {
            zenotiId: hostZenotiId,
            serviceId: userSelections?.massage?.zenotiId || '',
            therapistId: guestsTherapists['host'].therapist?.id || '',
            therapistGender: guestsTherapists['host'].gender === 'male' ? 1 : guestsTherapists['host'].gender === 'female' ? 0 : -1,
            addOnIds: userAddOnsIds || []
          }
          ]
        }
      });
    }
  }, [guestsInfo, boutiqueId, createGuests, guestsTherapists, value]);

  useEffect(() => {
    if (serviceBookingData?.zenotiBookingId && selectedTimeSlot && userSelections.massage?.zenotiId) {
      // Create an array of guest data if guests exist
      const guestsData = guestsSelections?.map((guestSelection, index) => ({
        addOnIds: guestSelection?.enhancements?.map(addOn => addOn.zenotiId) || [],
        name: `${guestsInfo?.[index]?.firstName}`,
        massageId: guestSelection?.massage?.zenotiId || '',
        therapistId: guestsTherapists[`guest-${index}`].therapist?.id,
        therapistGender: guestsTherapists[`guest-${index}`].gender
      })) || [];
  
      // Encode guests data only if there are guests
      const guestsQueryParam = guestsData.length > 0 
        ? `&guests=${encodeURIComponent(JSON.stringify(guestsData))}` 
        : '';
  
      // Set the checkout link with conditionally added guests data
      setCheckoutLink(
        `/booking-checkout/${boutiqueId}/${serviceBookingData?.zenotiBookingId}/${userSelections.massage?.zenotiId}` +
        `?time=${selectedTimeSlot}&addOns=${encodedUserAddOnIds}&therapistId=${guestsTherapists.host?.therapist?.id}${guestsQueryParam}`
      );
    }
  }, [selectedTimeSlot, guestsTherapists]);

  return (
    <div className={styles.appointment}>
      <div className={styles.leftCol}>
        <Calendar
          onChange={(value) => {
            onChange(value)
          }}
          value={value}
          className={styles.calendar}
          tileClassName={styles.tile}
          formatShortWeekday={(locale, date) =>
            date.toLocaleDateString(locale, { weekday: 'narrow' })
          }
          calendarType="gregory"
          minDate={today}
          maxDate={threeMonthsFromNow}
          next2Label={null}
          prev2Label={null}
        />
        <div className={styles.preferencesHeader}>
          <img src={PreferencesIcon} alt="preferences" />
          <p>Therapist Preferences</p>
        </div>
        {guestsInfo && guestsInfo?.length > 0 && <div 
          onClick={() => toggleTherapistDetails('host')}
          className={`${styles.expandingGuestBlock} ${expandedTherapistPreference.includes('host') ? styles.expanded : ''}`}
          >
          <p className={styles.guestName}>For You</p>
          <div className={styles.expandingContentRight}>
            <p>
            {
              guestsTherapists['host'].therapist
                ? guestsTherapists['host'].therapist?.nickName
                : guestsTherapists['host'].gender && guestsTherapists['host'].gender !== 'any'
                ? `Any ${guestsTherapists['host'].gender.charAt(0).toUpperCase() + guestsTherapists['host'].gender.slice(1)} Therapist`
                : 'Any Therapist'
            }
            </p>
            <img src={ArrowDownIcon} alt="Toggle therapist details" />
          </div>
        </div>}
        {(expandedTherapistPreference.includes('host') || guestsInfo?.length  === 0) && (
          <div>
            <CustomSelectDropdown
              label="Gender"
              onChange={(gender) =>
                setGuestsTherapist(prevState => ({
                  ...prevState,
                  host: {
                    ...prevState['host'],
                    therapist: null,
                    gender,
                  },
                }))
              }
              options={genders}
              selectedOption={guestsTherapists['host']?.gender || 'any'}
              disabled={userOptions.minor}
              disabledMessage='Minor guests can only select same gender therapist'
            />
            <CustomSelectDropdown
              label="Therapist"
              onChange={(therapistId) => handleTherapistChange('host', therapistId)}
              options={getFilteredTherapists('host') || []}
              selectedOption={guestsTherapists['host']?.therapist?.id || ''}
              className={styles.therapistsSelect}
              optionSeparator
              disabledMessage='This therapist is already selected by another guest'
            />
            {isLoadingTherapists && <Spinner />}
          </div>
          )
        }
        {guestsInfo && guestsInfo.length > 0 && guestsInfo.map((guest, index) => (
          <div key={`guest-${index}`}>
            <div
              onClick={() => toggleTherapistDetails(`guest-${index}`)}
              className={`${styles.expandingGuestBlock} ${expandedTherapistPreference.includes(`guest-${index}`) ? styles.expanded : ''}`}
            >
              <p className={styles.guestName}>For {guest.firstName}</p>
              <div className={styles.expandingContentRight}>
                <p>
                {
                  guestsTherapists[`guest-${index}`].therapist
                    ? guestsTherapists[`guest-${index}`].therapist?.nickName
                    : guestsTherapists[`guest-${index}`].gender && guestsTherapists[`guest-${index}`].gender !== 'any'
                    ? `${(guestsTherapists[`guest-${index}`]?.gender ?? 'any').charAt(0).toUpperCase() + (guestsTherapists[`guest-${index}`]?.gender ?? 'any').slice(1)} Therapist`
                    : 'Any Therapist'
                }
                </p>
                <img src={ArrowDownIcon} alt="Toggle therapist details" />
              </div>
            </div>
            {expandedTherapistPreference.includes(`guest-${index}`) && (
              <>
                <CustomSelectDropdown
                  label="Gender"
                  onChange={(gender) =>
                    setGuestsTherapist(prevState => ({
                      ...prevState,
                      [`guest-${index}`]: {
                        ...prevState[`guest-${index}`],
                        therapist: null,
                        gender,
                      },
                    }))
                  }
                  options={genders}
                  selectedOption={guestsTherapists[`guest-${index}`]?.gender || 'any'}
                  disabled={guest.minor}
                  disabledMessage='Minor guests can only select same gender therapist'
                />
                <CustomSelectDropdown
                  label="Therapist"
                  onChange={(therapistId) => handleTherapistChange(`guest-${index}`, therapistId)}
                  options={getFilteredTherapists(`guest-${index}`) || []}
                  selectedOption={guestsTherapists[`guest-${index}`]?.therapist?.id || ''}
                  className={styles.therapistsSelect}
                  optionSeparator
                  disabledMessage='This therapist is already selected by another guest'
                />
              </>
            )}
          </div>
        ))}

      </div>
      <div className={styles.rightCol}>
        <p>{Object.entries(guestsTherapists).map(([guestId, details]) => (
          <span key={guestId}>
          {details.therapist?.nickName 
            ? details.therapist.nickName 
            : details.gender && details.gender !== 'any'
            ? `Any ${details.gender.charAt(0).toUpperCase() + details.gender.slice(1)} Therapist` 
            : 'Any Therapist'}
          {', '}
          </span>
        ))}</p>
        <div className={styles.timeSlots}>
          {isCreateServiceBookingPending && <Spinner />}
          {!isCreateServiceBookingPending && slots.map((slot, index) => {
            const formattedTime = DateTime.fromISO(slot.time).toFormat("h:mm a");
            return (
              <div className={`${styles.slot} ${selectedTimeSlot === slot.time ? styles.selected : ''}`} key={slot.time + index} onClick={() => setSelectedTimeSlot(slot.time)}>
                {formattedTime}
              </div>
            )
          })}
        </div>
        <div className={styles.callUs}>
          Not seeing the availability you want? Please call us at {formatPhoneNumber(boutiquePhone)} and one of our experience guides will work with you to find a time.
        </div>
      </div>
    </div>
  );
};
