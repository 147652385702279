// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddPaymentMethodModal_iframeModal__5vWPE {
  padding: 0;
}
.AddPaymentMethodModal_iframeModal__5vWPE.AddPaymentMethodModal_error__6i6aS {
  padding: 3rem 2rem;
}
.AddPaymentMethodModal_iframeModal__5vWPE iframe {
  width: 100%;
  min-height: 515px;
  border: none;
}
.AddPaymentMethodModal_iframeModal__5vWPE .AddPaymentMethodModal_limitError__V9CT5 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/AddPaymentMethodModal.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,WAAA;EACA,iBAAA;EACA,YAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADJ","sourcesContent":[".iframeModal {\n  padding: 0;\n\n  &.error {\n    padding: 3rem 2rem;\n  }\n\n  iframe {\n    width: 100%;\n    min-height: 515px;\n    border: none;\n  }\n\n  .limitError {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n  } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframeModal": `AddPaymentMethodModal_iframeModal__5vWPE`,
	"error": `AddPaymentMethodModal_error__6i6aS`,
	"limitError": `AddPaymentMethodModal_limitError__V9CT5`
};
export default ___CSS_LOADER_EXPORT___;
