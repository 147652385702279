import DOMPurify from "dompurify";
import { useMembershipTerms } from "../hooks/useMemberships";
import ErrorScreen from "./core/ErrorMessages";
import Spinner from "./core/Spinner";
import styles from '../styles/components/MembershipTermsModal.module.scss';

interface Props {
  membershipId: string;
  boutiqueId: string;
}

export const MembershipTermsModal: React.FC<Props> = ({ membershipId, boutiqueId }) => {
  const { data: terms, isLoading: isTermsLoading, error: termsError } = useMembershipTerms(membershipId, boutiqueId);

  if (isTermsLoading) return <Spinner />;
  if (termsError) return <ErrorScreen errors={[termsError]} />;

  // Sanitize the raw HTML
  const sanitizedHTML = DOMPurify.sanitize(terms?.data.body || "");

  return (
    <div
      className={styles.membershipTerms}
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
    />
  );
};

export default MembershipTermsModal;
