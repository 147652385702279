import { del, get } from './apiClient';
import { AppointmentsData } from '../types/appointments';
import { GenericSuccessResponse } from '../types/api';

export const fetchUserAppointments = async (): Promise<AppointmentsData> => {
  const response = await get<AppointmentsData>('api/v1/user/appointments');
  return response.data;
};

export const cancelAppointment = async (boutiqueId: string, invoiceId: string): Promise<GenericSuccessResponse> => {
  const response = await del<GenericSuccessResponse, null>(`api/v1/boutiques/${boutiqueId}/appointments/${invoiceId}`);
  return response.data;
};