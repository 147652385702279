import styles from '../styles/components/PauseMembershipModal.module.scss';
import AttentionIcon from '../assets/icons/attention-icon.svg';
import { UserMembership } from '../types/memberships';
import { useFreezeMembership, useUnfreezeMembership } from '../hooks/useMemberships';
import { useToast } from './core/ToastManager';
import { useState } from 'react';
import Spinner from './core/Spinner';
import { useQueryClient } from '@tanstack/react-query';

interface PauseMembershipModalProps {
  userMembership: UserMembership
  onClose: () => void
}

export const PauseMembershipModal:React.FC<PauseMembershipModalProps> = ({ userMembership, onClose }) => {
  const { addToast } = useToast()
  const queryClient = useQueryClient();
  const [showConfirmation, setShowConfirmation] = useState(false)
  const { mutate: freezeMembership, isPending: isFreezingPending, error: freezingError } = useFreezeMembership(
    () => {
      addToast('Membership successfully paused!', 'success')
      queryClient.invalidateQueries({ queryKey: ['userMemberships'] });
      onClose()
    },
    (error) => {
      addToast(error.error_messages[0], 'error', 4000)
      onClose()
    }
  )
  const { mutate: unfreezeMembership, isPending: isUnfreezingPending, error: unfreezingError } = useUnfreezeMembership(
    () => {
      addToast('Membership successfully resumed!!', 'success')
      queryClient.invalidateQueries({ queryKey: ['userMemberships'] });
      onClose()
    },
    (error) => {
      addToast(error.error_messages[0], 'error', 4000)
      onClose()
    }
  )
  if (userMembership.freezeDate) {
    return (
      <div className={styles.pauseMembership}>
        <h2>Are you sure you want to resume your membership?</h2>
        <p>Freeze your membership multiple times as long as the total does not exceed three months per calendar year. </p>
        <div className={styles.actions}>
          <button onClick={() => unfreezeMembership(userMembership.zenotiId)} className={`button ${isUnfreezingPending ? 'disabled' : ''}`}>
            {isUnfreezingPending ? <Spinner size={20} /> : "Resume my membership"}
          </button>
          <button onClick={onClose} className="button__underline">CANCEL</button>
        </div>
        {unfreezingError && <p>Error resuming your membership: {unfreezingError.error_messages[0]}</p>}
      </div>
    )
  } else if (showConfirmation) {
    return (
      <div className={styles.pauseMembership}>
        <h2>Are you ready to pause your membership?</h2>
        <p>Reinstate your membership online at anytime.</p>
        <div className={styles.actions}>
          <button onClick={() => freezeMembership(userMembership.zenotiId)} className={`button ${isFreezingPending ? 'disabled' : ''}`}>
            {isFreezingPending ? <Spinner size={20} /> : "Pause my membership"}
          </button>
          <button onClick={onClose} className="button__underline">CANCEL</button>
        </div>
        {freezingError && <p>Error pausing your membership: {freezingError.error_messages[0]}</p>}
      </div>
    )
} else {
  return (
    <div className={styles.pauseMembership}>
      <h2>Pause your membership for up to 3 months in a calendar year.</h2>
      <div className={styles.status}>
        <div className={styles.statusHeading}>
          <img src={AttentionIcon} alt="exclamation point" />
          <p>Your membership is active</p>
        </div>
        {/* TODO: uncomment once we have data for this */}
        {/* <p>Your membership has not been frozen in the current calendar year.</p> */}
      </div>
      {/* TODO: uncomment once we have data for this */}
      {/* <div className={styles.remainingMonths}>3 Months Remaining</div> */}
      <p className={styles.termsHeading}>What to expect when pausing a membership:</p>
      <ul className={styles.termsList}>
        <li>You will not receive any charges while your membership is paused.</li>
        <li>Resume at any time.</li>
        <li>Freeze your membership multiple times as long as the total does not exceed three months per calendar year.</li>
        <li>Use any previously accrued credits during the freeze, however, you will not accumulate additional credits.</li>
      </ul>
      <div className={styles.actions}>
        <button onClick={() => setShowConfirmation(true)} className="button">Pause my membership</button>
        <button onClick={onClose} className="button__underline">CANCEL</button>
      </div>
    </div>
)
  }
}
