import React, { ReactNode } from 'react';
import styles from '../../styles/components/InputFeedback.module.scss';
import SuccessInputIcon from '../../assets/icons/blue-checkmark.svg';
import ErrorInputIcon from '../../assets/icons/error-input.svg';

interface InputFeedbackProps {
  message: string | ReactNode;
  variant: 'success' | 'error';
  extraClasses?: string;
  apiError?: boolean;
}

const InputFeedback: React.FC<InputFeedbackProps> = ({ message, variant, extraClasses, apiError }) => {
  const icon = variant === 'success' ? SuccessInputIcon : ErrorInputIcon;

  return (
    <div className={`${styles.inputFeedback} ${variant === 'success' ? styles.success : styles.error} ${extraClasses ? extraClasses : ''} ${apiError ? styles.apiError : ''}`}>
      <img src={icon} alt={variant} />
      <p className={styles.feedbackText}>{message}</p>
    </div>
  );
};

export default InputFeedback;
