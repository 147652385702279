import { useEffect } from 'react'
import Spinner from '../components/core/Spinner'
import { useSearchParams } from 'react-router-dom'

export const PurchaseSuccessRedirect = () => {
  const [searchParams] = useSearchParams()
  const success = searchParams.get('success') === '1'
  const message = searchParams.get('message')

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'processComplete',
        message: success ? 'Your purchase was successful' : message,
        success
      },
      '*'
    );
  })
  return (
    <div>
      <h1 style={{textAlign: "center"}}>Redirecting...</h1>
      <Spinner />
    </div>
  )
}

export default PurchaseSuccessRedirect;