// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorScreen_errors__jTolW {
  display: flex;
  flex-direction: column;
}
.ErrorScreen_errors__jTolW .ErrorScreen_error__Pg6sm span {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/ErrorScreen.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;AAAF;AAGI;EACE,gBAAA;AADN","sourcesContent":["\n.errors {\n  display: flex;\n  flex-direction: column;\n\n  .error {\n    span {\n      font-weight: 600;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errors": `ErrorScreen_errors__jTolW`,
	"error": `ErrorScreen_error__Pg6sm`
};
export default ___CSS_LOADER_EXPORT___;
