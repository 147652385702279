import { useEffect, useState } from 'react';
import styles from '../styles/components/BookingFlowPressure.module.scss';
import MediumPressureIcon from '../assets/icons/medium-pressure.svg';
import DeepPressureIcon from '../assets/icons/deep-pressure.svg';
import { useToast } from './core/ToastManager';

interface Props {
  onSelectPressure: (pressure: string) => void;
  prevSelectedPressure?: string;
  isPregnant?: boolean;
}

export const BookingFlowPressure: React.FC<Props> = ({ onSelectPressure, prevSelectedPressure, isPregnant }) => {
  const [selectedPressure, setSelectedPressure] = useState<string | null>(prevSelectedPressure || null);
  const { addToast } = useToast()

  useEffect(() => {
    if (isPregnant) {
      setSelectedPressure('medium');
      onSelectPressure('medium');
    }
  }, [isPregnant])

  const handlePressureClick = (pressure: string) => {
    if (isPregnant && pressure === 'deep') {
      addToast('Deep pressure is not recommended for pregnant guests.', 'error', 4000);
      return;
    }
    setSelectedPressure(pressure);
    onSelectPressure(pressure);
  };

  return (
    <div className={styles.pressure}>
      <div
        className={`${styles.pressureOption} ${selectedPressure === 'medium' ? styles.selected : ''}`}
        onClick={() => handlePressureClick('medium')}
      >
        <img src={MediumPressureIcon} alt="light to medium pressure" />
        <p className={styles.pressureTitle}>Light to Medium</p>
      </div>
      <div
        className={`${styles.pressureOption} ${selectedPressure === 'deep' ? styles.selected : ''} ${isPregnant ? styles.disabled : ''}`}
        onClick={() => handlePressureClick('deep')}
      >
        <img src={DeepPressureIcon} alt="Deep pressure" />
        <p className={styles.pressureTitle}>Firm to Deep</p>
      </div>
    </div>
  );
};

export default BookingFlowPressure;
