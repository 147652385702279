import { Service } from "../types/bookings"
import styles from '../styles/components/BookingFlowDuration.module.scss'
import { useState } from "react"
import Spinner from "./core/Spinner"
import { UserMembershipResponse } from "../types/memberships"
import { useToast } from "./core/ToastManager"
import { useNavigate } from "react-router-dom"

interface Props {
  services: Service[]
  durations: number[]
  groupAppointment?: boolean
  onSelectDuration: (duration: number, createMembership?: boolean) => void
  nextStep: () => void
  userMemberships: UserMembershipResponse | undefined
  isLoadingMemberships: boolean
  prevSelectedDuration?: number
  isPregnant?: boolean
  isMinor?: boolean
  isGuest?: boolean
  saveDataToSessionStorage?: () => void
  promotion?: string | null
}

export const BookingFlowDuration: React.FC<Props> = ({ 
  services,
  groupAppointment,
  onSelectDuration,
  nextStep,
  userMemberships,
  isLoadingMemberships,
  prevSelectedDuration,
  isPregnant,
  isMinor,
  isGuest,
  saveDataToSessionStorage,
  promotion
}) => {
  const getUniqueRealDurations = (services: Service[]) => {
    const durations = services
      .filter(service => service.duration > 0)
      .map(service => service.duration - (service.recoveryTime || 0));
    return Array.from(new Set(durations)).sort((a, b) => a - b);
  };

  const { addToast } = useToast()
  const navigate = useNavigate()

  const durations = getUniqueRealDurations(services);
  const [activeTab, setActiveTab] = useState(durations[1]);

  const nonMemberActiveService = services.find((service) => service.duration - service.recoveryTime === activeTab);
  const disabled25 = nonMemberActiveService && (nonMemberActiveService?.duration - nonMemberActiveService?.recoveryTime) === 25 && (isPregnant || groupAppointment) ? true : false

  const getActiveMembershipDuration = () => {
    if (userMemberships && userMemberships.membershipGroups) {
      for (const group of userMemberships.membershipGroups) {
        const activeMembership = group.userMemberships.find(
          (userMembership) => userMembership.status === 'active'
        );

        if (activeMembership) {
          return activeMembership.membership.durationInMinutes;
        }
      }
    }
    return null;
  };

  const [selectedDuration, setSelectedDuration] = useState(prevSelectedDuration)

  const hasMembership = !!getActiveMembershipDuration();

  if (isLoadingMemberships) return <Spinner />;

  const handleDisabled25Check = (disabled25: boolean): boolean => {
    if (disabled25 && groupAppointment) {
      addToast('The 25 minute isn’t an option if you are booking a group appointment.', 'info');
      return true;
    }
    if (disabled25 && isPregnant) {
      addToast('The 25 minute isn’t an option if you are pregnant.', 'info');
      return true;
    }
    return false;
  };
  
  const handleContinueClick = () => {
    if (handleDisabled25Check(disabled25)) {
      return;
    }
    onSelectDuration(activeTab);
    nextStep();
  };
  
  const handleSignUpClick = () => {
    if (handleDisabled25Check(disabled25)) {
      return;
    }
    if (saveDataToSessionStorage) {
      saveDataToSessionStorage()
    }
    sessionStorage.setItem('restoreBooking', JSON.stringify({restoreBooking: true}));
    navigate('/memberships')
  };

  const handleMemberSelection = (duration: number, isDisabled?: boolean) => {
    if (isDisabled && handleDisabled25Check(isDisabled)) {
      return;
    }
    setSelectedDuration(duration)
    onSelectDuration(duration)
  }

  const promotionBanner = () => {
    return (
      <div className={styles.promoBanner}>
        <p>{promotion}</p>
      </div>
    )
  }

  if (!hasMembership && !isGuest) return (
    <div>
      <div className={styles.tabs}>
        {durations.map((duration) => {
          if (duration === 0) return null;
          return (
            <div
              className={`${styles.tab} ${activeTab === duration ? styles.active : ''}`}
              key={duration}
              onClick={() => setActiveTab(duration)}
            >
              {duration} Min
            </div>
          );
        })}
      </div>
      <div className={`${styles.nonMemberOptions} ${disabled25 ? styles.disabled : ''}`}>
        {!isMinor && (
        <div 
          className={`${styles.priceOption} ${selectedDuration === activeTab ? styles.selected : ''}`}
        >
          <p>Members Pay</p>
          <h2>${nonMemberActiveService?.salePrice}</h2>
          <button className={`button inverted ${disabled25 ? styles.disabledBtn : '' }`} onClick={handleSignUpClick}>Sign Up</button>
        </div>
        )}
        <div className={styles.priceOption}>
          <p>Regular Pricing</p>
          <h2>${nonMemberActiveService?.salePrice}</h2>
          <button className={`button inverted ${disabled25 ? styles.disabledBtn : '' }`} onClick={handleContinueClick}>Continue</button>
        </div>
      </div>
      {promotion && promotionBanner()}
    </div>
  );

  return (
    <div className={styles.membersDurations}>
      {durations.map((duration) => {
        const currentService = services.find((service) => service.duration - service.recoveryTime === duration);
        if (duration === 0) return null;
        const isDisabled = duration === 25 && (isPregnant || groupAppointment)
        return (
          <div
            className={`${styles.memberOption} ${selectedDuration === duration ? styles.selected : ""} ${isDisabled ? styles.disabled : ''}`}
            key={duration}
            onClick={() => handleMemberSelection(duration, isDisabled)}
          >
            <p>{duration} Minutes</p>
            <h2>${currentService?.salePrice}</h2>
            {getActiveMembershipDuration() === duration && <div className={styles.yourMembership}>Your membership</div>}
          </div>
        );
      })}
       {promotion && promotionBanner()}
    </div>
  );
};
