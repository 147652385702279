import styles from '../styles/components/BookingStepper.module.scss';
import { generateStepGroups } from '../constants/bookingSteps';
import { Guest } from '../types/bookings';
import MapPinIcon from '../assets/icons/map-pin.svg';
import { Boutique } from '../types/boutiques';
import { useState } from 'react';
import LocationSearch from './LocationSearch';
import { Selection } from '../pages/Booking';

interface Props {
  currentStepId: string;
  guestsInfo: Guest[];
  onStepSelect: (stepId: string) => void;
  boutique?: Boutique;
  setBoutique: (boutique: Boutique) => void;
  completedSteps: string[];
  userSelections: Selection;
  guestsSelections: Selection[];
}

export const BookingStepper: React.FC<Props> = ({ 
  currentStepId,
  guestsInfo,
  onStepSelect,
  boutique,
  setBoutique,
  completedSteps,
  userSelections,
  guestsSelections
}) => {
  const stepGroups = generateStepGroups(guestsInfo);
  const [expandedGroupIndex, setExpandedGroupIndex] = useState<number | null>(null);
  const [showLocationSearch, setShowLocationSearch] = useState(false);

  const hasGuests = guestsInfo.length > 0;

  // Find the current group and step index
  const currentGroupIndex = stepGroups.findIndex(group =>
    group.steps.some(step => step.id === currentStepId)
  );

  // Check if a step is clickable (highlighted if completed or current step)
  const isStepClickable = (stepId: string) => completedSteps.includes(stepId) || stepId === currentStepId;

  const handleGroupClick = (groupIndex: number) => {
    if (hasGuests) {
      setExpandedGroupIndex(groupIndex);
    }
  };

  const handleStepClick = (stepId: string) => {
    if (isStepClickable(stepId)) {
      onStepSelect(stepId);
    }
  };

  // Helper function to get the display value for each step
  const getStepValue = (stepId: string): string | null => {
    if (stepId.startsWith('duration')) {
      const selection = stepId === 'duration-host' ? userSelections : guestsSelections[parseInt(stepId.split('-').pop()!) - 1];
      return selection?.duration ? `${selection.duration} Min` : null;
    }
    if (stepId.startsWith('pressure')) {
      const selection = stepId === 'pressure-host' ? userSelections : guestsSelections[parseInt(stepId.split('-').pop()!) - 1];
      if (selection?.pressure === 'medium') return 'Light to Medium'
      if (selection?.pressure === 'deep') return 'Firm to Deep'
      
    }
    if (stepId.startsWith('massage')) {
      const selection = stepId === 'massage-host' ? userSelections : guestsSelections[parseInt(stepId.split('-').pop()!) - 1];
      return selection?.massage?.name || null;
    }
    if (stepId.startsWith('enhancements')) {
      const selection = stepId === 'enhancements-host' ? userSelections : guestsSelections[parseInt(stepId.split('-').pop()!) - 1];
      return selection?.enhancements ? `${selection.enhancements.length} selected` : null;
    }
    if (stepId === 'appointment') {
      return null;
    }
    return null;
  };

  return (
    <div className={styles.stepper}>
      {boutique && (
        <div onClick={() => setShowLocationSearch(true)} className={`${styles.boutique}`}>
          <div className={styles.boutiqueTitle}>
            <img src={MapPinIcon} alt="map pin icon" />
            {boutique.name}
          </div>
          <p className={styles.address}>
            {boutique.address}, {boutique.city}, {boutique.state} {boutique.zip}
          </p>
        </div>
      )}

      <div
        className={`${styles.step} ${currentStepId === 'guests' ? styles.active : ''} ${isStepClickable('guests') ? styles.highlighted : ''} ${styles.whoIsComing}`}
        onClick={() => handleStepClick('guests')}
      >
        <p>Who’s Coming</p>
        {guestsInfo.length > 0 ? <p>Me, {guestsInfo.length} {guestsInfo.length === 1 ? "Guest" : "Guests"}</p> : <p>Just Me</p>}
      </div>

      {!hasGuests ? (
        stepGroups.map((group) => (
          <div
            key={group.groupLabel}
            className={`${styles.group} ${currentStepId === group.steps[0].id ? styles.active : ''}`}
            onClick={() => handleStepClick(group.steps[0].id)}
          >
            <div className={`${styles.step} ${isStepClickable(group.steps[0].id) ? styles.highlighted : ''}`}>
              {group.groupLabel}
              {/* Display the step value if available */}
              {getStepValue(group.steps[0].id) && <span className={styles.stepValue}>{getStepValue(group.steps[0].id)}</span>}
            </div>
          </div>
        ))
      ) : (
        stepGroups.map((group, groupIndex) => {
          const isGroupCompleted = group.steps.some(step => completedSteps.includes(step.id));
          const isCurrentGroup = group.steps.some(step => step.id === currentStepId);
        
          return (
            <div key={groupIndex} className={`${styles.group} ${isCurrentGroup ? styles.active : ''}`}>
              <div
                className={`${styles.groupTitle} ${isGroupCompleted ? styles.highlighted : ''}`}
                onClick={() => handleGroupClick(groupIndex)}
              >
                {group.groupLabel}
              </div>
        
              {(expandedGroupIndex === groupIndex || isCurrentGroup) && (
                <div className={styles.groupSteps}>
                  {group.steps.map((step, stepIndex) => (
                    <div
                      key={stepIndex}
                      className={`${styles.step} ${step.id === currentStepId ? styles.active : ''} ${isStepClickable(step.id) ? styles.highlighted : ''}`}
                      onClick={() => handleStepClick(step.id)}
                    >
                      {step.label}
                      {/* Display the step value if available */}
                      {getStepValue(step.id) && <span className={styles.stepValue}>{getStepValue(step.id)}</span>}
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })
      )}
      <div
        className={`${styles.step} ${currentStepId === 'appointment' ? styles.active : ''}`}
        onClick={() => handleStepClick('appointment')}
      >
        Select Appointment
      </div>
      {showLocationSearch && <LocationSearch currentBoutique={boutique || null} setBoutique={setBoutique} onClose={() => setShowLocationSearch(false)} />}
    </div>
  );
};

export default BookingStepper;
