import { post, del } from './apiClient';
import { PaymentProfileRequest, PaymentProfileResponse  } from '../types/payment';
import { GenericSuccessResponse } from '../types/api';


export const createPaymentProfile = async (
  paymentData: PaymentProfileRequest
): Promise<PaymentProfileResponse> => {
  const response = await post<PaymentProfileResponse, PaymentProfileRequest>('/api/v1/user/payment_profiles', paymentData);
  return response.data;
};

export const deletePaymentProfile = async (accountId: string): Promise<GenericSuccessResponse> => {
  const response = await del<GenericSuccessResponse, { account_id: string }>('/api/v1/user/payment_profiles', { account_id: accountId });
  return response.data;
};