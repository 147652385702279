import { useEffect, useState } from 'react'
import styles from '../styles/pages/BillingDetails.module.scss'
import Modal from '../components/core/Modal'
import { PaymentMethodModal } from '../components/PaymentMethodModal'
import { useCreatePaymentProfile } from '../hooks/usePaymentProfile'
import { PaymentProfileRequest } from '../types/payment'
import Spinner from '../components/core/Spinner'
import { useCreditCards } from '../hooks/useCreditCards'
import { useToast } from '../components/core/ToastManager'
import { useQueryClient } from '@tanstack/react-query'
import AddPaymentMethodModal from '../components/AddPaymentMethodModal'

export const BillingDetails = () => {
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false)
  const [openAddPaymentMethodModal, setOpenAddPaymentMethodModal] = useState(false)
  const { data: creditCards, error: creditCardError, isLoading } = useCreditCards();
  const { mutate: createPaymentProfile, data, error, isPending } = useCreatePaymentProfile();
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const addPaymentMethod = () => {
    const paymentProfileRequest: PaymentProfileRequest = {
      platform: 'web',
      redirect_uri: `${process.env.REACT_APP_WEBSITE_URL}/add-card-success`,
    };
    createPaymentProfile(paymentProfileRequest, {
      onSuccess: (response) => {
        setOpenAddPaymentMethodModal(true);
      },
      onError: (error) => {
        addToast(error.error_messages[0] || 'Error fetching Payment Profile', 'error');
      }
    });
  };

  const managePaymentMethod = () => {
    setOpenPaymentMethodModal(true);
  }

  const isFilled = creditCards?.data && creditCards?.data?.length > 0;
  const title = isFilled ? 'Payment Methods' : 'Add a Payment Method'
  const description = isFilled ? creditCards.data[0].identifier : 'Your credit card will be used by default for billing.'
  const buttonText = isFilled ? 'Manage' : 'Add a credit card'
  const buttonAction = isFilled ? managePaymentMethod : addPaymentMethod

  // Listen for messages from the iframe. this is needed to close the iframe modal after payment method is added
  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent) => {
      if (event.data?.type === 'processComplete') {
        setOpenAddPaymentMethodModal(false);
        // invalidate query to fetch updated credit cards list
        queryClient.invalidateQueries({ queryKey: ['creditCards'] });

        addToast(event.data.message, 'success', 3500);
      }
    };

    // Add event listener for message
    window.addEventListener('message', handleIframeMessage);

    return () => {
      // Clean up the event listener
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  if (isLoading) return <Spinner />;
  if (creditCardError) return <p>Failed to load credit cards: {creditCardError.error_messages[0]}</p>;

  return (
    <div>
      <div className={styles.PaymentMethodCard}>
        <h2>{title}</h2>
        <p className={`${styles.description} ${isFilled ? styles.filled : ''}`}>{description}</p>
        {isFilled && <p className={styles.expDate}>Exp date {creditCards.data[0].expiryOn}</p>}
        <button 
          className={`button ${styles.button} ${isFilled ? styles.filled : ''}`}
          onClick={buttonAction}
        >
            {buttonText}
        </button>
      </div>
      <Modal isOpen={openPaymentMethodModal} onClose={() => setOpenPaymentMethodModal(false)}>
        <PaymentMethodModal onClose={() => setOpenPaymentMethodModal(false)} creditCards={creditCards?.data || []} addPaymentMethod={addPaymentMethod} />
      </Modal>
      {openAddPaymentMethodModal && (
        <AddPaymentMethodModal isOpen={openAddPaymentMethodModal} onClose={() => setOpenAddPaymentMethodModal(false)}/>
      )}
    </div>
  )
}

export default BillingDetails