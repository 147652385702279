import { InputHTMLAttributes, useState } from 'react';
import styles from '../../styles/components/Input.module.scss';
import HidePasswordIcon from '../../assets/icons/hide-password.svg';
import ShowPasswordIcon from '../../assets/icons/show-password.svg';
import InputFeedback from './InputFeedback';

interface InputProps {
  id: string;
  label: string;
  className?: string;
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  error?: string;
  success?: string;
  apiError?: boolean;
}

export const Input: React.FC<InputProps> = ({ id, label, className, inputProps, error, success, apiError }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(prevState => !prevState);
  };

  return (
    <div
      className={`
      ${styles.input} 
      ${className ? className : ''} 
      ${error ? styles.entryError : ''} 
      ${success ? styles.entrySuccess : ''}`}
      >
      <input
        id={id}
        {...inputProps}
        type={inputProps.type === 'password' ? (isPasswordVisible ? 'text' : 'password') : inputProps.type}
        placeholder=" "
      />
      <label className={styles.placeholder} htmlFor={id}>{label}</label>
      {inputProps.type === 'password' && (
        <button
          type="button"
          className={styles.hidePassword}
          onClick={togglePasswordVisibility}
        >
          <img src={isPasswordVisible ? HidePasswordIcon : ShowPasswordIcon} alt="Toggle password visibility" />
        </button>
      )}
      {/* the span below can be used for nice animation on input focus, for now it is not used */}
      {/* <span className={styles.underline}></span> */}
      {error && <InputFeedback message={error} variant='error' apiError={apiError} />}
      {!error && success && <InputFeedback message={success} variant='success' />}
    </div>
  );
};
