import { NavLink, NavLinkProps } from 'react-router-dom';
import styles from '../styles/components/SubMenu.module.scss';
import { useAuth } from './auth/AuthProvider';

interface NavItemProps extends NavLinkProps {
  label: string;
  isHidden?: boolean;
  childLinks?: NavItemProps[];
}

export default function SubMenu() {

  const { logout } = useAuth();

  const links: NavItemProps[] = [
    {
      label: 'Dashboard',
      to: '/dashboard',
    },
    {
      label: 'My Appointments',
      to: '/appointments',
    },
    {
      label: 'Account Settings',
      to: '/settings',
    }
  ];

  return (
    <div className={styles.submenu}>
      <div className={styles.submenuContainer}>
        <nav>
          <ul>
            {links.map((link, index) => {
              return (
                <li key={index}>
                  <NavLink
                    to={link.to}
                    className={({ isActive }) =>
                      [
                        styles.submenuLink,
                        isActive ? styles.active : '',
                        link.isHidden ? styles.hidden : ''
                      ].join(' ')
                    }
                  >
                    {link.label}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
        <button onClick={logout} className={styles.submenuLogout}>Log out</button>
      </div>
    </div>
  );
}
