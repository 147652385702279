import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom';
import styles from '../styles/pages/Checkout.module.scss';
import OrderDetails from '../components/OrderDetails';
import { Checkbox } from '../components/core/Checkbox';
import AddPaymentMethodModal from '../components/AddPaymentMethodModal';
import Modal from '../components/core/Modal';
import Spinner from '../components/core/Spinner';
import { PaymentMethodModal } from '../components/PaymentMethodModal';
import { useToast } from '../components/core/ToastManager';
import { useCancelMembership, useMembershipById } from '../hooks/useMemberships';
import { useCreditCards } from '../hooks/useCreditCards';
import { useCloseInvoice, useCreateInvoice } from '../hooks/useInvoice';
import { useCreateUserMembership } from '../hooks/useUserProfile';
import { numberToNumeralsText } from '../utils/caseConverter';
import MembershipTermsModal from '../components/MembershipTermsModal';

export const Checkout = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { boutiqueId, serviceType, membershipId } = useParams();
  const [searchParams] = useSearchParams();
  const currentMembershipId = searchParams.get('currentMembershipId');

  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [openIframeModal, setOpenIframeModal] = useState(false);
  const [isCardVerificationRequired, setIsCardVerificationRequired] = useState(false);
  const [showMembershipTermsModal, setShowMembershipTermsModal] = useState(false);

  const [promoCode, setPromoCode] = useState('');
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [promoError, setPromoError] = useState('');

  const [checkbox1, setCheckbox1] = useState(false); 
  const [checkbox2, setCheckbox2] = useState(false); 

  const { data: creditCards, error: creditCardError, isLoading: isCreditCardLoading } = useCreditCards();
  const { data: membership, isLoading: isMembershipLoading, error: membershipError } = useMembershipById(membershipId!, boutiqueId!);

  const { mutate: createInvoice, data: invoiceData, isPending: isCreateInvoicePending, error: createInvoiceError } = useCreateInvoice(
    (data) => {
      if (data.data.status !== 'Open') {
        addToast(`Purchase failed due to technical issues. Please try again later.`, 'error');
      } else {
        if (creditCards?.data[0].accountId) {
          createUserMembership({
            redirectUri: `${process.env.REACT_APP_WEBSITE_URL}/purchase-success`,
            invoiceId: data.data.invoiceId,
            accountId: creditCards?.data[0].accountId,
          });
        } else {
          addToast(`Purchase failed: No credit card on file. Please add a credit card to proceed.`, 'warning');
        }
      }
    },
    (error) => {
      addToast(`Purchase failed: ${error.error_messages[0]}`, 'error');
    }
  );

  const { mutate: createUserMembership, data: createMembershipData, isPending: isCreateMembershipPending, error: createMembershipError } = useCreateUserMembership(
    (data) => {
      if (!data.data.success) {
        setIsCardVerificationRequired(true);
        setOpenIframeModal(true);
      } else if (data.data.success && invoiceData?.data.invoiceId) {
        closeInvoice({invoiceId: invoiceData?.data.invoiceId});
      }
    },
    (error) => {
      addToast(`Error creating membership: ${error.error_messages[0]}`, 'error');
    }
  );

  const { mutate: closeInvoice, data: closeInvoiceData, isPending: isCloseInvoicePending, error: closeInvoiceError } = useCloseInvoice(
    (data) => {
      if (data.data.status !== 'Closed') {
        addToast(`Error purchasing membership`, 'error');
      } else {
        if (currentMembershipId) {
          cancelMembership(currentMembershipId);
        } else {
          addToast('Purchase successful!', 'success', 3500);
          navigate(`/purchase-confirmation/${membership?.membershipGroup.memberships.boutique.id}/${membership?.membershipGroup.memberships.zenotiId}`);
        }
      }
    },
    (error) => {
      addToast(`Error closing invoice: ${error.error_messages[0]}`, 'error');
    }
  );

  const { mutate: cancelMembership } = useCancelMembership(
    (data) => {
      if (!data.data.success) {
        addToast(`Error canceling previous membership`, 'error');
      } else {
        navigate(`/purchase-confirmation/${membership?.membershipGroup.memberships.boutique.id}/${membership?.membershipGroup.memberships.zenotiId}`);
        addToast('Membership successfully updated!', 'success', 3500);
      }
    },
    (error) => {
      addToast(`Error canceling previous membership: ${error.error_messages[0]}`, 'error', 3500);
    }
  );

  useEffect(() => {
    if (!boutiqueId || !serviceType || !membershipId) {
      navigate('/settings/memberships');
    }
  }, []);

  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent) => {
      if (event.data?.type === 'processComplete' && invoiceData?.data.invoiceId) {
        if (event.data.success) {
          setOpenIframeModal(false);
          closeInvoice({invoiceId: invoiceData?.data.invoiceId});
        } else {
          addToast(event.data.message, 'error');
        }
      }
    };
    window.addEventListener('message', handleIframeMessage);
    return () => window.removeEventListener('message', handleIframeMessage);
  }, [invoiceData?.data.invoiceId]);

  const handlePromoApply = () => {
    if (promoCode.trim() === '') {
      setPromoError('Promo code cannot be empty');
      return;
    }
    setPromoError('');
    console.log({ promoCode });
  };

  const handlePayment = () => {
    createInvoice({ membershipId: membershipId! });
  };

  const isPurchasePending = isCreateInvoicePending || isCreateMembershipPending || isCloseInvoicePending;
  const isPayNowDisabled = !checkbox1 || !checkbox2 || isPurchasePending || creditCards?.data.length === 0;

  if (isMembershipLoading) return <Spinner />;
  if (membershipError) return <div>Error loading membership. Please try again later.</div>;

  const { name, durationInMinutes, frequencyPerMonth, boutique, price } = membership?.membershipGroup.memberships || {};

  return (
    <div className={styles.checkout}>
      <div className={styles.orderDetails}>
        <h2>Membership Details</h2>
        <OrderDetails 
          title={name || ''}
          duration={`${durationInMinutes} minutes`}
          frequency={`${numberToNumeralsText(frequencyPerMonth || 0)} per month`}
          location={boutique?.name || ''}
          isHighlighted
        />
        <p className={styles.note}>Please note, membership sales to minors will not be permitted without a parent or legal guardian present at time of purchase.</p>
        <div className={`${styles.separator} ${styles.orderSeparator}`}></div>
      </div>

      <div className={styles.paymentDetails}>
        <div className={styles.contentBlock}>
          <h2>Payment Method</h2>
          {isCreditCardLoading ? (
            <Spinner />
          ) : creditCards?.data && creditCards?.data.length > 0 ? (
            <div className={styles.inputWithAction}>
              <p><strong>{creditCards?.data[0].cardLogo}</strong> ending in {creditCards?.data[0].lastFour}</p>
              <button className={`button__underline`} onClick={() => setShowPaymentMethodModal(true)}>MANAGE</button>
            </div>
          ) : (
            <button className="button" onClick={() => setShowAddPaymentMethodModal(true)}>Add Payment Method</button>
          )}

          <div className={styles.inputWithAction}>
            <input
              placeholder="Add promo code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
            <button className="button__underline" onClick={handlePromoApply}>APPLY</button>
          </div>
          {promoError && <p className={styles.promoError}>{promoError}</p>}
          {isPromoApplied && <p className={styles.promoSuccess}>Promo code applied!</p>}
        </div>

        <div className={styles.separator}></div>

        <div className={styles.contentBlock}>
          <h2>Price Details</h2>
          <div className={styles.priceDetails}>
            <div className={styles.packageInfo}>
              <p>{name}</p>
              <p className={styles.frequency}>{durationInMinutes} Minutes, {`${numberToNumeralsText(frequencyPerMonth || 0)} per month`}</p>
            </div>
            <div className={styles.price}>${price} /mo</div>
          </div>
        </div>

        <div className={styles.separator}></div>

        <div className={styles.contentBlock}>
          <div className={styles.subTotal}>
            <p>Sub Total</p>
            <p>${price} /mo</p>
          </div>
          <div className={styles.total}>
            <p><strong>Total</strong></p>
            <p><strong>${price} /mo</strong></p>
          </div>
          <p className={styles.note}>Please note, membership sales to minors will not be permitted without a parent or legal guardian present at time of purchase.</p>
        </div>

        <div className={styles.separator}></div>

        <div className={styles.contentBlock}>
          <Checkbox
            id="terms"
            label={<span>I agree to the <Link to="https://thenowmassage.com/terms-of-service/" target="_blank">Terms of Use Agreement</Link> & <Link to="https://thenowmassage.com/privacy-policy" target="_blank">Privacy Policy</Link></span>}
            checked={checkbox1}
            onChange={(e) => setCheckbox1(e)}
            className={styles.checkbox}
          />
          <Checkbox
            id="membership-agreement"
            label={<button className={styles.termsLink} onClick={() => setShowMembershipTermsModal(true)}>I accept the Membership Agreement</button>}
            checked={checkbox2}
            onChange={(e) => setCheckbox2(e)}
            className={styles.checkbox}
          />
        </div>

        <button onClick={handlePayment} className={`button ${isPayNowDisabled ? 'disabled' : ''}`} disabled={isPayNowDisabled}>
          {isPurchasePending ? <Spinner size={20} /> : "Pay Now"}
        </button>
        {createInvoiceError && <p className={styles.error}>Error purchasing membership: {createInvoiceError.error_messages[0]}.</p>}
        {createMembershipError && <p className={styles.error}>Error purchasing membership: {createMembershipError.error_messages[0]}</p>}
      </div>

      <AddPaymentMethodModal onClose={() => setShowAddPaymentMethodModal(false)} isOpen={showAddPaymentMethodModal} />
      
      <Modal isOpen={showPaymentMethodModal} onClose={() => setShowPaymentMethodModal(false)}>
        <PaymentMethodModal
          onClose={() => setShowPaymentMethodModal(false)}
          creditCards={creditCards?.data || []}
          addPaymentMethod={() => setShowAddPaymentMethodModal(true)}
        />
      </Modal>

      <Modal className={styles.iframeModal} isOpen={openIframeModal} onClose={() => setOpenIframeModal(false)}>
        {isCreateMembershipPending ? (
          <Spinner />
        ) : !createMembershipError && isCardVerificationRequired && (
          <iframe
            src={createMembershipData?.data.hostedPaymentUri}
            title="Purchase Membership"
            className={styles.iframe}
          />
        )}
      </Modal>
      <Modal isOpen={showMembershipTermsModal} onClose={() => setShowMembershipTermsModal(false)}>
        <MembershipTermsModal membershipId={membership?.membershipGroup.memberships.zenotiId || ''} boutiqueId={membership?.membershipGroup.memberships.boutique.id || ''} />
      </Modal >
    </div>
  );
};

export default Checkout;
