import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../styles/pages/ForgotPassword.module.scss';
import { Input } from '../components/core/Input';
import Modal from '../components/core/Modal';
import { useToast } from '../components/core/ToastManager';
import { useTriggerVerificationEmail } from '../hooks/useUserProfile';
import Spinner from '../components/core/Spinner';

interface FormData {
  email: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Email is required'),
});

const ForgotPassword: React.FC = () => {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState<{ userId: string, verificationId: string } | null>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const mutation = useTriggerVerificationEmail(
    (data) => {
      setIsModalOpen(true);
      setUserData(data.data);
    },
    (error) => {
      addToast(error.error_messages[0] || 'Failed to send reset password instructions. Please try again.', 'error');
    }
  );

  const onSubmit: SubmitHandler<FormData> = (data) => {
    mutation.mutate(data);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    navigate(`/reset-password?user_id=${userData?.userId}&verification_id=${userData?.verificationId}`);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h1>Forgot Your Password?</h1>
        <Input 
          id="email" 
          label="Email Address" 
          inputProps={{ ...register('email') }} 
          error={errors.email?.message} 
        />
        {mutation.isPending ? <Spinner /> : (
          <button className={`button large ${styles.button} ${!isValid ? 'disabled' : ''}`} disabled={!isValid} type="submit">
            SEND PASSWORD RESET INSTRUCTIONS
          </button>
        )}

        <div className={styles.linksContainer}>
          <p>Have an account? <Link to="/login">Login</Link></p>
          <p>New to The NOW? <Link to="/register">Create an Account</Link></p>
        </div>
      </form>

      <Modal isOpen={isModalOpen} onClose={handleClose}>
        <>
          <p>If an account exists for the address provided, you will receive an email with instructions to reset your password. </p>
          <button className="button large" onClick={handleClose}>Ok, got it</button>
        </>
      </Modal>
    </>
  );
};

export default ForgotPassword;
