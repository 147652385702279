import React from 'react';
import styles from '../../styles/components/Checkbox.module.scss';
import InputFeedback from './InputFeedback';

interface CustomCheckboxProps {
  id: string;
  label: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  error?: string;
  className?: string;
}

export const Checkbox: React.FC<CustomCheckboxProps> = ({
  id,
  label,
  checked = false,
  onChange,
  error,
  className
}) => {
  return (
    <>
      <div className={`${styles.checkboxContainer} ${className ? className : ''}`}>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          className={styles.checkbox}
        />
        <label htmlFor={id} className={styles.checkboxLabel}>
          {label}
        </label>
      </div>
      {error && <InputFeedback message={error} variant='error' />}
    </>
  );
};
