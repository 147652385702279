import { useState } from 'react'
import styles from '../styles/pages/Appointments.module.scss'
import MembershipCredits from '../components/core/MembershipCredits'
import { useCancelAppointment, useUserAppointments } from '../hooks/useAppointments'
import AppointmentIcon from '../assets/icons/appointments.svg'
import TherapistIcon from '../assets/icons/massage-therapist.svg'
import MapPinIcon from '../assets/icons/map-pin-new.svg'
import GuestsIcon from '../assets/icons/guests.svg'
import ClockIcon from '../assets/icons/clock.svg'
import { DateTime } from 'luxon';
import Spinner from '../components/core/Spinner'
import { Link } from 'react-router-dom'
import Modal from '../components/core/Modal'
import PhoneIcon from '../assets/icons/phone.svg'
import EnvelopeIcon from '../assets/icons/envelope.svg'
import CalendarIcon from '../assets/icons/calendar.svg'
import { useBoutiques } from '../hooks/useBoutiques'
import { useToast } from '../components/core/ToastManager'
import { useQueryClient } from '@tanstack/react-query'
import ErrorScreen from '../components/core/ErrorMessages'
import { useUserMemberships } from '../hooks/useUserProfile'
import { formatPhoneNumber } from '../utils/formatPhone'

export const Appointments:React.FC = () => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState('upcoming')
  const [showManageAppointmentModal, setShowManageAppointmentModal] = useState(false)
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false)
  const { data: appointments, isLoading: isAppointmentsLoading, error: appointmentsError } = useUserAppointments()
  const { data: boutiques, isLoading: isBoutiquesLoading, error: boutiquesError } = useBoutiques({page: 1, perPage: 100})
  const { data: userMemberships, isLoading: isMembershipsLoading, error: membershipsError } = useUserMemberships(true)
  const { mutate: cancelAppointment, isPending: isCancellationPending } = useCancelAppointment(
    (data) => {
      setShowConfirmCancelModal(false)
      addToast('Your appointment was successfully canceled.', 'success')
      queryClient.invalidateQueries({ queryKey: ['userAppointments'] });
    },
    (error) => {
      console.error('Failed to cancel appointment:', error);
      addToast(`Failed to cancel appointment: ${error.errorMessages[0]}`, 'error');
    })

  const getActiveMemberships = () => {
    const activeMemberships = [];
  
    if (userMemberships && userMemberships.membershipGroups) {
      for (const group of userMemberships.membershipGroups) {
        const groupActiveMemberships = group.userMemberships.filter(
          (userMembership) => userMembership.status === 'active'
        );
        activeMemberships.push(...groupActiveMemberships);
      }
    }
  
    return activeMemberships.length > 0 ? activeMemberships : null;
  };

  const getTotalActiveCredits = () => {
    const activeMemberships = getActiveMemberships();
  
    if (!activeMemberships) return 0;
  
    return activeMemberships.reduce((totalCredits, membership) => {
      return totalCredits + (membership.membership.creditBalance || 0);
    }, 0);
  };

  const creditsSum = getTotalActiveCredits();
  
  if (isAppointmentsLoading || isBoutiquesLoading) return <Spinner />
  if (appointmentsError || boutiquesError) return <ErrorScreen errors={[appointmentsError ?? undefined, boutiquesError ?? undefined]} />

  return (
    <div className={styles.appointments}>
      <h1 className={styles.header}>My Appointments</h1>
      <div className={styles.navigation}>
        <div className={styles.tabs}>
          <button onClick={() => setActiveTab('upcoming')} className={`${styles.tabButton} ${activeTab === 'upcoming' ? styles.active : ''}`}>Upcoming</button>
          <button onClick={() => setActiveTab('past')} className={`${styles.tabButton} ${activeTab === 'past' ? styles.active : ''}`}>Past</button>
        </div>
      {creditsSum ? <MembershipCredits name="Credits" amount={creditsSum} /> : null}
      </div>
      {activeTab === 'upcoming' && (
        <div className={styles.appointmentsList}>
          {appointments?.futureAppointments
          .filter(a => a.serviceDuration !== 0)
          .map((appointment, index) => {
            const currentBoutique = boutiques?.data.boutiques.find(boutique => boutique.id === appointment.boutiqueId)
            const handleProceedCancel = () => {
              setShowManageAppointmentModal(false)
              setShowConfirmCancelModal(true)
            }
            const handleCancel = () => {
              if (appointment.invoiceId) {
                cancelAppointment({boutiqueId: currentBoutique?.id || '', invoiceId: appointment.invoiceId})
              } else {
                addToast('Failed to cancel appointment: Invoice ID not found', 'error');
              }
            }
            return (
              <div className={styles.appointment} key={appointment.invoiceId + index}>
                <h2>{DateTime.fromISO(appointment.date).toFormat('MMMM dd')}</h2>
                <div className={styles.infoItem}>
                  <img src={ClockIcon} alt="clock" />
                  <p>{appointment.time}</p>
                </div>
                <div className={styles.infoItem}>
                  <img src={MapPinIcon} alt="Map pin" />
                  <p>{appointment.boutiqueName}</p>
                </div>
                <div className={`${styles.infoItem} ${styles.serviceDetails}`}>
                  <img src={AppointmentIcon} alt="Service name" />
                  <div>
                    <p>{appointment.serviceName}</p>
                    {appointment.addOnPackages?.length > 0 && (
                            <p>
                              {appointment.addOnPackages.map((addOn, index) => (
                                <span key={index}>
                                  + {addOn.name}
                                  {index < appointment.addOnPackages.length - 1 && ', '}
                                </span>
                              ))}
                            </p>
                          )}
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <img src={TherapistIcon} alt="Therapist" />
                  <p>Therapist, {appointment.therapistName}</p>
                </div>
                <button onClick={() => setShowManageAppointmentModal(true)} className="button inverted">Manage Appointment</button>
                <Link target="_blank" to={`https://www.google.com/maps/search/?api=1&query=${currentBoutique?.lat},${currentBoutique?.lng}`} className="button__underline">DIRECTIONS</Link>

                <Modal onClose={() => setShowManageAppointmentModal(false)} isOpen={showManageAppointmentModal}>
                  <div className={styles.manageAppointmentModal}>
                    <h2>Manage this Appointment</h2>
                    <p>We respectfully request at least 12 hours notice if you need to cancel or rebook your massage appointment. We charge 50% of the reservation made to the credit card on file to compensate our staff for missed wages when there is a last minute cancellation.</p>
                    <button onClick={handleProceedCancel} className={`button__underline`}>CANCEL APPOINTMENT</button>
                    <div className={styles.separator}></div>
                    <p>Contact The NOW Massage {currentBoutique?.name} directly:</p>
                    {currentBoutique?.phoneNumber && 
                    <div className={styles.contactItem}>
                      <img src={PhoneIcon} alt="Phone" />
                      <Link to={`tel:${currentBoutique.phoneNumber}`}>{formatPhoneNumber(currentBoutique.phoneNumber)}</Link>
                    </div>}
                    {currentBoutique?.franchiseEmail && 
                    <div className={styles.contactItem}>
                      <img src={EnvelopeIcon} alt="Envelope" />
                      <Link to={`mailto: ${currentBoutique.franchiseEmail}`}>{currentBoutique.franchiseEmail}</Link>
                    </div>}
                  </div>
                </Modal>
                <Modal onClose={() => setShowConfirmCancelModal(false)} isOpen={showConfirmCancelModal}>
                  <div className={styles.cancelAppointmentModal}>
                  <h2>Are you sure you want to cancel this appointment?</h2>
                    <p>We respectfully request at least 12 hours notice if you need to cancel or rebook your massage appointment. We charge 50% of the reservation made to the credit card on file to compensate our staff for missed wages when there is a last minute cancellation.</p>
                    <button onClick={handleCancel} className={`button`}>
                      {isCancellationPending ? <Spinner size={20} /> : "Yes, CANCEL"}
                    </button>
                    <button onClick={() => setShowConfirmCancelModal(false)} className={`button__underline`}>NO, KEEP</button>
                    <div className={styles.separator}></div>
                    <p>Contact The NOW Massage {currentBoutique?.name} directly:</p>
                    {currentBoutique?.phoneNumber && 
                    <div className={styles.contactItem}>
                      <img src={PhoneIcon} alt="Phone" />
                      <Link to={`tel:${currentBoutique.phoneNumber}`}>{formatPhoneNumber(currentBoutique.phoneNumber)}</Link>
                    </div>}
                    {currentBoutique?.franchiseEmail && 
                    <div className={styles.contactItem}>
                      <img src={EnvelopeIcon} alt="Envelope" />
                      <Link to={`mailto: ${currentBoutique.franchiseEmail}`}>{currentBoutique.franchiseEmail}</Link>
                    </div>}
                  </div>
                </Modal>
              </div>
            )
          })}
          {appointments?.futureAppointments.length === 0 && (
            <div className={`${styles.appointment} ${styles.noAppointment}`}>
              <div className={styles.cardTop}>
                <h2>Nothing Booked</h2>
                <p>Book an appointment.</p>
              </div>
              <Link to="/book" className="button">Book Now</Link>
            </div>
          )}
          {/* {appointments?.futureAppointments.length === 0 && (
            <div className={`${styles.nextAppointment} ${styles.nextAppointment}`}>
              <div className={styles.cardTop}>
                <div>
                  <img src={CalendarIcon} alt="Calendar" />
                  <p></p>
                </div>
                <p>Book an appointment.</p>
              </div>
              <Link to="/book" className="button">Book Now</Link>
            </div>
          )} */}
        </div>
      )}
      {activeTab === 'past' && (
        <div className={styles.pastAppointmentsList}>
          {appointments?.pastAppointments.map(appointment => {
            return (
              <div className={styles.pastAppointment} key={appointment.invoiceId}>
                <h2>{DateTime.fromISO(appointment.date).toFormat('MMMM dd')}, {appointment.time}</h2>
                <div className={styles.pastAppointmentCard}>
                  <div className={`${styles.col} ${styles.firstCol}`}>
                    <div className={styles.infoItem}>
                      <img src={MapPinIcon} alt="Map pin" />
                      <p>{appointment.boutiqueName}</p>
                    </div>
                    <div className={styles.infoItem}>
                      <img src={TherapistIcon} alt="Therapist" />
                      <p>Therapist, {appointment.therapistName ? appointment.therapistName: 'Any'}</p>
                    </div>
                    {appointment.noOfGuests > 1 && <div className={styles.infoItem}>
                      <img src={GuestsIcon} alt="Guests" />
                      <p>Me, {appointment.noOfGuests - 1} {appointment.noOfGuests - 1 === 1 ? 'Guest' : 'Guests'}</p>
                    </div>}
                  </div>
                  <div className={`${styles.col} ${styles.secondCol}`}>
                    <div className={styles.infoItem}>
                      <img src={AppointmentIcon} alt="Service name" />
                      <p>{appointment.serviceName}</p>
                    </div>
                    {appointment.addOnPackages.length > 0 && (
                      <ul className={styles.addOns}>
                      {appointment.addOnPackages.map((addOn, index) => (
                        <li key={index}>{addOn.name}</li>
                      ))}
                      </ul>
                    )}
                  </div>
                  <div className={`${styles.col} ${styles.thirdCol}`}>
                    <Link to="/book" className={`button ${appointment.noOfGuests > 1 ? `disabled ${styles.bookAgainDisabled}` : ''}`}>Book again</Link>
                  </div>
                </div>
              </div>
            )
          })}
          {appointments?.pastAppointments.length === 0 && (
            <div className={`${styles.appointment} ${styles.noAppointment}`}>
              <div className={styles.cardTop}>
                <h2>Past Appointments</h2>
                <p>You don’t have any past appointments.</p>
              </div>
              <Link to="/book" className="button">Book Now</Link>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Appointments