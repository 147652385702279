import { useQuery } from '@tanstack/react-query';
import { fetchBoutiques, fetchBoutiqueById, fetchBoutiquePromotions } from '../api/boutiques';
import { BoutiquesResponse, Boutique, BoutiquesRequestParams, PromotionResponse } from '../types/boutiques';
import { ApiErrorResponse } from '../types/api';


export const useBoutiques = (options: BoutiquesRequestParams = {}) => {
  return useQuery<BoutiquesResponse, ApiErrorResponse>({
    queryKey: ['boutiques', options],
    queryFn: () => fetchBoutiques(options)
  });
};

export const useBoutique = (id: string) => {
  return useQuery<{ data: Boutique }, ApiErrorResponse>({
    queryKey: ['boutique', id],
    queryFn: () => fetchBoutiqueById(id)
  });
};

export const useBoutiquePromotions = (boutiqueId: string) => {
  return useQuery<PromotionResponse, ApiErrorResponse>({
    queryKey: ['boutiquePromotions', boutiqueId],
    queryFn: () => fetchBoutiquePromotions(boutiqueId)
  });
}