import { useLocation } from "react-router-dom"
import { FooterLegal } from "../components/FooterLegal"
import MainMenu from "../components/MainMenu"

const GuestLayout: React.FC<{
  children: React.ReactNode
}> = ({children}) => {
  const location = useLocation()
  const isBookingFlow = location.pathname.includes("/book")
  
  return (
    <div className="guest-layout">
      <MainMenu />
      <div className="container">
        {children}
      </div>
      {!isBookingFlow && <FooterLegal />}
    </div>
  )
}

export default GuestLayout
