import { useQuery, useMutation, UseMutationResult } from '@tanstack/react-query';
import { fetchUserProfile, updateUserPassword, updateUserProfile, updateUserBoutique, triggerVerificationEmail, resetUserPassword, fetchUserMemberships, createUserMembership, cancelUserMembership, updateUserNotes  } from '../api/user';
import { UserProfileResponse, UserProfileUpdateRequest, UserProfileUpdateResponse, UpdatePasswordData, UserProfile, TriggerVerificationEmailRequest, TriggerVerificationEmailResponse, ResetPasswordRequest, ResetPasswordResponse, CreateUserMembershipRequest } from '../types/user';
import { ApiErrorResponse, GenericSuccessResponse } from '../types/api';
import { UserMembershipResponse } from '../types/memberships';
import { PaymentProfileResponse } from '../types/payment';

export const useUserProfile = () => {
  return useQuery<UserProfileResponse, ApiErrorResponse>({
    queryKey: ['userProfile'],
    queryFn: fetchUserProfile,
    retry: false,
  });
};

export const useUpdateUserProfile = (
  onSuccess?: (data: UserProfileUpdateResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<UserProfileUpdateResponse, ApiErrorResponse, UserProfileUpdateRequest> => {
  return useMutation<UserProfileUpdateResponse, ApiErrorResponse, UserProfileUpdateRequest>({
    mutationFn: (user: UserProfileUpdateRequest) => updateUserProfile(user),
    onSuccess,
    onError,
  });
};

export const useUpdateUserPassword = (): UseMutationResult<void, ApiErrorResponse, UpdatePasswordData> => {
  return useMutation<void, ApiErrorResponse, UpdatePasswordData>({
    mutationFn: (data: UpdatePasswordData) => updateUserPassword({
      old_password: data.oldPassword,
      new_password: data.newPassword,
    })
  });
};

export const useUpdateUserBoutique = (): UseMutationResult<UserProfile, ApiErrorResponse, string> => {
  return useMutation<UserProfile, ApiErrorResponse, string>({
    mutationFn: (boutiqueId: string) => updateUserBoutique(boutiqueId)
  });
};

export const useTriggerVerificationEmail = (
  onSuccess?: (data: TriggerVerificationEmailResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<TriggerVerificationEmailResponse, ApiErrorResponse, TriggerVerificationEmailRequest> => {
  return useMutation<TriggerVerificationEmailResponse, ApiErrorResponse, TriggerVerificationEmailRequest>({
    mutationFn: (data: TriggerVerificationEmailRequest) => triggerVerificationEmail(data),
    onSuccess,
    onError,
  });
};

export const useResetUserPassword = (onSuccess?: () => void, onError?: (error: ApiErrorResponse) => void) => {
  return useMutation<ResetPasswordResponse, ApiErrorResponse, ResetPasswordRequest>({
    mutationFn: (data: ResetPasswordRequest) => resetUserPassword(data),
    onSuccess,
    onError,
  });
};

export const useUserMemberships = (isActive: boolean) => {
  return useQuery<UserMembershipResponse, ApiErrorResponse>({
    queryKey: ['userMemberships'],
    queryFn: () => fetchUserMemberships(isActive),
  });
};

export const useCreateUserMembership = (
  onSuccess?: (data: PaymentProfileResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<PaymentProfileResponse, ApiErrorResponse, CreateUserMembershipRequest> => {
  return useMutation<PaymentProfileResponse, ApiErrorResponse, CreateUserMembershipRequest>({
    mutationFn: (data: CreateUserMembershipRequest) => createUserMembership(data),
    onSuccess,
    onError,
  });
};

export const useCancelUserMembership = (
  onSuccess?: (data: GenericSuccessResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<GenericSuccessResponse, ApiErrorResponse, string> => {
  return useMutation<GenericSuccessResponse, ApiErrorResponse, string>({
    mutationFn: (membershipId: string) => cancelUserMembership(membershipId),
    onSuccess,
    onError,
  });
};

export const useUpdateUserNotes = (
  onSuccess?: (data: GenericSuccessResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<GenericSuccessResponse, ApiErrorResponse, string> => {
  return useMutation<GenericSuccessResponse, ApiErrorResponse, string>({
    mutationFn: (note: string) => updateUserNotes(note),
    onSuccess,
    onError,
  });
};
