import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../styles/pages/BookingCheckout.module.scss';
import { Checkbox } from '../components/core/Checkbox';
import AddPaymentMethodModal from '../components/AddPaymentMethodModal';
import Modal from '../components/core/Modal';
import Spinner from '../components/core/Spinner';
import { PaymentMethodModal } from '../components/PaymentMethodModal';
import { useToast } from '../components/core/ToastManager';
import { useCreditCards } from '../hooks/useCreditCards';
import BookingDetails from '../components/BookingDetails';
import { useBoutique } from '../hooks/useBoutiques';
import { useConfirmServiceBooking, useServices, useTherapists } from '../hooks/useBookings';
import { DateTime } from 'luxon';
import PlusIcon from '../assets/icons/plus.svg'
import CrossIcon from '../assets/icons/cross.svg'
import FlowerIcon from '../assets/icons/flower.svg'
import ArrowDownIcon from '../assets/icons/arrow-down.svg'
import PreferenceIcon from '../assets/icons/preferences.svg'
import ErrorScreen from '../components/core/ErrorMessages';
import { Input } from '../components/core/Input';

interface GuestData {
  name: string;
  massageId: string;
  addOnIds: string[];
  therapistId?: string;
  therapistGender?: string;
}

export const BookingCheckout = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { bookingId, boutiqueId, serviceId } = useParams();
  const [searchParams] = useSearchParams()
  const time = searchParams.get('time')
  const therapistId = searchParams.get('therapistId')
  const minorHostGender = searchParams.get('gender') !== 'undefined' ? searchParams.get('gender') : undefined
  const encodedGuests = searchParams.get('guests');
  const encodedAddOns = searchParams.get('addOns')
  const formattedDate = time ? DateTime.fromISO(time).toFormat("MMM dd") : null
  const formattedTime = time ? DateTime.fromISO(time).toFormat("hh:mm a") : null
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(boutiqueId || '')
  const { data: services, isLoading: isLoadingServices, error: servicesError } = useServices(boutiqueId || '')
  const { data: therapists, isLoading: isLoadingTherapists, error: therapistsError } = useTherapists(boutiqueId || '');
  const selectedService = services?.services.find(service => service.zenotiId === serviceId)
  const selectedTherapist = therapists?.therapists.find(therapist => therapist.id === therapistId)
  const [notes, setNotes] = useState('')
  const [groupName, setGroupName] = useState('')
  const [expandNotes, setExpandNotes] = useState(false)
  const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
  const [expandedGuestPriceIds, setExpandedGuestPriceIds] = useState<string[]>([]);
  const {mutate: confirmBooking, isPending: isConfirmBookingPending, error: confirmBookingError} = useConfirmServiceBooking(
    (data) => {
      if (data.success) {
        console.log('booking invoice id:', data.data.invoiceId, 'booking invoice item id:', data.data.invoiceItemId)
        clearBookingDataFromSessionStorage()
        addToast('Your booking confirmed!', 'success')
        navigate(`/booking-confirmation/${boutiqueId}/${serviceId}?time=${time}&therapistId=${therapistId}&addOns=${encodedAddOns}`)
      }
      if (data.error) {
        console.log(data.error)
      }
    },
    (error) => {
      addToast(`Failed to confirm booking: ${error.errorMessages[0]}`, 'error');
    }
  );

  let addOnIds: string[] = [];
    if (encodedAddOns && encodedAddOns !== 'undefined') {
        try {
          addOnIds = JSON.parse(decodeURIComponent(encodedAddOns));
        } catch (error) {
            console.error("Failed to parse IDs:", error);
        }
    }

  let guestsData = []

  if (encodedGuests) {
    try {
      guestsData = JSON.parse(decodeURIComponent(encodedGuests));
    } catch (error) {
      console.error("Failed to parse guests data:", error);
    }
  }

  const selectedAddOns = services?.services.filter(service => addOnIds.includes(service.zenotiId))

  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);

  const [promoCode, setPromoCode] = useState('');
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [promoError, setPromoError] = useState('');

  const [checkbox1, setCheckbox1] = useState(false); 

  const { data: creditCards, error: creditCardError, isLoading: isCreditCardLoading } = useCreditCards();
  // const { data: membership, isLoading: isMembershipLoading, error: membershipError } = useMembershipById(membershipId!, boutiqueId!);
  const clearBookingDataFromSessionStorage = () => {
    sessionStorage.removeItem('bookingData');
  };

  const toggleGuestDetails = (id: string) => {
    setExpandedGuestPriceIds((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  useEffect(() => {
    if (!boutiqueId || !time || !bookingId) {
      navigate('/book');
    }
  }, []);

  const handlePromoApply = () => {
    if (promoCode.trim() === '') {
      setPromoError('Promo code cannot be empty');
      return;
    }
    setPromoError('');
    console.log({ promoCode });
  };
  const totalHostAddOnsPrice = selectedAddOns?.reduce((sum, item) => sum + item.salePrice, 0)
  const totalHostPrice = selectedService?.salePrice && totalHostAddOnsPrice ? selectedService?.salePrice + totalHostAddOnsPrice : selectedService?.salePrice || 0
  const totalGuestPrice = guestsData.reduce((sum: number, guest: GuestData) => {
    const guestSelectedService = services?.services.find(service => service.zenotiId === guest.massageId);
    const guestServicePrice = guestSelectedService?.salePrice || 0;
  
    // Calculate add-ons price for the current guest
    const guestAddOnsPrice = services?.services
      .filter(service => guest.addOnIds.includes(service.zenotiId))
      .reduce((addOnSum, addOn) => addOnSum + addOn.salePrice, 0) || 0;
  
    return sum + guestServicePrice + guestAddOnsPrice;
  }, 0);

  const totalPrice = totalHostPrice + totalGuestPrice
  const isPayNowDisabled = !checkbox1 || creditCards?.data.length === 0;

  const handleConfirmBooking = () => {
    confirmBooking({
      data: {
      bookingId: bookingId || '',
      time: time || '',
      groupName: groupName || 'Members Portal Booking',
      notes
    }, boutiqueId: boutiqueId || ''
    })
  }

  const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 50) return;
    setGroupName(e.target.value);
  }

  if (isLoadingBoutique || isLoadingServices || isLoadingTherapists) return <Spinner />;
  if (boutiqueError || servicesError || therapistsError) return <ErrorScreen errors={[boutiqueError ?? undefined, servicesError ?? undefined, therapistsError ?? undefined]} />;

  return (
    <div className={styles.bookingCheckout}>
      <div className={styles.bookingDetails}>
        <h2>Appointment Details</h2>
        <BookingDetails 
          title={selectedService?.name || ''}
          location={boutique?.data.name || ''}
          locationId={boutiqueId}
          therapistName={selectedTherapist?.nickName || ''}
          date={formattedDate || ''}
          time={formattedTime || ''}
          addOns={selectedAddOns?.map(addOn => addOn.name) || []}
        />
      </div>

      <div className={styles.paymentDetails}>
        {guestsData.length > 0 && (
          <div className={styles.contentBlock}>
            <h2>Name Your Group</h2>
            <p>Please reference your group name if you have questions before your appointment or when you arrive for your appointment</p>
            <div>
              <Input id="group-name" label="Name Your Group" inputProps={{value: groupName, onChange: handleGroupNameChange}} />
              <p className={styles.charactersLimit}>{50 - groupName.length} characters remaining</p>
            </div>
          </div>
        )}
        <div className={styles.contentBlock}>
          <h2>Payment Method</h2>
          {isCreditCardLoading ? (
            <Spinner />
          ) : creditCards?.data && creditCards?.data.length > 0 ? (
            <div className={styles.inputWithAction}>
              <p><strong>{creditCards?.data[0].cardLogo}</strong> ending in {creditCards?.data[0].lastFour}</p>
              <button className={`button__underline`} onClick={() => setShowPaymentMethodModal(true)}>MANAGE</button>
            </div>
          ) : (
            <button className="button" onClick={() => setShowAddPaymentMethodModal(true)}>Add Payment Method</button>
          )}

          <div className={styles.inputWithAction}>
            <input
              placeholder="Add promo code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
            <button className="button__underline" onClick={handlePromoApply}>APPLY</button>
          </div>
          {promoError && <p className={styles.promoError}>{promoError}</p>}
          {isPromoApplied && <p className={styles.promoSuccess}>Promo code applied!</p>}
        </div>

        <div className={styles.separator}></div>
        <div className={styles.priceDetailsGroup}>
          <h2>Price Details</h2>
          <div 
            onClick={() => toggleGuestDetails('host')}
            className={`${styles.expandingGuestBlock} ${expandedGuestPriceIds.includes('host') ? styles.expanded : ''}`}
            >
            <p className={styles.guestName}>Your Total</p>
            <div className={styles.expandingContentRight}>
              <p>${totalHostPrice}</p>
              <img src={ArrowDownIcon} alt="Toggle price details" />
            </div>
          </div>
          {expandedGuestPriceIds.includes('host') && (
          <div className={`${styles.contentBlock} ${styles.subContentBlock}`}>
            <div className={styles.priceDetails}>
              <div className={styles.packageInfo}>
                <img src={FlowerIcon} alt="Flower" />
                <p>{selectedService?.name}</p>
              </div>
              <div className={styles.price}>${selectedService?.salePrice}</div>
            </div>
            <div className={styles.addOns}>
              {selectedAddOns?.map(addOn => (
                <div className={styles.addOn} key={addOn.id}>
                  <p>+ {addOn.name}</p>
                  <p>${addOn.salePrice}</p>
                </div>
              ))}
            </div>
            <div className={styles.therapistInfo}>
              <img src={PreferenceIcon} alt="Therapist" />
              <p>Therapist, {selectedTherapist?.nickName || selectedTherapist?.gender || minorHostGender || "any"}</p>
            </div>
          </div>
          )}

          {guestsData.length > 0 &&
          guestsData.map((guest: GuestData, index: number) => {
            const guestSelectedService = services?.services.find(service => service.zenotiId === guest.massageId)
            const selectedAddOns = services?.services.filter(service => guest.addOnIds.includes(service.zenotiId))
            const guestTotalPrice = guestSelectedService?.salePrice && selectedAddOns ? guestSelectedService?.salePrice + selectedAddOns.reduce((sum, item) => sum + item.salePrice, 0) : 0
            const guestSelectedTherapist = therapists?.therapists.find(t => t.id === guest.therapistId)
            return (
              <div key={guest.name + index}>
                <div
                  onClick={() => toggleGuestDetails(guest.name + index)}
                  className={`${styles.expandingGuestBlock} ${expandedGuestPriceIds.includes(guest.name + index) ? styles.expanded : ''}`}
                >
                  <p className={styles.guestName}>{guest.name}'s Total</p>
                  <div className={styles.expandingContentRight}>
                    <p>${guestTotalPrice}</p>
                    <img src={ArrowDownIcon} alt="Toggle price details" />
                  </div>
                </div>
  
                {expandedGuestPriceIds.includes(guest.name + index) && (
                  <div className={`${styles.contentBlock} ${styles.subContentBlock}`}>
                    <div className={styles.priceDetails}>
                      <div className={styles.packageInfo}>
                        <img src={FlowerIcon} alt="Flower" />
                        <p>{guestSelectedService?.name}</p>
                      </div>
                      <div className={styles.price}>${guestSelectedService?.salePrice}</div>
                    </div>
                    <div className={styles.addOns}>
                      {selectedAddOns?.map(addOn => (
                        <div className={styles.addOn} key={addOn.id}>
                          <p>+ {addOn.name}</p>
                          <p>${addOn.salePrice}</p>
                        </div>
                      ))}
                    </div>
                    <div className={styles.therapistInfo}>
                      <img src={PreferenceIcon} alt="Therapist" />
                      <p>Therapist, {guestSelectedTherapist?.nickName || guest.therapistGender || "any"}</p>
                    </div>
                  </div>
                )}
              </div>
            )
          })}

          <div className={styles.separator}></div>

          <div className={`${styles.contentBlock} ${styles.subContentBlock}`}>
            <div className={styles.subTotal}>
              <p>Sub Total</p>
              <p>${totalPrice}</p>
            </div>
            <div className={styles.total}>
              <p><strong>Total</strong></p>
              <p><strong>${totalPrice}</strong></p>
            </div>
            {guestsData.length > 0 && <p className={styles.groupDisclaimer}>Please note, group bookings require a 50% deposit.</p>}
          </div>

        </div>
        
        <div className={styles.separator}></div>

        <div className={styles.notes}>
          <div onClick={() => setExpandNotes(prev => !prev)} className={styles.expandingBlock}>
            <h3>Special Requests (Optional)</h3>
            <img src={expandNotes ? CrossIcon : PlusIcon} alt="Toggle notes text area" />
          </div>
          {expandNotes && 
          <div>
            <textarea onChange={(e) => setNotes(e.target.value)} id="notes" name="notes" rows={4} cols={50}></textarea>
          </div>}
        </div>

        <div className={styles.separator}></div>

        <div className={styles.contentBlock}>
          <Checkbox
            id="cancellation-policy"
            label={<span>I accept the <button className="button__link" onClick={() => setShowCancellationPolicy(true)}>Cancellation Policy</button></span>}
            checked={checkbox1}
            onChange={(e) => setCheckbox1(e)}
            className={styles.checkbox}
          />
        </div>

        <button onClick={handleConfirmBooking} className={`button ${isPayNowDisabled ? 'disabled' : ''}`} disabled={isPayNowDisabled}>
          {isConfirmBookingPending ? <Spinner size={20} /> : "Book Now"}
        </button>
        {confirmBookingError && <p className={styles.error}>Error purchasing membership: {confirmBookingError.errorMessages[0]}.</p>}
      </div>

      <AddPaymentMethodModal onClose={() => setShowAddPaymentMethodModal(false)} isOpen={showAddPaymentMethodModal} />

      <Modal isOpen={showCancellationPolicy} onClose={() => setShowCancellationPolicy(false)} hideCloseButton>
        <div className={styles.cancellationModal}>
          <h2>Cancellation Policy</h2>
          <p>We respectfully request at least 12 hours notice if you need to cancel or rebook your massage appointment. We charge 50% of the reservation made to the credit card on file to compensate our staff for missed wages when there is a last minute cancellation.</p>
          <button onClick={() => setShowCancellationPolicy(false)} className="button__underline">CLOSE</button>
        </div>
      </Modal>
      
      <Modal isOpen={showPaymentMethodModal} onClose={() => setShowPaymentMethodModal(false)}>
        <PaymentMethodModal
          onClose={() => setShowPaymentMethodModal(false)}
          creditCards={creditCards?.data || []}
          addPaymentMethod={() => setShowAddPaymentMethodModal(true)}
        />
      </Modal>
    </div>
  );
};

export default BookingCheckout;
