// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/IvyPresto/IvyPresto Display Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/Neutra2Text/Neutra2Text-Book.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "headfont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
@font-face {
  font-family: "basefont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}
h1, h2, h3, h4, h5, h6 {
  font-family: "headfont", sans-serif;
  font-weight: 400;
}

.CustomSelectDropdown_selectLabel__WtnMf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d8d9d9;
  border-bottom: 1px solid #d8d9d9;
  padding: 0 1rem;
}
.CustomSelectDropdown_selectLabel__WtnMf.CustomSelectDropdown_open__dkAxL img {
  transform: rotate(180deg);
}

.CustomSelectDropdown_option__AgK6V {
  padding: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}
.CustomSelectDropdown_option__AgK6V p {
  margin: 0;
}
.CustomSelectDropdown_option__AgK6V.CustomSelectDropdown_selected__nU7GR {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CustomSelectDropdown_option__AgK6V.CustomSelectDropdown_separate__tkEyT {
  border-bottom: 1px solid #d8d9d9;
}
.CustomSelectDropdown_option__AgK6V.CustomSelectDropdown_disabled__5ECss {
  opacity: 40%;
}

.CustomSelectDropdown_disabled__5ECss .CustomSelectDropdown_option__AgK6V {
  opacity: 40%;
}
.CustomSelectDropdown_disabled__5ECss .CustomSelectDropdown_selected__nU7GR {
  opacity: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/constants/fonts.scss","webpack://./src/styles/components/CustomSelectDropdown.module.scss","webpack://./src/styles/constants/typography.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,+DAAA;ACCF;ADEA;EACE,uBAAA;EACA,+DAAA;ACAF;ACPA;EACC,mCAAA;EACA,gBAAA;ADSD;;AATA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,6BAAA;EACA,gCAAA;EACA,eAAA;AAYF;AATI;EACE,yBAAA;AAWN;;AANA;EACE,aAAA;EACA,iBAAA;EACA,eAAA;AASF;AARE;EACE,SAAA;AAUJ;AAPE;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AASJ;AAPE;EACE,gCAAA;AASJ;AANE;EACE,YAAA;AAQJ;;AAHE;EACE,YAAA;AAMJ;AAJE;EACE,aAAA;AAMJ","sourcesContent":["@font-face {\n  font-family: 'headfont';\n  src: url('/assets/fonts/IvyPresto/IvyPresto Display Regular.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'basefont';\n  src: url('/assets/fonts/Neutra2Text/Neutra2Text-Book.otf') format('opentype');\n}\n","@import '../constants/';\n\n.selectLabel {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-top: 1px solid $grey;\n  border-bottom: 1px solid $grey;\n  padding: 0 1rem;\n\n  &.open {\n    img {\n      transform: rotate(180deg);\n    }\n  }\n}\n\n.option {\n  padding: 1rem;\n  margin-left: 1rem;\n  cursor: pointer;\n  p {\n    margin: 0;\n  }\n\n  &.selected {\n    font-weight: 600;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  &.separate {\n    border-bottom: 1px solid $grey;\n  }\n\n  &.disabled {\n    opacity: 40%;\n  }\n}\n\n.disabled {\n  .option {\n    opacity: 40%;\n  }\n  .selected {\n    opacity: 100%;\n  }\n}","h1, h2, h3, h4, h5, h6 {\n font-family: 'headfont', sans-serif;\n font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectLabel": `CustomSelectDropdown_selectLabel__WtnMf`,
	"open": `CustomSelectDropdown_open__dkAxL`,
	"option": `CustomSelectDropdown_option__AgK6V`,
	"selected": `CustomSelectDropdown_selected__nU7GR`,
	"separate": `CustomSelectDropdown_separate__tkEyT`,
	"disabled": `CustomSelectDropdown_disabled__5ECss`
};
export default ___CSS_LOADER_EXPORT___;
