import { useState, useEffect } from "react"
import styles from '../styles/pages/NotificationsSettings.module.scss'
import { SwitchContainer } from "./core/SwitchContainer"
import WarningIcon from '../assets/icons/warning.svg'
import { useUpdateUserProfile, useUserProfile } from "../hooks/useUserProfile"
import Spinner from "./core/Spinner"
import ErrorScreen from "./core/ErrorMessages"
import { useToast } from "./core/ToastManager"

export const NotificationsSettings = () => {
  const { data: userProfile, isLoading: isUserProfileLoading, error: userProfileError } = useUserProfile()
  const { addToast } = useToast()
  const [showBanner, setShowBanner] = useState(false)
  const [isEmailOn, setIsEmailOn] = useState(userProfile?.data.prefMarketingEmail || false)
  const [isSmsOn, setIsSmsOn] = useState(userProfile?.data.prefMarketingSms || false)
  // have to manage loading state separately because we use the same api call to update both
  const [isEmailPending, setIsEmailPending] = useState(false)
  const [isSmsPending, setIsSmsPending] = useState(false)

  useEffect(() => {
    if (userProfile?.data.prefMarketingEmail !== undefined) {
      setIsEmailOn(userProfile.data.prefMarketingEmail)
    } 
    if (userProfile?.data.prefMarketingSms !== undefined) {
      setIsSmsOn(userProfile.data.prefMarketingSms)
    }
  }, [userProfile])

  const { mutate: updateUserProfile } = useUpdateUserProfile(
    (data) => {
      setIsEmailOn(data.data.prefMarketingEmail)
      setIsSmsOn(data.data.prefMarketingSms)
      setIsEmailPending(false)
      setIsSmsPending(false)
    },
    (error) => {
      addToast(error.errorMessages[0] || 'Error updating user notifications. Please try again later.', 'error')
      setIsEmailPending(false)
      setIsSmsPending(false)
    }
  )
  const onEmailChange = () => {
    setIsEmailPending(true)
    updateUserProfile({
      prefMarketingEmail: !isEmailOn,
    })
  }

  const onSmsChange = () => {
    setIsSmsPending(true)
    updateUserProfile({
      prefMarketingSms: !isSmsOn,
    })
  }

  if (isUserProfileLoading) return <Spinner />
  if (userProfileError) return <ErrorScreen errors={[userProfileError]} />

  return (
    <div className={styles.notificationsSettings}>
      {showBanner && (
        <div className={styles.banner}>
          <div className={styles.bannerTitle}>
            <img src={WarningIcon} alt="Warning icon" />
            <p>Web push notifications are off.</p>
          </div>
          <p className={styles.bannerDescription}>Turn on notifications to get notified of new responses on your device.</p>
          <button className="button__link">Turn on notifications</button>
          <button className={styles.closeButton} onClick={() => setShowBanner(false)}>
            &#x2715;
          </button>
        </div>
      )}
      <div className={styles.notificationsGroup}>
        <h2>Enable Marketing Communications</h2>
        <SwitchContainer
          id="email"
          isOn={isEmailOn}
          handleToggle={onEmailChange}
          label="Marketing Emails"
          isLoading={isEmailPending}
          />
        <div className={styles.separator}></div>
        <SwitchContainer
          id="sms"
          isOn={isSmsOn}
          handleToggle={onSmsChange}
          label="SMS"
          isLoading={isSmsPending}
          />
      </div>
    </div>
  )
}

export default NotificationsSettings