import styles from '../styles/pages/Memberships.module.scss';
import StarsIcon from '../assets/icons/stars-icon.svg';
import PiggyBankIcon from '../assets/icons/piggy-bank-icon.svg';
import ShoppingBagIcon from '../assets/icons/shopping-bag-icon.svg';
import ArrowsCircleIcon from '../assets/icons/arrows-circle-icon.svg';
import { numberToText } from '../utils/caseConverter';
import { Link } from 'react-router-dom';
import { useUserProfile } from '../hooks/useUserProfile';
import { useMemberships } from '../hooks/useMemberships';
import Spinner from '../components/core/Spinner';
import { LocationPicker } from '../components/core/LocationPicker';
import { useBoutique } from '../hooks/useBoutiques';
import ArrowIcon from '../assets/icons/arrow-down.svg';
import { useState } from 'react';
import LocationSearch from '../components/LocationSearch';
import ErrorScreen from '../components/core/ErrorMessages';

const MembershipBenefits = [
  { title: 'Friends & family member rate for anyone new to The NOW', icon: StarsIcon },
  { title: '10% off in-store purchases on nourish, scent & room by the NOW Products', icon: ShoppingBagIcon },
  { title: 'Savings on monthly massages', icon: PiggyBankIcon },
  { title: 'Roll-over unused credits', icon: ArrowsCircleIcon },
];

export const Memberships = () => {
  const { data: userProfile, isLoading: isLoadingUserProfile, error: userProfileError } = useUserProfile();
  const [selectedBoutique, setSelectedBoutique] = useState<string>(userProfile?.data.boutiqueId || '');
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(selectedBoutique || userProfile?.data.boutiqueId || '');
  const { data: boutiqueMemberships, isLoading: isLoadingMemberships, error: membershipsError } = useMemberships(selectedBoutique || userProfile?.data.boutiqueId || '');
  const [showLocationSearch, setShowLocationSearch] = useState<boolean>(false);

  const handleBoutiqueChange = (boutiqueId: string) => {
    setSelectedBoutique(boutiqueId);
    setShowLocationSearch(false);
  }

  if (isLoadingUserProfile || isLoadingMemberships || isLoadingBoutique) {
    return <div><Spinner /></div>;
  }

  if (userProfileError || membershipsError || boutiqueError) return <ErrorScreen errors={[userProfileError ?? undefined, membershipsError ?? undefined, boutiqueError ?? undefined]} />
  const hasMemberships = boutiqueMemberships?.membershipGroups?.some(group => group.memberships.length > 0);

  return (
    <div className={styles.noMemberships}>
      <div className={styles.header}>
        <h3 className={styles.benefitTitle}>Save up to $100 on monthly massages</h3>
        <p>Make massage a part of your monthly ritual. No long-term commitments, cancel any time with 30 days notice</p>
      </div>
      <div className={styles.membershipBenefits}>
        {MembershipBenefits.map((benefit, index) => (
          <div key={index} className={styles.membershipBenefit}>
            <img src={benefit.icon} alt="benefit icon" />
            <p>{benefit.title}</p>
          </div>
        ))}
      </div>
      <div className={styles.locationContainer}>
        <div className={styles.locationPickerContainer}>
          <h2>Select a Boutique:</h2>
          <LocationPicker currentBoutique={boutique?.data.id || ''} onBoutiqueChange={handleBoutiqueChange} />
        </div>
        <div className={styles.boutiqueCardLocation} onClick={() => setShowLocationSearch(true)}>
          <h4>{boutique?.data.name}</h4>
          <p>{boutique?.data.address}, {boutique?.data.city}, {boutique?.data.state} {boutique?.data.zip}</p>
          <img className={styles.arrowIcon} src={ArrowIcon} alt="arrow" />
        </div>
      </div>
      <div className={styles.selectMembership}>
        {hasMemberships && boutiqueMemberships ? (
          boutiqueMemberships.membershipGroups.map((membershipGroup) => (
            membershipGroup.memberships.length > 0 && (
              <div key={membershipGroup.id} className={styles.membershipColumn}>
                <h3>{membershipGroup.name} ({membershipGroup.memberships[0].durationInMinutes} min)</h3>
                {membershipGroup.memberships.map((membership) => (
                  <div
                    key={membership.zenotiId}
                    className={styles.membershipCard}
                  >
                    <div className={styles.membershipContentLeft}>
                      <p className={styles.membershipFrequency}>
                        {numberToText(membership.frequencyPerMonth)} {membership.durationInMinutes} minute {membership.frequencyPerMonth > 1 ? 'massages' : 'massage'}
                      </p>
                      <p>per month</p>
                      <Link to={`/checkout/${membership.boutique.id}/membership/${membership.zenotiId}`} className={`button`}>Choose</Link>
                    </div>
                    <div className={styles.membershipContentRight}>
                      <div className={styles.price}>
                        <div className={styles.membershipPrice}>${membership.price}</div>
                        <div className={styles.nonMemberPrice}>per month</div>
                      </div>
                      {membership.memberSaving && <p className={styles.discount}>${membership.memberSaving} SAVINGS</p>}
                    </div>
                  </div>
                ))}
              </div>
            )
          ))
        ) : (
          <p>No memberships found for selected location.</p>
        )}
      </div>
      {showLocationSearch && 
        <LocationSearch
          currentBoutique={boutique?.data || null}
          setBoutique={(boutique) => setSelectedBoutique(boutique.id)}
          onClose={() => setShowLocationSearch(false)} 
        />
      }
    </div>
  );
};

export default Memberships;
