import React from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import Spinner from '../core/Spinner';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location');

  // Map for guest routes based on the path
  const guestRouteMap: Record<string, string> = {
    '/book': '/book-guest',
  };

  if (loading) return <Spinner />;

  if (!isAuthenticated) {
    // Save the intended path before redirecting
    localStorage.setItem('redirectPath', location.pathname + location.search);

    // Redirect to the guest route or login based on path
    const guestRoute = guestRouteMap[location.pathname];
    if (guestRoute) {
      return <Navigate to={`${guestRoute}?location=${locationId}`} />;
    }

    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
