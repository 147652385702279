import { Link } from 'react-router-dom';
import styles from '../styles/components/FooterLegal.module.scss';

export const FooterLegal = () => {
  return (
    <div className={styles.footerLegal}>
      <div className={`${styles.footerLegalContainer} container`}>
        <div className={styles.footerLinks}>
          <ul>
            <li><Link to={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/accessibility-statement`}>Accessibility Statement</Link></li>
            <li><Link to={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/terms-of-service`}>Terms of Service</Link></li>
            <li><Link to={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/privacy-policy`}>Privacy Policy</Link></li>
          </ul>
        </div>
        <div className={styles.footerCopyright}>
          <p>The NOW Massage. All Rights Reserved 2024.</p>
        </div>
      </div>
    </div>
  )
}
