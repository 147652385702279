import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchBoutiques, fetchBoutiqueById, fetchBoutiquePromotions, fetchNationalPromotions, fetchPromoDetails } from '../api/boutiques';
import { BoutiquesResponse, Boutique, BoutiquesRequestParams, PromotionResponse, PromoDetailsResponse } from '../types/boutiques';
import { ApiErrorResponse } from '../types/api';


export const useBoutiques = (options: BoutiquesRequestParams = {}) => {
  return useQuery<BoutiquesResponse, ApiErrorResponse>({
    queryKey: ['boutiques', options],
    queryFn: () => fetchBoutiques(options)
  });
};

export const useBoutique = (id: string) => {
  return useQuery<{ data: Boutique }, ApiErrorResponse>({
    queryKey: ['boutique', id],
    queryFn: () => fetchBoutiqueById(id)
  });
};

export const useBoutiquePromotions = (boutiqueId: string) => {
  return useQuery<PromotionResponse, ApiErrorResponse>({
    queryKey: ['boutiquePromotions', boutiqueId],
    queryFn: () => fetchBoutiquePromotions(boutiqueId)
  });
}

export const useNationalPromotions = () => {
  return useQuery<PromotionResponse, ApiErrorResponse>({
    queryKey: ['nationalPromotions'],
    queryFn: () => fetchNationalPromotions()
  });
}

export const usePromoDetails = (
  onSuccess?: (data: PromoDetailsResponse) => void,
  onError?: (error: ApiErrorResponse) => void
) => {
  return useMutation<PromoDetailsResponse, ApiErrorResponse, { promoCode: string, boutiqueId: string}>({
    mutationFn: ({promoCode, boutiqueId}) => fetchPromoDetails(promoCode, boutiqueId),
    onSuccess,
    onError,
  });
};
