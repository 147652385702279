import { Link } from 'react-router-dom';
import styles from '../styles/pages/Dashboard.module.scss';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import MapPinIcon from '../assets/icons/location-pin-dashboard.svg';
import AppointmentsIcon from '../assets/icons/appointments.svg'
import MemberIcon from '../assets/icons/become-a-member.svg'
import QuestionMarkIcon from '../assets/icons/question-intake-form.svg';
import ArrowIcon from '../assets/icons/arrow-down.svg';
import GiftCardsIcon from '../assets/icons/gift-cards.svg'
import { useToast } from '../components/core/ToastManager';
import GiftCardBalance from '../components/GiftCardBalance';
import Spinner from '../components/core/Spinner';
import MembershipCredits from '../components/core/MembershipCredits';
import { DashboardCard } from '../components/DashboardCard';
import { numberToText } from '../utils/caseConverter';
import { useUserAppointments } from '../hooks/useAppointments';
import { useUpdateUserBoutique, useUserMemberships, useUserProfile } from '../hooks/useUserProfile';
import { useBoutique } from '../hooks/useBoutiques';
import { formatPhoneNumber } from '../utils/formatPhone';
import { LocationPicker } from '../components/core/LocationPicker';
import { useState } from 'react';
import LocationSearch from '../components/LocationSearch';
import { Boutique } from '../types/boutiques';
import ErrorScreen from '../components/core/ErrorMessages';

export const Dashboard = () => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const [showLocationSearch, setShowLocationSearch] = useState(false);
  const { data: userProfile, isLoading: isLoadingUserProfile, error: userProfileError } = useUserProfile()
  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(userProfile?.data.boutiqueId || '')
  const { data: userMemberships, isLoading: isLoadingUserMemberships, error: userMembershipsError } = useUserMemberships(true);
  const { data: appointments, isLoading: isLoadingAppointments, error: appointmentsError } = useUserAppointments()
  const membership = userMemberships?.membershipGroups && userMemberships?.membershipGroups.length > 0 ? userMemberships.membershipGroups[0].userMemberships[0]?.membership : null;
  const membershipGroup = userMemberships?.membershipGroups ? userMemberships.membershipGroups[0] : null;
  const membershipDescription = membership 
    ? `${numberToText(membership.frequencyPerMonth)} ${membership.durationInMinutes} minute ${membership.frequencyPerMonth > 1 ? 'massages' : 'massage'} per month` 
    : 'Enjoy a 50 or 80 minute massage, once to twice per month. Save on your monthly massages.';
  const membershipCredits = membership?.creditBalance || null
  const creditsName = membershipGroup?.name || null

  const updateBoutiqueMutation = useUpdateUserBoutique();

  const handleBoutiqueChange = async (boutique: string | Boutique) => {
    const boutiqueId = typeof boutique === 'string' ? boutique : boutique.id;
    try {
      await updateBoutiqueMutation.mutateAsync(boutiqueId);
      queryClient.invalidateQueries({ queryKey: ['userProfile'] });
      queryClient.invalidateQueries({ queryKey: ['boutique', { boutique_id: userProfile?.data.boutiqueId }] });
      addToast('Boutique updated successfully', 'success');
    } catch (error) {
      addToast('Failed to update boutique. Please try again.', 'error');
    }
  };

  const nextAppointment = appointments?.futureAppointments && appointments.futureAppointments[0]
  const numberOfGuests = nextAppointment && nextAppointment?.noOfGuests > 1 ? `Me, ${nextAppointment?.noOfGuests} guests` : 'Just me'
  const appointmentTitle = nextAppointment ? nextAppointment.serviceName : 'You don’t have any upcoming bookings'

  if (isLoadingUserProfile) return <Spinner />
  if (userProfileError) return <ErrorScreen errors={[userProfileError]} />

  return (
    <div className={styles.dashboard}>
      <h1>Welcome back{userProfile?.data.firstName ? `, ${userProfile.data.firstName}!` : "!"}</h1>
      <div className={styles.cardGrid}>
        <DashboardCard iconUrl={AppointmentsIcon} title="Upcoming Appointment">
          {isLoadingAppointments 
            ? <Spinner />
            : <div className={styles.upcomingAppointmentContent}>
                <div className={styles.upcomingAppointmentInfo}>
                  {nextAppointment && <Link to="/appointments" className={styles.viewAppointmentsButton}>VIEW ALL</Link>}
                  {nextAppointment && <p className={styles.appointmentDate}>{DateTime.fromISO(nextAppointment.date).toFormat('MMMM dd')}, {nextAppointment.time}</p>}
                  <p>{appointmentTitle}</p>
                  {nextAppointment && <p className={styles.appointmentGuests}>{numberOfGuests}</p>}
                  {nextAppointment && <p className={styles.appointmentLocation}>{nextAppointment.boutiqueName}</p>}
                </div>
                <Link className={`button ${styles.cta}`} to={nextAppointment ? '/appointments' : '/book'}>
                  {nextAppointment ? 'View Details' : 'Book an Appointment'}
                </Link>
              </div>
          }
          {appointmentsError && !isLoadingAppointments && <ErrorScreen errors={[appointmentsError]} />}
        </DashboardCard>
        <DashboardCard iconUrl={MemberIcon} title={membership ? "Your Membership" : 'Become a Member'}>
          {isLoadingUserMemberships 
            ? <Spinner /> 
            : <div className={styles.memberCardContent}>
                {membershipGroup && <p className={styles.membershipSubtitle}>{membershipGroup.name}</p>}
                <p>{membershipDescription}</p>
                {membership && <p className={styles.membershipLocation}>{membership.boutique.name}</p>}
                {membershipCredits && creditsName && <MembershipCredits name={creditsName} amount={membershipCredits} />}
                <Link className={`button inverted ${styles.cta}`} to="/settings/memberships">
                  {membership ? 'Manage Membership' : 'Become a Member'}
                </Link>
              </div>
          }
          {userMembershipsError && <ErrorScreen errors={[userMembershipsError]} />}
        </DashboardCard>
        <DashboardCard 
          iconUrl={MapPinIcon}
          title="Your Boutique"
          >
          {isLoadingBoutique ? <Spinner /> : (
            <div className={styles.locationContainer}>
              <div className={styles.locationPickerContainer}>
                <LocationPicker currentBoutique={boutique?.data.id || ''} onBoutiqueChange={handleBoutiqueChange} />
              </div>
              <div className={styles.boutiqueCardLocation} onClick={() => setShowLocationSearch(true)} >
                <h4>{boutique?.data.name}</h4>
                <p>{boutique?.data.address}, {boutique?.data.city}, {boutique?.data.state} {boutique?.data.zip}</p>
                <img className={styles.arrowIcon} src={ArrowIcon} alt="arrow" />
              </div>
            </div>
          )}
          {boutiqueError && <p className={styles.error}>Failed to load boutique information</p>}
        </DashboardCard>
        <DashboardCard iconUrl={GiftCardsIcon} title="Gift Card Balance">
          <div className={styles.giftCardContent}>
            <p>Enter the gift card code to check the balance.</p>
            <GiftCardBalance />
            <Link className={`button__underline ${styles.purchaseGiftCardButton}`} to={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/giftcards`}>
              Purchase Gift Card
            </Link>
          </div>
        </DashboardCard>
        {/* DO NOT DELETE, WILL UNCOMMENT LATER */}
        {/* <DashboardCard iconUrl={QuestionMarkIcon} title="In-Take Questionnaire">
          <div className={styles.questionnaireCardContent}>
            <p>Please complete the required in-take questionnaire prior to arriving for a massage.</p>
            <Link className={`button inverted ${styles.cta}`} to="/questionnaire">
              Complete Questionnaire
            </Link>
          </div>
        </DashboardCard> */}
        <DashboardCard title="Welcome to our Elevated New Site!">
          <div className={styles.newSiteCard}>
            <p>If you experience any issues or need assistance with accessing your account or booking, please contact the boutique.</p>
            <Link className="button inverted w-full" to={`tel:${boutique?.data.phoneNumber}`}>call {formatPhoneNumber(boutique?.data.phoneNumber || '')}</Link>
          </div>
        </DashboardCard>
        {showLocationSearch && 
          <LocationSearch
            currentBoutique={boutique?.data || null}
            setBoutique={handleBoutiqueChange}
            onClose={() => setShowLocationSearch(false)} 
          />
        }
      </div>
    </div>
  );
};

export default Dashboard;