import styles from '../../styles/components/CustomSelectDropdown.module.scss';
import ArrowDownIcon from '../../assets/icons/arrow-down.svg'
import CheckMarkIcon from '../../assets/icons/checkmark.svg'
import { useState } from 'react';
import { useToast } from './ToastManager';

interface CustomSelectDropdownProps {
  label: string;
  className?: string;
  options: {
    label: string;
    value: string;
    disabled?: boolean;
  }[]
  onChange: (value: string) => void;
  selectedOption?: string;
  optionSeparator?: boolean
  disabled?: boolean
  disabledMessage?: string
}

export const CustomSelectDropdown: React.FC<CustomSelectDropdownProps> = ({ 
  label,
  className,
  options,
  onChange,
  selectedOption,
  optionSeparator,
  disabled,
  disabledMessage
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { addToast } = useToast()
  const handleOnChange = (value: string, disabledOption?: boolean ) => {
    if (disabled || disabledOption) {
      setIsOpen(false)
      addToast(disabledMessage || 'This option is disabled', 'error')
      return
    }
    onChange(value)
  }
  return (
    <div className={`${styles.customSelect} ${className}`} onClick={() => setIsOpen((prev) => !prev)}>
      <div className={`${styles.selectLabel} ${isOpen ? styles.open : ''}`}>
        <p>{label}</p>
        <img src={ArrowDownIcon} alt="arrow down" />
      </div>
      {isOpen && (
        <div className={`${styles.optionsDropdown} ${disabled ? styles.disabled : ''}`}>
          {options.map(option => (
            <div 
              className={`${styles.option} ${selectedOption === option.value ? styles.selected : '' } ${optionSeparator ? styles.separate : ''} ${option.disabled ? styles.disabled : ''}`}
              key={option.value}
              onClick={() => handleOnChange(option.value, option.disabled)}
              >
              <p>{option.label}</p>
              {selectedOption === option.value && <img src={CheckMarkIcon} alt="checkmark" />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelectDropdown
