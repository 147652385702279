import { Guest } from "../pages/Booking";

export interface BookingStep {
  label: string;
  id: string;
  index: number;
}

export interface StepGroup {
  groupLabel: string;
  steps: BookingStep[];
}

export const generateStepGroups = (guestsInfo: Guest[]): StepGroup[] => {
  const stepGroups: StepGroup[] = [
    {
      groupLabel: "Choose Duration",
      steps: [
        { label: "Choose Your Duration", id: "duration-host", index: 1 },
        ...guestsInfo.map((guest, index) => ({
          label: `Choose ${guest.firstName || `Guest ${index + 1}`}'s Duration`,
          id: `duration-guest-${index + 1}`,
          index: 2 + index,
        })),
      ],
    },
    {
      groupLabel: "Pressure Preference",
      steps: [
        { label: "Select Your Pressure Preference", id: "pressure-host", index: 1 },
        ...guestsInfo.map((guest, index) => ({
          label: `Select ${guest.firstName || `Guest ${index + 1}`}'s Pressure Preference`,
          id: `pressure-guest-${index + 1}`,
          index: 2 + index,
        })),
      ],
    },
    {
      groupLabel: "Select Massage",
      steps: [
        { label: "Select Your Massage", id: "massage-host", index: 1 },
        ...guestsInfo.map((guest, index) => ({
          label: `Select ${guest.firstName || `Guest ${index + 1}`}'s Massage`,
          id: `massage-guest-${index + 1}`,
          index: 2 + index,
        })),
      ],
    },
    {
      groupLabel: "Choose Enhancements",
      steps: [
        { label: "Choose Your Enhancements", id: "enhancements-host", index: 1 },
        ...guestsInfo.map((guest, index) => ({
          label: `Choose ${guest.firstName || `Guest ${index + 1}`}'s Enhancements`,
          id: `enhancements-guest-${index + 1}`,
          index: 2 + index,
        })),
      ],
    },
  ];

  // // Add the appointment step only once, as a separate group
  // stepGroups.push({
  //   groupLabel: "Select Appointment",
  //   steps: [{ label: "Appointment", id: "appointment", index: stepGroups.length + 1 }],
  // });

  return stepGroups;
};
