// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Switch_switchCheckbox__WWVya {
  height: 0;
  width: 0;
  visibility: hidden;
}

.Switch_switchLabel__\\+kBDW {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  height: 32px;
  background: rgba(64, 77, 106, 0.5019607843);
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.Switch_switchLabel__\\+kBDW .Switch_switchButton__\\+AE6h {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 28px;
  height: 28px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.Switch_switchCheckbox__WWVya:checked + .Switch_switchLabel__\\+kBDW .Switch_switchButton__\\+AE6h {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.Switch_switchLabel__\\+kBDW:active .Switch_switchButton__\\+AE6h {
  width: 60px;
}

.Switch_switchCheckbox__WWVya:disabled + .Switch_switchLabel__\\+kBDW {
  background: rgba(64, 77, 106, 0.3058823529);
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/Switch.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,QAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,2CAAA;EACA,oBAAA;EACA,kBAAA;EACA,iCAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,4CAAA;AACF;;AAEA;EACE,sBAAA;EACA,4BAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,2CAAA;EACA,mBAAA;AACF","sourcesContent":[".switchCheckbox {\n  height: 0;\n  width: 0;\n  visibility: hidden;\n}\n\n.switchLabel {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  cursor: pointer;\n  width: 60px;\n  height: 32px;\n  background: #404D6A80;\n  border-radius: 100px;\n  position: relative;\n  transition: background-color .2s;\n}\n\n.switchLabel .switchButton {\n  position: absolute;\n  top: 2px;\n  left: 2px;\n  width: 28px;\n  height: 28px;\n  border-radius: 45px;\n  transition: 0.2s;\n  background: #fff;\n  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);\n}\n\n.switchCheckbox:checked + .switchLabel .switchButton {\n  left: calc(100% - 2px);\n  transform: translateX(-100%);\n}\n\n.switchLabel:active .switchButton {\n  width: 60px;\n}\n\n.switchCheckbox:disabled + .switchLabel {\n  background: #404d6a4e;\n  cursor: not-allowed;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switchCheckbox": `Switch_switchCheckbox__WWVya`,
	"switchLabel": `Switch_switchLabel__+kBDW`,
	"switchButton": `Switch_switchButton__+AE6h`
};
export default ___CSS_LOADER_EXPORT___;
