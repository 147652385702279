// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/IvyPresto/IvyPresto Display Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/Neutra2Text/Neutra2Text-Book.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "headfont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
@font-face {
  font-family: "basefont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}
h1, h2, h3, h4, h5, h6 {
  font-family: "headfont", sans-serif;
  font-weight: 400;
}

.MembershipTermsModal_membershipTerms__5XkDx {
  max-height: 60vh;
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/styles/constants/fonts.scss","webpack://./src/styles/components/MembershipTermsModal.module.scss","webpack://./src/styles/constants/typography.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,+DAAA;ACCF;ADEA;EACE,uBAAA;EACA,+DAAA;ACAF;ACPA;EACC,mCAAA;EACA,gBAAA;ADSD;;AATA;EACE,gBAAA;EACA,kBAAA;AAYF","sourcesContent":["@font-face {\n  font-family: 'headfont';\n  src: url('/assets/fonts/IvyPresto/IvyPresto Display Regular.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'basefont';\n  src: url('/assets/fonts/Neutra2Text/Neutra2Text-Book.otf') format('opentype');\n}\n","@import '../constants/';\n\n.membershipTerms {\n  max-height: 60vh;\n  overflow-y: scroll;\n}","h1, h2, h3, h4, h5, h6 {\n font-family: 'headfont', sans-serif;\n font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"membershipTerms": `MembershipTermsModal_membershipTerms__5XkDx`
};
export default ___CSS_LOADER_EXPORT___;
