import React, { useState, useEffect, ReactNode } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import { Boutique } from '../types/boutiques';
import styles from '../styles/components/LocationSearch.module.scss';
import { useBoutiques } from '../hooks/useBoutiques';
import MapPinFilledIcon from '../assets/icons/map-pin-filled.svg'
import ActiveMapPinIcon from '../assets/icons/active-map-pin.svg'
import GeoIcon from '../assets/icons/geo.svg'
import CrossIcon from '../assets/icons/cross.svg'
import MapIcon from '../assets/icons/map.svg'
import PhoneIcon from '../assets/icons/phone.svg'
import ClockIcon from '../assets/icons/clock.svg'
import ArrowDownIcon from '../assets/icons/arrow-down.svg'
import 'mapbox-gl/dist/mapbox-gl.css';
import { DateTime } from 'luxon';
import { useToast } from './core/ToastManager';
import { formatPhoneNumber } from '../utils/formatPhone';

interface Props {
  currentBoutique: Boutique | null;
  setBoutique: (boutique: Boutique) => void;
  onClose: () => void;
}

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_TOKEN;

export const LocationSearch: React.FC<Props> = ({
  currentBoutique,
  setBoutique,
  onClose
}) => {
  const { data: boutiquesData } = useBoutiques({page: 1, perPage: 100});
  const boutiques = boutiquesData?.data.boutiques || []
  const [viewport, setViewport] = useState({
    latitude: 34.0522, 
    longitude: -118.2437,
    zoom: 10,
  });

  const isCoordinatesValid = (lat: number, lng: number) => {
    return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
  }

  const { addToast } = useToast();

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBoutiques, setFilteredBoutiques] = useState<Boutique[]>(boutiques);
  const [selectedBoutique, setSelectedBoutique] = useState<Boutique | null>(null)

  // Update filtered boutiques based on search query
  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredBoutiques(boutiques);
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      setFilteredBoutiques(
        boutiques.filter(
          (b) =>
            b.name.toLowerCase().includes(lowerCaseQuery) ||
            b.city?.toLowerCase().includes(lowerCaseQuery) ||
            b.state?.toLowerCase().includes(lowerCaseQuery) ||
            b.zip?.toLowerCase().includes(lowerCaseQuery) ||
            b.address?.toLowerCase().includes(lowerCaseQuery)
        )
      );
    }
  }, [searchQuery, boutiques]);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleViewOnMap = (boutique: Boutique) => {
    if (!isCoordinatesValid(boutique.lat, boutique.lng)) {
      addToast('Invalid coordinates for this boutique. We apologize for inconvenience', 'error');
      return
    }
    setViewport({
      latitude: boutique.lat, 
      longitude: boutique.lng,
      zoom: 10,
    })
    setSelectedBoutique(boutique)
  }

  // Render boutique markers on the map
  const renderMarkers = () => {
    return filteredBoutiques.map((boutique) => {
      if (!isCoordinatesValid(boutique.lat, boutique.lng)) {
        return null;
      }
  
      const isActive = selectedBoutique?.id === boutique.id;
  
      return (
        <Marker key={boutique.id} latitude={boutique.lat} longitude={boutique.lng}>
          <div onClick={() => handleViewOnMap(boutique)} className={styles.marker}>
            <img
              src={isActive ? ActiveMapPinIcon : MapPinFilledIcon}
              alt={isActive ? 'active map pin' : 'map pin'}
              className={isActive ? styles.activePin : ''}
            />
          </div>
        </Marker>
      );
    });
  };

  const getOpenStatus = (hours: Record<string, string[][]>): string | ReactNode => {
    const now = DateTime.local().setZone('America/New_York'); // Assuming boutique timezone is EST
    const dayOfWeek = now.toFormat('cccc').toLowerCase();
    const todayHours = hours[dayOfWeek];

    if (todayHours) {
      const [openTime, closeTime] = todayHours[0];
      const openDateTime = DateTime.fromFormat(openTime, 'HH:mm', { zone: 'America/New_York' });
      const closeDateTime = DateTime.fromFormat(closeTime, 'HH:mm', { zone: 'America/New_York' });

      if (now >= openDateTime && now <= closeDateTime) {
        return (
          <>
            <span>Open Now</span> - Closes at {closeDateTime.toFormat('h:mm a')}
          </>
        );
      }
    }
    // Find next opening time
    const tomorrow = now.plus({ days: 1 }).toFormat('cccc').toLowerCase();
    const nextDayHours = hours[tomorrow];
    if (nextDayHours) {
      const [nextOpenTime] = nextDayHours[0];
      const nextOpenDateTime = DateTime.fromFormat(nextOpenTime, 'HH:mm', { zone: 'America/New_York' });
      return (
        <>
          <span>Closed Now</span> - Opens at {nextOpenDateTime.toFormat('h:mm a')}
        </>
      );
    }

    return 'Closed Now';
  };

  const formatWeeklyHours = (hours: string | Record<string, string[][]> | undefined): string[] | React.ReactNode[] => {
    if (!hours) return ['Hours not available'];
  
    let hoursData: Record<string, string[][]>;
    
    // If hours is a JSON string, parse it to an object
    if (typeof hours === 'string') {
      try {
        hoursData = JSON.parse(hours);
      } catch (error) {
        return ['Invalid hours format'];
      }
    } else {
      hoursData = hours;
    }
  
    // Convert the hours object into the desired format
    return Object.entries(hoursData).map(([day, timeRanges]) => {
      const formattedDay = day.charAt(0).toUpperCase() + day.slice(1); // Capitalize the day
      if (!timeRanges || timeRanges.length === 0) {
        return `${formattedDay}: Closed`;
      }
  
      const formattedHours = timeRanges
        .map(([open, close]) => {
          const openTime = DateTime.fromFormat(open, 'HH:mm').toFormat('h:mm a');
          const closeTime = DateTime.fromFormat(close, 'HH:mm').toFormat('h:mm a');
          return `${openTime} - ${closeTime}`;
        })
        .join(', ');
  
      return <div className={styles.workHours} key={formattedDay}><span>{formattedDay}</span> {formattedHours}</div>;
    });
  };

  const handleBoutiqueSelect = (boutique: Boutique) => {
    if (boutique.useOldSite) {
      window.open(boutique.oldBookingUrl,'_blank')
    } else {
      setBoutique(boutique);
      onClose();
    }
  }

  return (
    <div className={styles.locationSearch}>
      <div className={styles.mapContainer}>
        <ReactMapGL
          {...viewport}
          style={{width: '100%', height: '100%'}}
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle="mapbox://styles/mapbox/light-v10"
          onMove={(evt) => setViewport(evt.viewState)}
        >
          {renderMarkers()}
        </ReactMapGL>
      </div>
      <div className={styles.inputContainer}>
        <input
          type="text"
          className={styles.searchBox}
          placeholder="Search by city, state, zip, or address"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button onClick={() => setSearchQuery('')} className={styles.clearButton}><img src={CrossIcon} alt="Clear search" /></button>
        <button className={styles.geoButton}><img src={GeoIcon} alt="Geo location" /></button>
      </div>
        <div className={`${styles.boutiqueList} ${filteredBoutiques.length === 0 ? styles.noResults : ""}`}>
          {selectedBoutique ? (
            <div className={styles.boutiqueInfo}>
              <button onClick={()=> setSelectedBoutique(null)} className={styles.closeInfoButton}><img src={CrossIcon} alt="close boutique info" /></button>
              <h3>{selectedBoutique.name}</h3>
              <p>{selectedBoutique.address}, {selectedBoutique.city}, {selectedBoutique.state} {selectedBoutique.zip}</p>
              <button onClick={() => handleBoutiqueSelect(selectedBoutique)} className="button w-full">Book</button>
              <div className={styles.phone}>
                <img src={PhoneIcon} alt="phone" />
                {formatPhoneNumber(selectedBoutique.phoneNumber || '')}
              </div>
              <div className={styles.separator}></div>
              <div className={styles.clock}>
                <img src={ClockIcon} alt="hours of operation" />
                {selectedBoutique.hoursOfOperation && getOpenStatus(selectedBoutique.hoursOfOperation)}
              </div>
              {selectedBoutique.hoursOfOperation && selectedBoutique.hoursOfOperation && (
              <ul className={styles.weeklyHours}>
                {formatWeeklyHours(selectedBoutique.hoursOfOperation).map((formattedHour, index) => (
                  <li key={index}>{formattedHour}</li>
                ))}
                </ul>
              )}

            </div>
          ) : (
            <>
              <ul>
                {filteredBoutiques.map((boutique) => (
                  <li key={boutique.id} className={styles.boutiqueItem}>
                    <div onClick={() => handleViewOnMap(boutique)} className={styles.boutiqueName}>
                      <h3>{boutique.name}</h3>
                      <img src={ArrowDownIcon} alt="arrow" />
                    </div>
                    <p>{boutique.address}, {boutique.city}, {boutique.state} {boutique.zip}</p>
                    <div className={styles.buttons}>
                      <button onClick={() => handleBoutiqueSelect(boutique)} className={`${styles.selectButton} button`}>Book</button>
                      <button onClick={() => handleViewOnMap(boutique)} className={`${styles.viewMapButton} button__link`}><img src={MapIcon} alt="map icon" /> View on Map</button>
                    </div>
                  </li>
                ))}
              </ul>
              {filteredBoutiques.length === 0 && (
                <div className={styles.noLocations}>
                  <h3>No Locations Found</h3>
                  <p>There are no boutiques matching this text.</p>
                </div>
              )}
            </>
          )}
        </div>
    </div>
  );
};

export default LocationSearch;