import React from 'react';
import ReactDOM from 'react-dom';
import styles from '../../styles/components/Modal.module.scss';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  hideCloseButton?: boolean;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, className, hideCloseButton = false }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={`${styles.modalContent} ${className ? className : ''}`} onClick={e => e.stopPropagation()}>
        {!hideCloseButton && <button className={styles.closeButton} onClick={onClose}>&#x2715;</button>}
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
