import React, { useState, useRef, useEffect } from 'react';
import styles from '../../styles/components/Dropdown.module.scss';
import ArrowIcon from '../../assets/icons/arrow-down.svg';
import InputFeedback from './InputFeedback';

interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  id: string;
  label: string;
  className?: string;
  error?: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  id,
  label,
  className,
  error,
  options,
  value,
  onChange,
  onBlur,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === value);
    setSelectedLabel(selectedOption ? selectedOption.label : '');
  }, [options, value]);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleOptionClick = (option: Option) => {
    onChange(option.value);
    setIsOpen(false);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsOpen(false);
      onBlur?.();
    }
  };

  return (
    <div
      className={`${styles.dropdown} ${className} ${error ? styles.error : ''}`}
      ref={dropdownRef}
      tabIndex={0}
      onBlur={handleBlur}
    >
      <div
        className={styles.dropdownInput}
        onClick={toggleDropdown}
      >
        <span className={styles.label} data-focused={isOpen || !!value}>
          {label}
        </span>
        <span className={styles.selectedValue}>
          {selectedLabel}
        </span>
        <img
          src={ArrowIcon}
          className={`${styles.arrowIcon} ${isOpen ? styles.open : ''}`}
          alt="arrow down"
        />
      </div>
      {error && <InputFeedback message={error} variant="error" extraClasses={styles.errorMessage} />}
      {isOpen && options.length > 0 && (
        <ul className={styles.suggestionsList}>
          {options.map((option, index) => (
            <li
              key={index}
              className={styles.suggestion}
              onClick={() => handleOptionClick(option)}
              tabIndex={0}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
