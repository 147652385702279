import { Link } from 'react-router-dom';
import { useRitualCollectivePerks } from '../hooks/useRitualCollective';
import styles from '../styles/pages/RitualCollective.module.scss';
import Spinner from '../components/core/Spinner';
import NavArrow from '../assets/icons/slider-arrow.svg';
import ExpandArrow from '../assets/icons/arrow-expand.svg';
import CopyIcon from '../assets/icons/copy-icon.svg';
import { useState } from 'react';
import ErrorScreen from '../components/core/ErrorMessages';
import { useToast } from '../components/core/ToastManager';
import Modal from '../components/core/Modal';

export const RitualCollective = () => {
  const { data: perks, isLoading: isPerksLoading, error: perksError } = useRitualCollectivePerks({ page: 1, perPage: 50 });
  const [openTerms, setOpenTerms] = useState('');
  const [expandedPerks, setExpandedPerks] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const { addToast } = useToast();

  const handleExpandToggle = (id: string) => {
    setExpandedPerks((prev) =>
      prev.includes(id) ? prev.filter((perkId) => perkId !== id) : [...prev, id]
    );
  };

  const totalPages = Math.ceil((perks?.data.ritualCollectivePerks.length || 0) / itemsPerPage);

  const currentPerks = perks?.data.ritualCollectivePerks.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const handleCopyToClipboard = (code: string) => {
    navigator.clipboard.writeText(code);
    addToast(`Code "${code}" copied to clipboard`, 'success');
  }

  if (isPerksLoading) return <Spinner />;
  if (perksError) return <ErrorScreen errors={[perksError]}/>;

  return (
    <div className={styles.ritualCollective}>
      <h1 className={styles.pageTitle}>The Ritual Collective</h1>
      <p className={styles.pageDescription}>
        Elevate your self-care routine with premium perks from our favorite brands.<br /> Exclusively for Ritual Members.
      </p>
      <div className={styles.perks}>
        {currentPerks?.map((perk) => (
          <div key={perk.id} className={styles.perk}>
            <img src={perk.imageUrl} alt={perk.description} />
            <h3>{perk.title}</h3>
            <p className={styles.subTitle}>{perk.subTitle}</p>
              <div className={`${styles.description}  ${expandedPerks.includes(perk.id) ? styles.active : ''}`}>
                <p>{perk.description}</p>
                {perk.termsAndConditions && <button className={`button__link ${styles.termsLink}`} onClick={() => setOpenTerms(perk.id)}>Terms & Conditions Apply</button>}
              </div>
            <button
              className={styles.expandButton}
              onClick={() => handleExpandToggle(perk.id)}
            >
              <img
                src={ExpandArrow}
                alt={expandedPerks.includes(perk.id) ? 'View less' : 'View more'}
                className={expandedPerks.includes(perk.id) ? styles.active : ''}
              />
              {expandedPerks.includes(perk.id) ? 'View less' : 'View more'}
            </button>
            {perk.code && (
              <div className={`${styles.actions} ${perk.code ? styles.hasCode : ''}`}>
                <div className={styles.code}>
                  <p>{perk.code}</p>
                  <button onClick={() => handleCopyToClipboard(perk.code || '')}>
                    <img src={CopyIcon} alt="copy code" />
                  </button>
                </div>
                <Link className={`button inverted ${styles.cta}`} to={perk.link}>
                  {perk.ctaText}
                </Link>
              </div>
            )}
            <Modal isOpen={openTerms === perk.id} onClose={() => setOpenTerms('')}>
              <h2>{perk.title}</h2>
              <p>{perk.termsAndConditions}</p>
            </Modal>
          </div>
        ))}
      </div>
      <div className={styles.navigation}>
        <button
          className={`${styles.prevStep} ${currentPage === 1 ? styles.disabled : ''}`}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <img src={NavArrow} alt="prev page" />
        </button>
        <div className={styles.pages}>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`${styles.pageNumber} ${currentPage === index + 1 ? styles.active : ''}`}
              onClick={() => handlePageClick(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <button
          className={`${styles.nextStep} ${currentPage === totalPages ? styles.disabled : ''}`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <img src={NavArrow} alt="next page" />
        </button>
      </div>
    </div>
  );
};
