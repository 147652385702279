import { get, post, put } from './apiClient';
import { 
  ServicesResponse,
  TherapistsResponse,
  CreateServiceBookingRequest,
  CreateServiceBookingResponse,
  ConfirmServiceBookingRequest,
  ConfirmServiceBookingResponse,
  UpdateServiceBookingRequest,
  UpdateServiceBookingResponse,
  CreateGuestsRequest,
  CreateGuestsResponse,
  EnhancementsResponse
} from '../types/bookings';


export const fetchServices = async (boutiqueId: string): Promise<ServicesResponse> => {
  const response = await get<ServicesResponse>(`api/v1/boutiques/${boutiqueId}/services`);
  
  return response.data;
};

export const fetchTherapists = async (boutiqueId: string): Promise<TherapistsResponse> => {
  const response = await get<TherapistsResponse>(`api/v1/boutiques/${boutiqueId}/therapists`);
  
  return response.data;
};

export const createServiceBooking = async (
  boutiqueId: string, 
  data: CreateServiceBookingRequest
): Promise<CreateServiceBookingResponse> => {
  const response = await post<CreateServiceBookingResponse, CreateServiceBookingRequest>(
    `api/v1/boutiques/${boutiqueId}/create_service_booking`,
    data
  );
  
  return response.data;
};

export const confirmServiceBooking = async (
  boutiqueId: string, 
  data: ConfirmServiceBookingRequest
): Promise<ConfirmServiceBookingResponse> => {
  const response = await post<ConfirmServiceBookingResponse, ConfirmServiceBookingRequest>(
    `api/v1/boutiques/${boutiqueId}/confirm_service_booking`,
    data
  );
  
  return response.data;
};

export const updateServiceBooking = async (
  boutiqueId: string, 
  data: UpdateServiceBookingRequest
): Promise<UpdateServiceBookingResponse> => {
  const response = await put<UpdateServiceBookingResponse, UpdateServiceBookingRequest>(
    `api/v1/boutiques/${boutiqueId}/update_service_booking`,
    data
  );
  
  return response.data;
};

export const createGuests = async (
  boutiqueId: string, 
  data: CreateGuestsRequest
): Promise<CreateGuestsResponse> => {
  const response = await post<CreateGuestsResponse, CreateGuestsRequest>(
    `api/v1/boutiques/${boutiqueId}/guests`,
    data
  );
  
  return response.data;
};

export const fetchEnhancements = async (
  boutiqueId: string
): Promise<EnhancementsResponse> => {
  const response = await get<EnhancementsResponse>(`api/v1/boutiques/${boutiqueId}/enhancements`);
  
  return response.data;
};