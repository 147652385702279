// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/IvyPresto/IvyPresto Display Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/Neutra2Text/Neutra2Text-Book.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "headfont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
@font-face {
  font-family: "basefont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}
h1, h2, h3, h4, h5, h6 {
  font-family: "headfont", sans-serif;
  font-weight: 400;
}

.PageNotFound_pageNotFound__dBBpd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}
.PageNotFound_pageNotFound__dBBpd img {
  width: 50%;
}
@media (max-width: 767px) {
  .PageNotFound_pageNotFound__dBBpd {
    flex-direction: column;
  }
  .PageNotFound_pageNotFound__dBBpd img {
    width: 100%;
  }
}

.PageNotFound_actions__htryn {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/constants/fonts.scss","webpack://./src/styles/pages/PageNotFound.module.scss","webpack://./src/styles/constants/typography.scss","webpack://./src/styles/constants/mixins.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,+DAAA;ACCF;ADEA;EACE,uBAAA;EACA,+DAAA;ACAF;ACPA;EACC,mCAAA;EACA,gBAAA;ADSD;;AATA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;AAYF;AAVE;EACE,UAAA;AAYJ;AEZE;EFPF;IAWI,sBAAA;EAYF;EAVE;IACE,WAAA;EAYJ;AACF;;AAPA;EACE,kBAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;AAUF","sourcesContent":["@font-face {\n  font-family: 'headfont';\n  src: url('/assets/fonts/IvyPresto/IvyPresto Display Regular.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'basefont';\n  src: url('/assets/fonts/Neutra2Text/Neutra2Text-Book.otf') format('opentype');\n}\n","@import '../constants/';\n\n.pageNotFound {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 4rem;\n\n  img {\n    width: 50%;\n  }\n\n  @include breakpoint-down(md) {\n    flex-direction: column;\n\n    img {\n      width: 100%;\n    }\n  }\n\n\n}\n.actions {\n  margin-top: 1.5rem;\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n}","h1, h2, h3, h4, h5, h6 {\n font-family: 'headfont', sans-serif;\n font-weight: 400;\n}","@mixin breakpoint-up($size) {\n  $breakpoint: map-get($grid-breakpoints, $size);\n  @media (min-width: $breakpoint) {\n    @content;\n  }\n}\n\n@mixin breakpoint-down($size) {\n  $breakpoint: map-get($grid-breakpoints, $size);\n  @media (max-width: ($breakpoint - 1px)) {\n    @content;\n  }\n}\n\n@mixin make-max-widths-container-width($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {\n  @each $breakpoint, $container-max-width in $max-widths {\n    @include breakpoint-up($breakpoint) {\n      max-width: $container-max-width;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageNotFound": `PageNotFound_pageNotFound__dBBpd`,
	"actions": `PageNotFound_actions__htryn`
};
export default ___CSS_LOADER_EXPORT___;
