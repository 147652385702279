import { useEffect } from 'react';
import styles from '../../styles/components/Toast.module.scss';
import ErrorIcon from '../../assets/icons/error-input.svg';
import SuccessIcon from '../../assets/icons/grey-checkmark.svg';
import WarningIcon from '../../assets/icons/warning.svg';
import InfoIcon from '../../assets/icons/info-filled.svg';

interface ToastProps {
  message: string;
  type?: 'success' | 'error' | 'warning' | 'info';
  duration?: number;
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, type = 'success', duration = 3000, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const getIcon = () => {
    switch (type) {
      case 'success':
        return SuccessIcon;
      case 'error':
        return ErrorIcon;
      case 'warning':
        return WarningIcon;
      case 'info':
        return InfoIcon;
      default:
        return SuccessIcon;
    }
  };

  return (
    <div className={`${styles.toast} ${styles[type]}`}>
      <img src={getIcon()} alt={type} />
      <p>{message}</p>
      <button className={styles.closeButton} onClick={onClose}>
        &#x2715;
      </button>
    </div>
  );
};

export default Toast;
