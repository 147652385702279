// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/IvyPresto/IvyPresto Display Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/Neutra2Text/Neutra2Text-Book.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "headfont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
@font-face {
  font-family: "basefont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}
h1, h2, h3, h4, h5, h6 {
  font-family: "headfont", sans-serif;
  font-weight: 400;
}

@media (min-width: 768px) {
  .UpdatePassword_form__WGMtK {
    max-width: 350px;
  }
}

.UpdatePassword_button__c7Gbr {
  margin-top: 1rem;
}
@media (max-width: 767px) {
  .UpdatePassword_button__c7Gbr {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/constants/fonts.scss","webpack://./src/styles/pages/UpdatePassword.module.scss","webpack://./src/styles/constants/typography.scss","webpack://./src/styles/constants/mixins.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,+DAAA;ACCF;ADEA;EACE,uBAAA;EACA,+DAAA;ACAF;ACPA;EACC,mCAAA;EACA,gBAAA;ADSD;;AETE;EFAF;IAEE,gBAAA;EAYA;AACF;;AAVA;EACE,gBAAA;AAaF;AEZE;EFFF;IAII,WAAA;EAcF;AACF","sourcesContent":["@font-face {\n  font-family: 'headfont';\n  src: url('/assets/fonts/IvyPresto/IvyPresto Display Regular.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'basefont';\n  src: url('/assets/fonts/Neutra2Text/Neutra2Text-Book.otf') format('opentype');\n}\n","@import '../constants/';\n\n.form {\n@include breakpoint-up(md) {\n  max-width: 350px;\n}\n}\n.button {\n  margin-top: 1rem;\n\n  @include breakpoint-down(md) {\n    width: 100%;\n  }\n}","h1, h2, h3, h4, h5, h6 {\n font-family: 'headfont', sans-serif;\n font-weight: 400;\n}","@mixin breakpoint-up($size) {\n  $breakpoint: map-get($grid-breakpoints, $size);\n  @media (min-width: $breakpoint) {\n    @content;\n  }\n}\n\n@mixin breakpoint-down($size) {\n  $breakpoint: map-get($grid-breakpoints, $size);\n  @media (max-width: ($breakpoint - 1px)) {\n    @content;\n  }\n}\n\n@mixin make-max-widths-container-width($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {\n  @each $breakpoint, $container-max-width in $max-widths {\n    @include breakpoint-up($breakpoint) {\n      max-width: $container-max-width;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `UpdatePassword_form__WGMtK`,
	"button": `UpdatePassword_button__c7Gbr`
};
export default ___CSS_LOADER_EXPORT___;
