export const formatPhoneNumber = (phone: string) => {
  // Remove the country code and any non-numeric characters
  const cleaned = phone.replace(/^\+1/, '').replace(/\D/g, '');
  // Format the cleaned number as XXX.XXX.XXXX
  const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3');
  return formatted;
};

export const normalizePhoneNumber = (phone: string) => phone.replace(/[^0-9]/g, '');

