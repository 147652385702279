// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/IvyPresto/IvyPresto Display Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/Neutra2Text/Neutra2Text-Book.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "headfont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
@font-face {
  font-family: "basefont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}
h1, h2, h3, h4, h5, h6 {
  font-family: "headfont", sans-serif;
  font-weight: 400;
}

.BookingFlowPressure_pressure__4LwdD {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.BookingFlowPressure_pressureOption__FPA6C {
  padding: 1rem;
  border: 1px solid #d8d9d9;
  width: 100%;
}
.BookingFlowPressure_pressureOption__FPA6C .BookingFlowPressure_pressureTitle__Afwmx {
  font-weight: 600;
}
.BookingFlowPressure_pressureOption__FPA6C.BookingFlowPressure_selected__eFW1t {
  background-color: #ecedf0;
  border-color: #404D6A;
}
.BookingFlowPressure_pressureOption__FPA6C.BookingFlowPressure_disabled__fyS7F {
  opacity: 0.6;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/styles/constants/fonts.scss","webpack://./src/styles/components/BookingFlowPressure.module.scss","webpack://./src/styles/constants/typography.scss","webpack://./src/styles/constants/vars.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,+DAAA;ACCF;ADEA;EACE,uBAAA;EACA,+DAAA;ACAF;ACPA;EACC,mCAAA;EACA,gBAAA;ADSD;;AATA;EACE,aAAA;EACA,SAAA;EACA,WAAA;AAYF;;AATA;EACE,aAAA;EACA,yBAAA;EACA,WAAA;AAYF;AAVE;EACE,gBAAA;AAYJ;AATE;EACE,yBAAA;EACA,qBElBM;AF6BV;AARE;EACE,YAAA;EACA,mBAAA;AAUJ","sourcesContent":["@font-face {\n  font-family: 'headfont';\n  src: url('/assets/fonts/IvyPresto/IvyPresto Display Regular.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'basefont';\n  src: url('/assets/fonts/Neutra2Text/Neutra2Text-Book.otf') format('opentype');\n}\n","@import '../constants/';\n\n.pressure {\n  display: flex;\n  gap: 1rem;\n  width: 100%;\n}\n\n.pressureOption {\n  padding: 1rem;\n  border: 1px solid $grey;\n  width: 100%;\n\n  .pressureTitle {\n    font-weight: 600;\n  }\n\n  &.selected {\n    background-color: #ecedf0;\n    border-color: $primary;\n  }\n\n  &.disabled {\n    opacity: 0.6;\n    cursor: not-allowed;\n  }\n}","h1, h2, h3, h4, h5, h6 {\n font-family: 'headfont', sans-serif;\n font-weight: 400;\n}","// colors\n$primary: #404D6A;\n$accent: #e1e5ef;\n$grey: #d8d9d9;\n$white: #fefcf5;\n$inactive-menu-item: #8f98a8;\n\n$breakpoint-xs: 0px;\n$breakpoint-sm: 576px;\n$breakpoint-md: 768px;\n$breakpoint-lg: 992px;\n$breakpoint-xl: 1200px;\n$breakpoint-xxl: 1600px;\n\n$grid-breakpoints: (\n  xs: $breakpoint-xs,\n  sm: $breakpoint-sm,\n  md: $breakpoint-md,\n  lg: $breakpoint-lg,\n  xl: $breakpoint-xl,\n  xxl: $breakpoint-xxl\n);\n\n$container-max-widths: (\n  xs: 100%, \n  sm: 540px,\n  md: 720px,\n  lg: 940px,\n  xl: 1140px,\n  xxl: 1320px\n);\n\n$main-menu-height: 64px;\n$main-menu-height-sm: 56px;\n$sub-menu-height: 56px;\n$sub-menu-height-sm: 48px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pressure": `BookingFlowPressure_pressure__4LwdD`,
	"pressureOption": `BookingFlowPressure_pressureOption__FPA6C`,
	"pressureTitle": `BookingFlowPressure_pressureTitle__Afwmx`,
	"selected": `BookingFlowPressure_selected__eFW1t`,
	"disabled": `BookingFlowPressure_disabled__fyS7F`
};
export default ___CSS_LOADER_EXPORT___;
