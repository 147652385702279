import { createBrowserRouter, Outlet } from 'react-router-dom';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import GuestLayout from './layouts/GuestLayout';
import BlankLayout from './layouts/BlankLayout';
import PrivateRoute from './components/auth/PrivateRoute';
import NotificationsSettings from './components/NotificationsSettings';
// Pages
import Dashboard from './pages/Dashboard';
import AccountSettings from './pages/AccountSettings';
import UserMemberships from './pages/UserMemberships';
import UpdatePassword from './pages/UpdatePassword';
import PersonalDetails from './pages/PersonalDetails';
import BillingDetails from './pages/BillingDetails';
import UpdateMembership from './pages/UpdateMembership';
import Login from './pages/Login';
import CreateAccount from './pages/CreateAccount';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import CancelMembership from './pages/CancelMembership';
import Appointments from './pages/Appointments';
import { AddCardSuccessRedirect } from './pages/AddCardSuccessRedirect';
import Checkout from './pages/Checkout';
import PurchaseConfirmation from './pages/PurchaseConfirmation';
import PurchaseSuccessRedirect from './pages/PurchaseSuccessRedirect';
import Memberships from './pages/Memberships';
import Booking from './pages/Booking';
import BookingCheckout from './pages/BookingCheckout';
import GiftCardCheckout from './pages/GiftCardCheckout';
import CustomGiftCardCheckout from './pages/CustomGiftCardCheckout';
import GiftCardPurchaseConfirmation from './pages/GiftCardPurchaseConfirmation';
// TODO: temp dev
import { TestingPage } from './pages/TestingPage';
import BookingConfirmation from './pages/BookingConfirmation';
import { RitualCollective } from './pages/RitualCollective';
import PauseMembership from './pages/PauseMembership';
import PageNotFound from './pages/PageNotFound';
import CustomGiftCardPurchaseConfirmation from './pages/CustomGiftCardPurchaseConfirmation';

const authenticatedRoutes = [
  { path: '/', element: <Dashboard /> },
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/checkout/:boutiqueId/:serviceType/:membershipId', element: <Checkout /> },
  { path: '/booking-checkout/:boutiqueId/:bookingId/:serviceId', element: <BookingCheckout /> },
  { path: '/gift-card-checkout/:boutiqueId/:templateId', element: <GiftCardCheckout /> },
  { path: '/purchase-confirmation/:boutiqueId/:membershipId', element: <PurchaseConfirmation /> },
  { path: '/custom-gift-card-checkout/:boutiqueId', element: <CustomGiftCardCheckout /> },
  { path: '/custom-gift-card-purchase-confirmation/:boutiqueId', element: <CustomGiftCardPurchaseConfirmation /> },
  { path: '/booking-confirmation/:boutiqueId/:serviceId', element: <BookingConfirmation /> },
  { path: '/gift-card-purchase-confirmation/:boutiqueId/:templateId', element: <GiftCardPurchaseConfirmation /> },
  { path: '/memberships', element: <Memberships /> },
  { path: '/appointments', element: <Appointments />},
  { path: '/book', element: <Booking /> },
  { path: 'ritual-collective', element: <RitualCollective />},
  { path: 'memberships/pause/:membershipId', element: <PauseMembership /> },
  { path: '*', element: <PageNotFound />},
  // TODO: temp dev, delete later
  { path: '/invoice-close-dev/:invoiceId/:membershipIdToCancel', element: <TestingPage /> },
  { 
    path: '/settings', 
    element: <AccountSettings />,
    children: [
      { index: true, element: <PersonalDetails />},
      { path: 'notifications', element: <NotificationsSettings /> },
      { path: 'password', element: <UpdatePassword /> },
      { path: 'personal-details', element: <PersonalDetails /> },
      { path: 'billing', element: <BillingDetails /> },
      { path: 'memberships', element: <UserMemberships /> }
    ]
  },
  { path: '/settings/memberships/update/:boutiqueId/:membershipId/:userMembershipId', element: <UpdateMembership /> },
  { path: '/settings/memberships/cancel/:boutiqueId/:membershipId/:userMembershipId', element: <CancelMembership /> },
];

const guestRoutes = [
  { path: 'login', element: <Login /> },
  { path: 'register', element: <CreateAccount /> },
  { path: 'forgot-password', element: <ForgotPassword /> },
  { path: 'reset-password', element: <ResetPassword /> },
  { path: '/book-guest', element: <Booking /> },
  { path: '*', element: <PageNotFound />}
];

const blankRoutes = [
  { path: 'add-card-success', element: <AddCardSuccessRedirect /> },
  { path: 'purchase-success', element: <PurchaseSuccessRedirect /> },
]

export const router = createBrowserRouter([
  {
    element: (
      <PrivateRoute>
        <AuthenticatedLayout>
          <Outlet />
        </AuthenticatedLayout>
      </PrivateRoute>
    ),
    children: authenticatedRoutes,
  },
  {
    element: (
      <GuestLayout>
        <Outlet />
      </GuestLayout>
    ),
    children: guestRoutes,
  },
  {
    element: (
      <BlankLayout>
        <Outlet />
      </BlankLayout>
    ),
    children: blankRoutes,
  },
]);