// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/IvyPresto/IvyPresto Display Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/Neutra2Text/Neutra2Text-Book.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "headfont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
@font-face {
  font-family: "basefont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}
h1, h2, h3, h4, h5, h6 {
  font-family: "headfont", sans-serif;
  font-weight: 400;
}

h2 {
  margin-top: 0;
}

.PauseMembershipModal_status__7sxDx, .PauseMembershipModal_remainingMonths__0d\\+hB {
  border: 1px solid #d8d9d9;
  padding: 1rem;
  margin-bottom: 1rem;
}
.PauseMembershipModal_status__7sxDx p, .PauseMembershipModal_remainingMonths__0d\\+hB p {
  margin-bottom: 0;
}

.PauseMembershipModal_statusHeading__JrSJB {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.PauseMembershipModal_statusHeading__JrSJB p {
  margin: 0;
  font-weight: 600;
}

.PauseMembershipModal_remainingMonths__0d\\+hB {
  text-align: center;
  font-weight: 600;
}

.PauseMembershipModal_termsHeading__klJwj {
  font-weight: 600;
  margin-top: 1.5rem;
}

.PauseMembershipModal_termsList__jLM44 {
  padding-left: 1.2rem;
}
.PauseMembershipModal_termsList__jLM44 li {
  list-style-type: disc;
  padding-left: 0;
}

.PauseMembershipModal_actions__z5W-i {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/constants/fonts.scss","webpack://./src/styles/components/PauseMembershipModal.module.scss","webpack://./src/styles/constants/typography.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,+DAAA;ACCF;ADEA;EACE,uBAAA;EACA,+DAAA;ACAF;ACPA;EACC,mCAAA;EACA,gBAAA;ADSD;;AATA;EACE,aAAA;AAYF;;AAVA;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;AAaF;AAZE;EACE,gBAAA;AAcJ;;AAVA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAaF;AAZE;EACE,SAAA;EACA,gBAAA;AAcJ;;AAVA;EACE,kBAAA;EACA,gBAAA;AAaF;;AAVA;EACE,gBAAA;EACA,kBAAA;AAaF;;AAVA;EACE,oBAAA;AAaF;AAZE;EACE,qBAAA;EACA,eAAA;AAcJ;;AAVA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;AAaF","sourcesContent":["@font-face {\n  font-family: 'headfont';\n  src: url('/assets/fonts/IvyPresto/IvyPresto Display Regular.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'basefont';\n  src: url('/assets/fonts/Neutra2Text/Neutra2Text-Book.otf') format('opentype');\n}\n","@import '../constants/';\n\nh2 {\n  margin-top: 0;\n}\n.status, .remainingMonths {\n  border: 1px solid $grey;\n  padding: 1rem;\n  margin-bottom: 1rem;\n  p {\n    margin-bottom: 0;\n  }\n}\n\n.statusHeading {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n  p {\n    margin: 0;\n    font-weight: 600;\n  }\n}\n\n.remainingMonths {\n  text-align: center;\n  font-weight: 600;\n}\n\n.termsHeading {\n  font-weight: 600;\n  margin-top: 1.5rem;\n}\n\n.termsList {\n  padding-left: 1.2rem;\n  li {\n    list-style-type: disc;\n    padding-left: 0;\n  }\n}\n\n.actions {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  margin-top: 1.5rem;\n}","h1, h2, h3, h4, h5, h6 {\n font-family: 'headfont', sans-serif;\n font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `PauseMembershipModal_status__7sxDx`,
	"remainingMonths": `PauseMembershipModal_remainingMonths__0d+hB`,
	"statusHeading": `PauseMembershipModal_statusHeading__JrSJB`,
	"termsHeading": `PauseMembershipModal_termsHeading__klJwj`,
	"termsList": `PauseMembershipModal_termsList__jLM44`,
	"actions": `PauseMembershipModal_actions__z5W-i`
};
export default ___CSS_LOADER_EXPORT___;
