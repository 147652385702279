// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputFeedback_inputFeedback__98u-8 {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 3px;
}
.InputFeedback_inputFeedback__98u-8.InputFeedback_success__Tedt5 img {
  display: block;
}
.InputFeedback_inputFeedback__98u-8.InputFeedback_error__ardhh img {
  display: none;
}
.InputFeedback_inputFeedback__98u-8.InputFeedback_apiError__yL6ja img {
  display: block !important;
  width: 16px;
}
.InputFeedback_inputFeedback__98u-8 .InputFeedback_feedbackText__2HwMK {
  font-size: 12px;
  margin: 0;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/InputFeedback.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AAEI;EACE,cAAA;AAAN;AAKI;EACE,aAAA;AAHN;AAQI;EACE,yBAAA;EACA,WAAA;AANN;AAUE;EACE,eAAA;EACA,SAAA;EACA,gBAAA;AARJ","sourcesContent":[".inputFeedback {\n  margin-top: 0.5rem;\n  display: flex;\n  align-items: center;\n  gap: 3px;\n\n  &.success {\n    img {\n      display: block;\n    }\n  }\n\n  &.error {\n    img {\n      display: none;\n    }\n  }\n\n  &.apiError {\n    img {\n      display: block !important;\n      width: 16px;\n    }\n  }\n\n  .feedbackText {\n    font-size: 12px;\n    margin: 0;\n    font-weight: 600;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputFeedback": `InputFeedback_inputFeedback__98u-8`,
	"success": `InputFeedback_success__Tedt5`,
	"error": `InputFeedback_error__ardhh`,
	"apiError": `InputFeedback_apiError__yL6ja`,
	"feedbackText": `InputFeedback_feedbackText__2HwMK`
};
export default ___CSS_LOADER_EXPORT___;
