import styles from '../styles/components/BookingDetails.module.scss'
import FlowerIcon from '../assets/icons/flower.svg'
import MapPinIcon from '../assets/icons/map-pin.svg'
import CalendarIcon from '../assets/icons/calendar.svg'
import PreferencesIcon from '../assets/icons/preferences.svg'
import Arrow from '../assets/icons/arrow-down.svg'
import { Link } from 'react-router-dom'

interface OrderDetailsProps {
  title: string;
  date: string;
  location: string;
  therapistName: string
}

export const BookingDetails: React.FC<OrderDetailsProps> = ({
  title,
  date,
  location,
  therapistName
}) => {
  return (
    <div className={`${styles.bookingDetails}`}>
      <div className={styles.navigation}>
        <img src={Arrow} alt="Back Arrow" />
        <Link className="button__link" to="/book?restoreSession=true">Edit Options</Link>
      </div>
      <div className={styles.bookingInfo}>
        <div className={styles.item}>
          <img src={CalendarIcon} alt="Calendar" />
          <p>{date}</p>
        </div>
        <div className={styles.item}>
          <img src={MapPinIcon} alt="Map Pin Icon" />
          <p>{location}</p>
        </div>
        <div className={styles.item}>
          <img src={FlowerIcon} alt="Flower Icon" />
          <div className={styles.itemInfo}>
            <p>{title}</p>
          </div>
        </div>
        {therapistName && <div className={styles.item}>
          <img src={PreferencesIcon} alt="Therapist" />
          <p>Therapist, {therapistName}</p>
        </div>}
      </div>
    </div>
  )
}

export default BookingDetails