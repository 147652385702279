import { get, post } from './apiClient';
import { GiftCardsResponse, CreateGiftCardRequest, CreateGiftCardResponse, GiftCardBalanceResponse } from '../types/giftCards';

export const fetchGiftCardTemplates = async (boutiqueId: string): Promise<GiftCardsResponse> => {
  const response = await get<GiftCardsResponse>(`/api/v1/boutiques/${boutiqueId}/gift_card_templates`);
  return response.data;
};

export const createGiftCard = async (
  boutiqueId: string, 
  data: CreateGiftCardRequest
): Promise<CreateGiftCardResponse> => {
  const response = await post<CreateGiftCardResponse, CreateGiftCardRequest>(
    `/api/v1/boutiques/${boutiqueId}/gift_card_templates`,
    data
  );
  return response.data;
};

export const checkGiftCardBalance = async (giftCardCode: string): Promise<GiftCardBalanceResponse> => {
  const response = await get<GiftCardBalanceResponse>(`/api/v1/giftcards/${giftCardCode}`);
  return response.data;
};