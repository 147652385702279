import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom';
import styles from '../styles/pages/BookingCheckout.module.scss';
import { Checkbox } from '../components/core/Checkbox';
import AddPaymentMethodModal from '../components/AddPaymentMethodModal';
import Modal from '../components/core/Modal';
import Spinner from '../components/core/Spinner';
import { PaymentMethodModal } from '../components/PaymentMethodModal';
import { useToast } from '../components/core/ToastManager';
import { useCreditCards } from '../hooks/useCreditCards';
import { useBoutique } from '../hooks/useBoutiques';
import { useCloseInvoice } from '../hooks/useInvoice';
import { useCreateGiftCard, useGiftCardTemplates } from '../hooks/useGiftCards';
import GiftCardDetails from '../components/GiftCardDetails';
import ErrorScreen from '../components/core/ErrorMessages';
import { useCreateUserMembership, useUserProfile } from '../hooks/useUserProfile';

export const GiftCardCheckout = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { templateId, boutiqueId } = useParams();
  const [searchParams] = useSearchParams()
  const message = searchParams.get('message')
  const recipientName = searchParams.get('recipient_name')
  const recipientEmail = searchParams.get('recipient_email')
  const promoCodeSecondaryEmail = searchParams.get('promo_code_secondary_email')

  const { data: boutique, isLoading: isLoadingBoutique, error: boutiqueError } = useBoutique(boutiqueId || '')
  const { data: userProfile, isLoading: userProfileLoading, error: userProfileError } = useUserProfile();
  const { data: giftCardTemplates, isLoading: isLoadingGiftCardTemplates, error: giftCardTemplatesError } = useGiftCardTemplates(boutiqueId || '')
  const selectedGiftCard = giftCardTemplates?.templates.find(giftCard => giftCard.zenotiId === templateId)
  const promotionalValue = selectedGiftCard && selectedGiftCard?.value - selectedGiftCard?.price

  const [isCardVerificationRequired, setIsCardVerificationRequired] = useState(false);
  const [openIframeModal, setOpenIframeModal] = useState(false);

  const { mutate: createGiftCard, data: giftCardData, isPending: isCreateGiftCardPending, error: createGiftCardError} = useCreateGiftCard(boutiqueId || '',
    (data) => {
      if ((creditCards?.data[0].accountId)) {
        purchaseGiftCard({
          redirectUri: `${process.env.REACT_APP_WEBSITE_URL}/purchase-success`,
          invoiceId: data.data.invoiceId,
          accountId: creditCards?.data[0].accountId,
        })
      } else {
        addToast(`Purchase failed: No credit card on file. Please add a credit card to proceed.`, 'warning');
      }
    },
    (error) => {
      addToast(`Error: ${error.error_messages[0]}`, 'error');
    }
  )

  const { mutate: closeInvoice, isPending: isCloseInvoicePending, error: closeInvoiceError } = useCloseInvoice(
    (data) => {
      if (data.data.status !== 'Closed') {
        addToast(`Error purchasing gift card`, 'error');
      } else {
        addToast('Purchase successful!', 'success', 3500);
        navigate(`/gift-card-purchase-confirmation/${boutiqueId}/${templateId}?message=${message}`);
      }
    },
    (error) => {
      addToast(`Error closing invoice: ${error.error_messages[0]}`, 'error');
    }
  );

  const { mutate: purchaseGiftCard, data: purchaseGiftCardData, isPending: isPurchaseGiftCardPending, error: purchaseGiftCardError } = useCreateUserMembership(
    (data) => {
      if (!data.data.success) {
        setIsCardVerificationRequired(true);
        setOpenIframeModal(true);
      } else if (data.data.success && giftCardData?.data.invoiceId) {
        closeInvoice({invoiceId: giftCardData?.data.invoiceId, giftCardPurchase: true});
      }
    },
    (error) => {
      addToast(`Error purchasing gift card: ${error.error_messages[0]}`, 'error');
    }
  );

  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);

  const [checkbox1, setCheckbox1] = useState(false); 

  const { data: creditCards, error: creditCardError, isLoading: isCreditCardLoading } = useCreditCards();


  useEffect(() => {
    if (!boutiqueId || !templateId) {
      navigate('/dashboard');
    }
  }, []);

  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent) => {
      if (event.data?.type === 'processComplete' && giftCardData?.data.invoiceId) {
        if (event.data.success) {
          setOpenIframeModal(false);
          closeInvoice({invoiceId: giftCardData?.data.invoiceId, giftCardPurchase: true});
        } else {
          addToast(event.data.message, 'error');
        }
      }
    };
    window.addEventListener('message', handleIframeMessage);
    return () => window.removeEventListener('message', handleIframeMessage);
  }, [giftCardData?.data.invoiceId]);

  const isPayNowDisabled = !checkbox1 || creditCards?.data.length === 0;

  const handleConfirmPurchase = () => {
    if (isPayNowDisabled) return;
    if (templateId && recipientName && recipientEmail && userProfile?.data.firstName && userProfile?.data.email) {
      createGiftCard({
        templateId,
        message: message || '',
        recepientName: recipientName,
        recepientEmail: recipientEmail,
        senderName: userProfile?.data.firstName,
        senderEmail: userProfile?.data.email,
        promoCodeSecondaryEmail: promoCodeSecondaryEmail || ''
      })
    }
  }

  const isPurchasePending = isCreateGiftCardPending || isCloseInvoicePending || isPurchaseGiftCardPending;

  if (isCreditCardLoading || isLoadingBoutique || isLoadingGiftCardTemplates || userProfileLoading) return <Spinner />;
  if (boutiqueError || giftCardTemplatesError || userProfileError) return <ErrorScreen errors={[boutiqueError ?? undefined, giftCardTemplatesError ?? undefined, userProfileError ?? undefined]} />;

  return (
    <div className={styles.bookingCheckout}>
      <div className={styles.bookingDetails}>
        <h2>Order Details</h2>
        <GiftCardDetails 
          title={`$${selectedGiftCard?.price} Gift Card`}
          promotionalValue={promotionalValue}
          location={boutique?.data.name || ''}
          message={message || ''}
        />
      </div>

      <div className={styles.paymentDetails}>
        <div className={styles.contentBlock}>
          <h2>Payment Method</h2>
          {isCreditCardLoading ? (
            <Spinner />
          ) : creditCards?.data && creditCards?.data.length > 0 ? (
            <div className={styles.inputWithAction}>
              <p><strong>{creditCards?.data[0].cardLogo}</strong> ending in {creditCards?.data[0].lastFour}</p>
              <button className={`button__underline`} onClick={() => setShowPaymentMethodModal(true)}>MANAGE</button>
            </div>
          ) : (
            <button className="button" onClick={() => setShowAddPaymentMethodModal(true)}>Add Payment Method</button>
          )}
          {creditCardError && <p className={styles.error}>Error loading payment methods: {creditCardError.error_messages[0]}</p>}
        </div>

        <div className={styles.separator}></div>

        <div className={styles.contentBlock}>
          <h2>Price Details</h2>
          <div className={styles.priceDetails}>
            <div className={styles.packageInfo}>
              <p>Gift Card</p>
            </div>
            <div className={styles.price}>${selectedGiftCard?.price}</div>
          </div>
          {selectedGiftCard && selectedGiftCard?.value - selectedGiftCard?.price > 0 && (
            <p className={styles.promotionalValue}>+ ${selectedGiftCard?.value - selectedGiftCard?.price} Free Promotional card</p>
          )}
        </div>

        <div className={styles.separator}></div>

        <div className={styles.contentBlock}>
          <div className={styles.subTotal}>
            <p>Sub Total</p>
            <p>${selectedGiftCard?.price}</p>
          </div>
          <div className={styles.total}>
            <p><strong>Total</strong></p>
            <p><strong>${selectedGiftCard?.price}</strong></p>
          </div>
        </div>

        <div className={styles.separator}></div>

        <div className={styles.contentBlock}>
          <Checkbox
            id="terms"
            label={<span>I accept the <Link to="https://thenowmassage.com/terms-of-service/" target="_blank">Terms & Conditions</Link></span>}
            checked={checkbox1}
            onChange={(e) => setCheckbox1(e)}
            className={styles.checkbox}
          />
        </div>

        <button onClick={handleConfirmPurchase} className={`button ${isPayNowDisabled ? 'disabled' : ''}`} disabled={isPayNowDisabled}>
          {isPurchasePending ? <Spinner size={20} /> : "Pay Now"}
        </button>
        {closeInvoiceError && <p className={styles.error}>Error purchasing gift card: {closeInvoiceError.error_messages[0]}.</p>}
      </div>

      <AddPaymentMethodModal onClose={() => setShowAddPaymentMethodModal(false)} isOpen={showAddPaymentMethodModal} />
      
      <Modal isOpen={showPaymentMethodModal} onClose={() => setShowPaymentMethodModal(false)}>
        <PaymentMethodModal
          onClose={() => setShowPaymentMethodModal(false)}
          creditCards={creditCards?.data || []}
          addPaymentMethod={() => setShowAddPaymentMethodModal(true)}
        />
      </Modal>

      <Modal className={styles.iframeModal} isOpen={openIframeModal} onClose={() => setOpenIframeModal(false)}>
        {isPurchaseGiftCardPending ? (
          <Spinner />
        ) : !purchaseGiftCardError && isCardVerificationRequired && (
          <iframe
            src={purchaseGiftCardData?.data.hostedPaymentUri}
            title="Purchase Gift Card"
            className={styles.iframe}
          />
        )}
      </Modal>
    </div>
  );
};

export default GiftCardCheckout;
