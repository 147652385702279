import React, { useState, useEffect } from 'react';
import ActiveSelectIcon from '../../assets/icons/active-select-filled.svg';
import InactiveSelectIcon from '../../assets/icons/inactive-select.svg';
import styles from '../../styles/components/RadioBox.module.scss';

interface CustomRadioBoxProps {
  id: string;
  label: string;
  value: string;
  selectedValue: string;
  onChange: (value: string) => void;
  error?: string;
  className?: string;
  options?: Array<{ label: string; value: string }>;
}

export const RadioBox: React.FC<CustomRadioBoxProps> = ({
  id,
  label,
  value,
  selectedValue,
  onChange,
  error,
  className,
  options = [],
}) => {
  const [selectedSubValue, setSelectedSubValue] = useState<string | null>(null);
  const [showError, setShowError] = useState(false);

  const isSelected = selectedValue.startsWith(value);

  const handleClick = () => {
    if (isSelected) {
      onChange(''); 
      setSelectedSubValue(null);
      setShowError(false);
    } else {
      onChange(value);
    }
  };

  const handleSubOptionChange = (subValue: string) => {
    setSelectedSubValue(subValue);
    setShowError(false);
    onChange(`${value}:${subValue}`);
  };

  useEffect(() => {
    if (isSelected && !selectedSubValue) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [isSelected, selectedSubValue]);

  return (
    <div className={`${styles.radioBoxContainer} ${className || ''}`}>
      <div
        className={`${styles.input} ${isSelected ? styles.selected : ''}`}
        onClick={handleClick}
      >
        <input
          type="checkbox"
          id={id}
          value={value}
          checked={isSelected}
          onChange={handleClick}
          className={styles.radioInput}
        />
        <label htmlFor={id} className={styles.radioLabel}>
          {label}
          <img
            src={isSelected ? ActiveSelectIcon : InactiveSelectIcon}
            alt={isSelected ? 'Selected' : 'Not Selected'}
            className={styles.radioIcon}
          />
        </label>
      </div>

      {isSelected && options.length > 0 && (
        <div className={styles.subOptions}>
          {options.map((option, idx) => (
            <div key={idx} className={styles.radioOption}>
              <input
                type="radio"
                id={`${id}-${option.value}`}
                value={option.value}
                checked={selectedSubValue === option.value}
                onChange={() => handleSubOptionChange(option.value)}
                className={styles.radioInputSub}
              />
              <label htmlFor={`${id}-${option.value}`} className={styles.radioLabel}>
                <span>{option.label}</span>
                <img
                  src={
                    selectedSubValue === option.value
                      ? ActiveSelectIcon
                      : InactiveSelectIcon
                  }
                  alt={selectedSubValue === option.value ? 'Selected' : 'Not Selected'}
                  className={styles.radioIcon}
                />
              </label>
            </div>
          ))}
        </div>
      )}

      {showError && <p className={styles.error}>Please select a sub-option.</p>}
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default RadioBox;
