import { useQuery, useMutation, UseMutationResult } from '@tanstack/react-query';
import { fetchGiftCardTemplates, createGiftCard, checkGiftCardBalance } from '../api/giftCards';
import { GiftCardsResponse, CreateGiftCardRequest, CreateGiftCardResponse, GiftCardBalanceResponse } from '../types/giftCards';
import { ApiErrorResponse } from '../types/api';

export const useGiftCardTemplates = (boutiqueId: string) => {
  return useQuery<GiftCardsResponse, ApiErrorResponse>({
    queryKey: ['giftCardTemplates', boutiqueId],
    queryFn: () => fetchGiftCardTemplates(boutiqueId),
  });
};

export const useCreateGiftCard = (
  boutiqueId: string,
  onSuccess?: (data: CreateGiftCardResponse) => void,
  onError?: (error: ApiErrorResponse) => void
): UseMutationResult<CreateGiftCardResponse, ApiErrorResponse, CreateGiftCardRequest> => {
  return useMutation<CreateGiftCardResponse, ApiErrorResponse, CreateGiftCardRequest>({
    mutationFn: (data: CreateGiftCardRequest) => createGiftCard(boutiqueId, data),
    onSuccess,
    onError,
  });
};

export const useCheckGiftCardBalance = () => {
  return useMutation<GiftCardBalanceResponse, ApiErrorResponse, string>({
    mutationFn: (giftCardCode: string) => checkGiftCardBalance(giftCardCode),
  });
};
