import styles from '../styles/pages/PauseMembership.module.scss';
import AttentionIcon from '../assets/icons/attention-icon.svg';
import WarningIcon from '../assets/icons/warning.svg';
import ArrowDownIcon from '../assets/icons/arrow-down.svg';
import { useFreezeMembership, useUnfreezeMembership } from '../hooks/useMemberships';
import { useToast } from '../components/core/ToastManager';
import { useMemo, useState } from 'react';
import Spinner from '../components/core/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useUserMemberships } from '../hooks/useUserProfile';
import Modal from '../components/core/Modal';
import ErrorScreen from '../components/core/ErrorMessages';
import {DateTime} from 'luxon';

export const PauseMembership:React.FC = () => {
  const { addToast } = useToast()
  const queryClient = useQueryClient();
  const [showConfirmation, setShowConfirmation] = useState(false)
  const { membershipId } = useParams();
  const navigate = useNavigate();
  const { data: userMemberships, isLoading: isLoadingUserMemberships, error: userMembershipsError } = useUserMemberships(true);
  const { mutate: freezeMembership, isPending: isFreezingPending } = useFreezeMembership(
    () => {
      addToast('Membership successfully paused!', 'success')
      queryClient.invalidateQueries({ queryKey: ['userMemberships'] });
      navigate('/settings/memberships')
    },
    (error) => {
      addToast(error.errorMessages[0], 'error', 4000)
    }
  )
  const { mutate: unfreezeMembership, isPending: isUnfreezingPending } = useUnfreezeMembership(
    () => {
      addToast('Membership successfully resumed!', 'success')
      queryClient.invalidateQueries({ queryKey: ['userMemberships'] });
      navigate('/settings/memberships')
    },
    (error) => {
      addToast(error.errorMessages[0], 'error', 4000)
    }
  )

  const userMembership = useMemo(() => {
    if (!userMemberships || isLoadingUserMemberships) return null;

    const foundMembership = userMemberships.membershipGroups
      .flatMap((group) => group.userMemberships)
      .find((um) => um.zenotiId === membershipId);

    return foundMembership || null;
  }, [userMemberships, isLoadingUserMemberships, membershipId]);

  function calculateMonthsLeft(targetDateISO: string): number {
    const today = DateTime.now();
    const targetDate = DateTime.fromISO(targetDateISO, { zone: "utc" });
  
    const diff = targetDate.diff(today, ["months", "days"]);
  
    const months = diff.months;
    const days = diff.days;
  
    return days > 0 ? months + 1 : months;
  }

  const isFreezePending = isFreezingPending || isUnfreezingPending;

  if (isLoadingUserMemberships) return <Spinner />;
  if (userMembershipsError) return <ErrorScreen errors={[userMembershipsError]} />;

  if (!userMembership) return <div>No membership found.</div>

  const { membership, freezeDate, zenotiId, monthsFrozen, monthsFrozenRemaining } = userMembership;

  return (
    <div>
      <div className={styles.cancelHeading}>
        <Link to="/settings/memberships" className={styles.backButton}>
          <img src={ArrowDownIcon} alt="Arrow Down Icon" />
          Back
        </Link>
        <h1>Pause Membership</h1>
      </div>
      <div className={styles.pauseMembership}>
        <h2>Pause your membership for up to 3 months in a calendar year.</h2>
        <div className={styles.status}>
            <div className={styles.statusHeading}>
              <img src={freezeDate ? WarningIcon : AttentionIcon} alt="attention icon" />
              <p>Your membership is {freezeDate ? `paused for ${monthsFrozen} ${monthsFrozen === 1 ? 'month' : 'months'}` : "active"}</p>
            </div>
          <p>{freezeDate ? `Your membership was paused on ${DateTime.fromISO(freezeDate).toFormat('MMMM dd')}` : `Your membership has not been paused in the current calendar year.` }</p>
        </div>
        <div className={styles.remainingMonths}>{monthsFrozenRemaining} Months Remaining</div>
        <p className={styles.termsHeading}>What to expect when pausing a membership:</p>
        <ul className={styles.termsList}>
          <li>You will not receive any charges while your membership is paused.</li>
          <li>Resume at any time.</li>
          <li>Pause your membership multiple times as long as the total does not exceed three months per calendar year.</li>
          <li>Use any previously accrued credits during the freeze, however, you will not accumulate additional credits.</li>
        </ul>
        <div className={styles.actions}>
          <button onClick={() => setShowConfirmation(true)} className="button w-full">{freezeDate ? "Resume my membership" : "Pause my membership"}</button>
          <p>Questions about freezing your membership?</p>
          <Link className="button__underline" to={`mailto: ${membership?.boutique.franchiseEmail}`}>CONTACT US</Link>
        </div>
      </div>
      <Modal onClose={() => setShowConfirmation(false)} isOpen={showConfirmation}>
        <div className={styles.pauseMembershipModal}>
          <h2>Are you ready to {freezeDate ? "resume" : "pause" } your membership?</h2>
          {!freezeDate && <p>Reinstate your membership online at anytime.</p>}
          <div className={styles.modalActions}>
            <button onClick={freezeDate ? () => unfreezeMembership(zenotiId) : () => freezeMembership(zenotiId)} className={`button w-full ${isFreezePending ? 'disabled' : ''}`}>
              {isFreezePending ? <Spinner size={20} /> : freezeDate ? "Resume membership" : "Pause membership"}
            </button>
            <button onClick={() => setShowConfirmation(false)} className="button__underline">CANCEL</button>
          </div>
        </div>
      </Modal>
    </div>
)
}

export default PauseMembership;