import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styles from '../styles/pages/UpdatePassword.module.scss';
import { Input } from '../components/core/Input';
import { useToast } from '../components/core/ToastManager';
import { useUpdateUserPassword } from '../hooks/useUserProfile';

interface FormData {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup.string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain a lowercase letter')
    .matches(/[A-Z]/, 'Password must contain an uppercase letter')
    .matches(/[0-9]/, 'Password must contain a numeral')
    .matches(/[^a-zA-Z0-9]/, 'Password must contain a special character'),
  repeatNewPassword: yup.string()
    .oneOf([yup.ref('newPassword')], 'Passwords Do Not Match')
    .required('Please repeat your new password'),
});

const UpdatePassword: React.FC = () => {
  const { register, handleSubmit, control, formState: { errors, isValid }, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const newPassword = useWatch({ control, name: 'newPassword' });
  const repeatNewPassword = useWatch({ control, name: 'repeatNewPassword' });
  const passwordsMatch = newPassword && repeatNewPassword && newPassword === repeatNewPassword;
  
  const { addToast } = useToast();
  const updatePasswordMutation = useUpdateUserPassword();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await updatePasswordMutation.mutateAsync({
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      });
      addToast('Password successfully updated!', 'success', 3500);
      reset();
    } catch (error) {
      addToast('Failed to update password. Please try again.', 'error', 3500);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Input
        id="currentPassword"
        label="Enter Current Password"
        inputProps={{ ...register('currentPassword'), type: 'password', autoComplete: 'current-password' }}
        error={errors.currentPassword?.message}
      />
      <Input
        id="newPassword"
        label="Enter New Password"
        inputProps={{ ...register('newPassword'), type: 'password', autoComplete: 'new-password' }}
        error={errors.newPassword?.message}
      />
      <Input
        id="repeatNewPassword"
        label="Confirm New Password"
        inputProps={{ ...register('repeatNewPassword'), type: 'password', autoComplete: 'new-password' }}
        error={errors.repeatNewPassword?.message}
        success={passwordsMatch ? 'Passwords Match' : undefined}
      />

      <button
        className={`button ${styles.button} ${!isValid || updatePasswordMutation.status === 'pending' ? "disabled" : ""}`}
        disabled={!isValid || updatePasswordMutation.status === 'pending'}
        type="submit"
      >
        {updatePasswordMutation.status === 'pending' ? 'Updating...' : 'Update Password'}
      </button>
    </form>
  );
};

export default UpdatePassword;