import styles from '../styles/components/BookingDetails.module.scss'
import MapPinIcon from '../assets/icons/map-pin.svg'
import GiftCardIcon from '../assets/icons/gift-card.svg'
import EnvelopeIcon from '../assets/icons/envelope.svg'
import MessageIcon from '../assets/icons/message.svg'
import Arrow from '../assets/icons/arrow-down.svg'
import { Link } from 'react-router-dom'

interface OrderDetailsProps {
  title: string;
  message?: string;
  location: string;
  promotionalValue?: number;
}

export const GiftCardDetails: React.FC<OrderDetailsProps> = ({
  title,
  message,
  location,
  promotionalValue
}) => {
  return (
    <div className={`${styles.bookingDetails}`}>
      <div className={styles.navigation}>
        <img src={Arrow} alt="Back Arrow" />
        <Link className="button__link" to={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/giftcards`}>Back to Gift Card Options</Link>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.bookingInfo}>
        <div className={styles.item}>
          <img src={GiftCardIcon} alt="Gift Card icon" />
          <div>
            <p>{title}</p>
            {promotionalValue ? <p className={styles.promotionalValue}>+ ${promotionalValue} free promotional card</p> : ''}
          </div>
        </div>
        <div className={styles.item}>
          <img src={MapPinIcon} alt="Map Pin Icon" />
          <p>Redeemable at: {location}</p>
        </div>
        <div className={styles.item}>
          <img src={EnvelopeIcon} alt="Envelope Icon" />
          <div className={styles.itemInfo}>
            <p>Delivery Method: Email</p>
          </div>
        </div>
        {message && 
        <div className={`${styles.item} ${styles.message}`}>
          <img src={MessageIcon} alt="Message" />
          <div>
            <p>Optional Message</p>
            <p className={styles.messageContent}>{message}</p>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default GiftCardDetails