import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import styles from '../styles/pages/UpdateMembership.module.scss';
import ActiveSelectIcon from '../assets/icons/active-select.svg';
import ArrowDownIcon from '../assets/icons/arrow-down.svg';
import InactiveSelectIcon from '../assets/icons/inactive-select.svg';
import { useToast } from '../components/core/ToastManager';
import Modal from '../components/core/Modal';
import { TermsModal } from '../components/TermsModal';
import Spinner from '../components/core/Spinner';
import { useMemberships } from '../hooks/useMemberships';
import { Membership } from '../types/memberships';
import { numberToText } from '../utils/caseConverter';
import { Checkbox } from '../components/core/Checkbox';


const UpdateMembership: React.FC = () => {
  const { boutiqueId, membershipId, userMembershipId } = useParams()
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { data: boutiqueMemberships, isLoading: isLoadingMemberships, error: membershipsError } = useMemberships(boutiqueId);
  const flattenedMemberships = boutiqueMemberships && boutiqueMemberships.membershipGroups.map((group) => group.memberships).flat();
  const activeMembership = flattenedMemberships?.find(membership => membership.zenotiId === membershipId)
  const [selectedMembership, setSelectedMembership] = useState<Membership | undefined>(activeMembership);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const currentPrice = activeMembership ? activeMembership.price : 0;

  useEffect(() => {
    if (!boutiqueId || !membershipId) {
      navigate('/settings/memberships');
    }
  }, [])

  useEffect(() => {
    if (activeMembership) {
      setSelectedMembership(activeMembership);
    }
  }, [activeMembership]);

  const handleMembershipClick = (membership: Membership) => {
    if ((membership.price >= currentPrice) && (membership.zenotiId !== activeMembership?.zenotiId)) {
      setSelectedMembership(membership);
    } else if (membership.zenotiId === activeMembership?.zenotiId) {
      addToast('You are already on this membership', 'warning')
    } else {
      addToast('Contact your boutique directly to ask about downgrading your membership', 'warning');
    }
  }

  const isContinueButtonDisabled = activeMembership?.zenotiId === selectedMembership?.zenotiId;

  const handleContinue = () => {
    setOpenConfirmationModal(true);
  }

  if (isLoadingMemberships) {
    return <Spinner />
  }

  if (membershipsError) {
    return <div>Error loading memberships. Please try again later.</div>;
  }

  return (
    <div className={styles.updateMembership}>
      <Link to="/settings/memberships" className={styles.backButton}>
        <img src={ArrowDownIcon} alt="Arrow Down Icon" />
        Back
      </Link>
      <h1>Change Membership</h1>
      <p className={styles.location}>West Hollywood, CA</p>
      <div className={styles.membershipsContainer}>
        {boutiqueMemberships && boutiqueMemberships.membershipGroups.map((membershipGroup) => (
          <div key={membershipGroup.id} className={styles.membershipColumn}>
            <h2>{membershipGroup.memberships[0].durationInMinutes} Minutes</h2>
            <p className={styles.membershipTitle}>{membershipGroup.name}</p>
            {membershipGroup.memberships.map((membership) => (
              <div
                key={membership.zenotiId}
                className={`${styles.membershipCard} ${membership.zenotiId === selectedMembership?.zenotiId ? styles.active : ''} ${membership.price < currentPrice ? styles.disabledCard : ''}`}
                onClick={() => handleMembershipClick(membership)}
              >
                {membership.zenotiId === activeMembership?.zenotiId && (
                  <div className={styles.currentMembership}>Current Membership</div>
                )}
                <div className={styles.membershipContentLeft}>
                  <img
                    className={styles.radioBox}
                    src={membership.zenotiId === selectedMembership?.zenotiId ? ActiveSelectIcon : InactiveSelectIcon}
                    alt="radio box"
                  />
                  <div className={styles.membershipFrequency}>{`${numberToText(membership.frequencyPerMonth || 0)} ${membership.frequencyPerMonth > 1 ? 'massages' : 'massage'} per month`}</div>
                </div>
                <div className={styles.membershipContentRight}>
                  <div className={styles.membershipPrice}>${membership.price}</div>
                  <div className={styles.nonMemberPrice}>Non-Members Pay ${membership.price + (membership.memberSaving || 0)}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.actionsContainer}>
        <div className={styles.actions}>
          <Link to="/settings/memberships" className={`button inverted ${styles.cancel}`}>Back</Link>
          <button onClick={handleContinue} disabled={isContinueButtonDisabled} className={`button ${styles.update}${isContinueButtonDisabled ? ' disabled' : ''}`}>Continue</button>
        </div>
      </div>

      <Modal isOpen={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)} hideCloseButton>
        <ConfirmationModal 
          currentMembership={activeMembership} 
          newMembership={selectedMembership} 
          onClose={() => setOpenConfirmationModal(false)}
          onTermsOpen={() => setIsTermsOpen(true)}
          boutiqueId={boutiqueId!}
          userMembershipId={userMembershipId!}
        />
      </Modal>

      <TermsModal isOpen={isTermsOpen} onClose={() => setIsTermsOpen(false)} />
    </div>
  );
};

interface ConfirmationModalProps {
  currentMembership?: Membership;
  newMembership?: Membership;
  onClose: () => void;
  onTermsOpen: () => void;
  boutiqueId: string;
  userMembershipId: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ currentMembership, newMembership, onClose, onTermsOpen, boutiqueId, userMembershipId }) => {
  const today = DateTime.local().toFormat('MM/d/yy');
  const [checkbox, setCheckbox] = useState(false);
  return (
    <div className={styles.confirmationModal}>
      <h2>Confirm Membership Change</h2>
      <div className={styles.modalMembershipCards}>
        <div className={styles.modalMembershipCardContainer}>
            <p className={styles.membershipType}>Current Membership:</p>
            {currentMembership && (
              <div className={styles.modalMembershipCard}>
                <div>
                  <h3 className={styles.membershipCardTitle}>{currentMembership?.name}</h3>
                  <ul className={styles.membershipDetails}>
                    <li>{`${numberToText(currentMembership?.frequencyPerMonth || 0)} ${currentMembership?.durationInMinutes} minute ${currentMembership?.frequencyPerMonth > 1 ? 'massages' : 'massage'} per month`}</li>
                    <li>{currentMembership?.boutique.city}, {currentMembership?.boutique.state}</li>
                  </ul>
                </div>
                <div>
                  <div className={styles.membershipPrice}>${currentMembership?.price}</div>
                </div>
              </div>
            )}
        </div>
        <div className={styles.modalMembershipCardContainer}>
            <p className={styles.membershipType}>New Membership:</p>
            <div className={styles.modalMembershipCard}>
              <div>
                <h3 className={styles.membershipCardTitle}>{newMembership?.name}</h3>
                <ul className={styles.membershipDetails}>
                  <li>{`${numberToText(newMembership?.frequencyPerMonth || 0)} ${newMembership?.durationInMinutes} minute ${newMembership?.frequencyPerMonth && newMembership?.frequencyPerMonth > 1 ? 'massages' : 'massage'} per month`}</li>
                  <li>{newMembership?.boutique.city}, {newMembership?.boutique.state}</li>
                </ul>
              </div>
              <div>
                <div className={styles.membershipPrice}>${newMembership?.price}</div>
              </div>
            </div>
        </div>
      </div>
      <p>Your new membership starts now. You’ll pay ${newMembership?.price}/month starting {today}.</p>
      <Checkbox
        id="terms"
        label={
          <button className={`button__link ${styles.termsLink}`} onClick={onTermsOpen}>
            By checking the box above you agree to the Terms and Conditions of The NOW Massage Membership agreement.
            <span>View Membership Agreement</span>
          </button>
        }
        checked={checkbox}
        onChange={(e) => setCheckbox(e)}
        className={styles.checkbox}
      />
      <div className={styles.modalActions}>
        <Link to={`/checkout/${boutiqueId}/membership/${newMembership?.zenotiId}?currentMembershipId=${userMembershipId}`} className={`button ${styles.confirm} ${checkbox ? '' : 'disabled'}`}>Continue</Link>
        <button onClick={onClose} className={`button__underline ${styles.cancel}`}>CANCEL</button>
      </div>
    </div>
  );
}

export default UpdateMembership;
